import React, { useState, useMemo, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Box, Button, Card, CardBody, CardHeader, Checkbox, HStack, Icon, List, ListItem, VStack } from '@chakra-ui/react';
import LoaderOverlay from '@/features/common/LoaderOverlay.jsx';
import { getIndexById, intersection, isRealValue, not, truncateText, union } from '@/utils/helpers.js';

// The TransferList component is a custom component that allows users to move items between two lists.
// It can be either controlled or uncontrolled, depending on the `checked` and `onChecked` props.
const TransferList = ({
  leftTitle,
  rightTitle,
  noResult,
  isLoading,
  left,
  onChangeLeft,
  right,
  onChangeRight,
  checked: checkedFromProps,
  onChecked,
  isItemDisabled,
  isDisabled,
  onFilter,
  listItem,
}) => {
  const isControlled = typeof checkedFromProps !== 'undefined';
  const showDisabled = typeof isItemDisabled === 'function';
  const [internalChecked, setInternalChecked] = useState(isControlled ? checkedFromProps : []);
  const checked = isControlled ? checkedFromProps : internalChecked;

  const leftChecked = useMemo(() => intersection(checked, left, getIndexById), [left, checked]);
  const rightChecked = useMemo(() => intersection(checked, right, getIndexById), [right, checked]);

  const handleChecked = (value) => {
    if (onChecked) {
      onChecked(value);
    }
    if (!isControlled) {
      setInternalChecked(value);
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = getIndexById(checked, value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    handleChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items, getIndexById).length;
  const isNotDisabled = (list) => list.filter((item) => !isItemDisabled(item));

  const handleToggleAll = (items) => () => {
    const total = showDisabled ? isNotDisabled(items).length : items.length;
    if (numberOfChecked(items) === total) {
      const selection = not(checked, items, getIndexById);
      handleChecked(showDisabled ? isNotDisabled(selection) : selection);
    } else {
      const selection = union(checked, items, getIndexById);
      handleChecked(showDisabled ? isNotDisabled(selection) : selection);
    }
  };

  const handleCheckedRight = () => {
    const newRight = right.concat(leftChecked);
    onChangeRight(newRight);
    onFilter(newRight);
    onChangeLeft(not(left, leftChecked, getIndexById));
    handleChecked(not(checked, leftChecked, getIndexById));
  };

  const handleCheckedLeft = () => {
    onChangeLeft(left.concat(rightChecked));
    const newRight = not(right, rightChecked, getIndexById);
    onChangeRight(newRight);
    onFilter(newRight);
    handleChecked(not(checked, rightChecked, getIndexById));
  };

  const renderItem = (item) => {
    if (isRealValue(listItem)) {
      return listItem(item);
    } else {
      return <Box py={2}>{truncateText(item?.name, 45)}</Box>;
    }
  };

  const customList = (orientation, items, title, noResult = false) => (
    <Card
      aria-label={title}
      sx={{
        width: '100%',
        height: '420px',
        overflow: 'hidden',
        boxShadow: 'none',
        border: '1px solid',
        borderColor: 'gray.200',
      }}
    >
      <CardHeader>
        <Checkbox
          spacing="1rem"
          colorScheme="purple"
          aria-label={`select all ${title.toLowerCase()}`}
          isDisabled={items.length === 0 || isDisabled}
          isChecked={numberOfChecked(items) === items.length && items.length !== 0}
          isIndeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
          onChange={handleToggleAll(items)}
        >
          {title}
          <Box color="gray.400">{`${numberOfChecked(items)}/${items.length} selected`}</Box>
        </Checkbox>
      </CardHeader>
      <CardBody alignItems={'start'} py={2} px={0} overflow={'auto'} borderTopWidth="1px" borderTopColor="gray.200">
        <List spacing={0} width={'100%'} mt={3}>
          {noResult ? (
            <ListItem
              px={5}
              key={'no-result'}
              sx={{ marginTop: '0px' }}
              _hover={{ backgroundColor: 'gray.50', marginTop: '0px' }}
              whiteSpace="nowrap"
              cursor="pointer"
            >
              No Results
            </ListItem>
          ) : (
            items.map((item, index) => (
              <ListItem
                px={5}
                key={index}
                sx={{ marginTop: '0px' }}
                _hover={{ backgroundColor: 'gray.50', marginTop: '0px' }}
                whiteSpace="nowrap"
                cursor="pointer"
              >
                <Checkbox
                  id={item.id}
                  aria-label={`select ${item.name}`}
                  isDisabled={(orientation === 'left' && showDisabled && isItemDisabled(item)) || isDisabled}
                  spacing="1rem"
                  colorScheme="purple"
                  onChange={handleToggle(item)}
                  isChecked={getIndexById(checked, item) !== -1}
                  width="100%"
                >
                  {renderItem(item)}
                </Checkbox>
              </ListItem>
            ))
          )}
        </List>
      </CardBody>
    </Card>
  );

  return (
    <Box>
      <HStack justifyContent={'space-between'} position="relative">
        <LoaderOverlay loading={isLoading} />
        {customList('left', left, leftTitle ?? 'Choices', noResult)}
        <VStack alignItems={'center'} p={4}>
          <Button onClick={handleCheckedRight} variant="outline" size="sm" aria-label="move selected right">
            <Icon boxSize={4} as={BsChevronRight} />
          </Button>
          <Button onClick={handleCheckedLeft} variant="outline" size="sm" aria-label="move selected left">
            <Icon boxSize={4} as={BsChevronLeft} />
          </Button>
        </VStack>
        {customList('right', right, rightTitle ?? 'Chosen')}
      </HStack>
    </Box>
  );
};

TransferList.propTypes = {
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  noResult: PropTypes.bool,
  left: PropTypes.array,
  right: PropTypes.array,
  onChangeLeft: PropTypes.func,
  onChangeRight: PropTypes.func,
  checked: PropTypes.array,
  onChecked: PropTypes.func,
  onFilter: PropTypes.func,
  isItemDisabled: PropTypes.func,
  isDisabled: PropTypes.bool,
  listItem: PropTypes.func,
};
export default TransferList;
