import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Switch,
} from '@chakra-ui/react';
import { chakraStyles } from '../common/select/styles';
import { ClearIndicator } from '../common/select/ClearIndicator';
import { Select } from 'chakra-react-select';
import { BsSearch, BsX } from 'react-icons/bs';
import TransferList from '@/features/common/TransferList.jsx';
import FacilityListItem from '@/features/salesRep/FacilityListItem.jsx';
import { SEARCHING } from '@/utils/constants.js';

const FacilityTransferList = ({
  isLoading,
  noResult,
  salesRep,
  selectedFacilities,
  onFacilityChecked,
  onFilter,
  left,
  setLeft,
  right,
  setRight,
  facilityFilterStatus,
  facilityInputValue,
  onFacilityInputChange,
  onFacilityInputClear,
  states,
  onStateChange,
  stateValue,
  displayDisabledFacilities,
  onDisplayDisabledFacilitiesChange,
  disabledFacility,
}) => {
  const portalRef = useRef(null);
  return (
    <FormControl id="select_facilities" ref={portalRef}>
      <Heading px={0} mb={2} as="h5" size="sm">
        Select facilities
      </Heading>
      <Flex gap={5} justifyContent="space-between" width="100%" flexWrap="nowrap" mt={3} mb={5}>
        <FormControl aria-label="filter by name or address" maxWidth="300px">
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <BsSearch aria-label="find icon" />
            </InputLeftElement>
            <Input
              isDisabled={!salesRep}
              type="text"
              width="310px"
              aria-label="facility name"
              placeholder="Facility name or address"
              focusBorderColor="brand.700"
              value={facilityInputValue || ''}
              onChange={onFacilityInputChange}
            />
            {facilityInputValue !== '' && (
              <InputRightElement>
                <IconButton onClick={onFacilityInputClear} icon={<BsX size="25px" />} variant="ghost" size="sm" aria-label="clear" />
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>
        <FormControl aria-label="facility_transfer_state_control" maxWidth="160px">
          <Select
            aria-label="state select"
            chakraStyles={chakraStyles}
            focusBorderColor="brand.700"
            menuPosition="fixed"
            menuPortalTarget={portalRef.current}
            options={states}
            isDisabled={!salesRep}
            onChange={onStateChange}
            value={stateValue}
            isClearable
            isSearchable
            placeholder="State"
            components={{ ClearIndicator }}
          />
        </FormControl>
        <Spacer />
        <FormControl my={2} width="auto">
          <Flex alignItems="center">
            <FormLabel htmlFor="toggle-disabled-facilities" mb="0">
              Display disabled facilities?
            </FormLabel>
            <Switch
              isDisabled={!salesRep}
              aria-label="toggle disabled facilities"
              id="toggle-disabled-facilities"
              size="sm"
              colorScheme="cyan"
              isChecked={displayDisabledFacilities}
              onChange={onDisplayDisabledFacilitiesChange}
            />
          </Flex>
        </FormControl>
      </Flex>
      <TransferList
        leftTitle={'Available Facilities'}
        rightTitle={'Assigned Facilities'}
        noResult={noResult}
        isLoading={isLoading || facilityFilterStatus === SEARCHING}
        isDisabled={!salesRep}
        checked={selectedFacilities}
        onChecked={onFacilityChecked}
        onFilter={onFilter}
        left={left}
        onChangeLeft={setLeft}
        right={right}
        onChangeRight={setRight}
        isItemDisabled={displayDisabledFacilities ? disabledFacility : undefined}
        listItem={(item) => <FacilityListItem item={item} />}
      />
    </FormControl>
  );
};

FacilityTransferList.propTypes = {
  isLoading: PropTypes.bool,
  noResult: PropTypes.bool,
  salesRep: PropTypes.object,
  selectedFacilities: PropTypes.array,
  onFacilityChecked: PropTypes.func,
  onFilter: PropTypes.func,
  left: PropTypes.array,
  setLeft: PropTypes.func,
  right: PropTypes.array,
  setRight: PropTypes.func,
  facilityFilterStatus: PropTypes.string,
  facilityInputValue: PropTypes.string,
  onFacilityInputChange: PropTypes.func,
  onFacilityInputClear: PropTypes.func,
  states: PropTypes.array,
  onStateChange: PropTypes.func,
  stateValue: PropTypes.object,
  displayDisabledFacilities: PropTypes.bool,
  onDisplayDisabledFacilitiesChange: PropTypes.func,
  disabledFacility: PropTypes.func,
};
export default FacilityTransferList;
