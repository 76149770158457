import { DragOverlay, defaultDropAnimationSideEffects } from '@dnd-kit/core';

const dropAnimationConfig = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
};

const SortableOverlay = ({ children }) => {
  return (
    <DragOverlay
      zIndex={100}
      style={{
        borderLeft: '5px solid #514689',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      }}
      dropAnimation={dropAnimationConfig}
    >
      {children}
    </DragOverlay>
  );
};

export default SortableOverlay;
