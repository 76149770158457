import React, { useMemo } from 'react';
import { createContext, useContextSelector } from '@fluentui/react-context-selector';
import { MdDragIndicator } from 'react-icons/md';
import { useSortable, defaultAnimateLayoutChanges } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, IconButton } from '@chakra-ui/react';

const SortableItemContext = createContext({
  attributes: {},
  listeners: undefined,
  ref() {},
});
const SortableProvider = SortableItemContext.Provider;

export const useSortableItemContextSelector = (selector) => useContextSelector(SortableItemContext, selector);

const animateLayoutChanges = (args) => {
  const { isSorting, wasSorting } = args;

  if (isSorting || wasSorting) {
    return defaultAnimateLayoutChanges(args);
  }
  return true;
};

export const SortableItem = ({ children, id, isDisabled }) => {
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    animateLayoutChanges,
    id,
    disabled: isDisabled,
  });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };
  return (
    <SortableProvider value={context}>
      <Box width={'100%'} p={0} m={0} ref={setNodeRef} style={style}>
        {children}
      </Box>
    </SortableProvider>
  );
};

export const DragHandle = ({ ...props }) => {
  const attributes = useSortableItemContextSelector((context) => context.attributes);
  const listeners = useSortableItemContextSelector((context) => context.listeners);
  const ref = useSortableItemContextSelector((context) => context.ref);
  return (
    <IconButton
      className="DragHandle"
      {...attributes}
      {...listeners}
      ref={ref}
      icon={<MdDragIndicator />}
      variant="ghost"
      size="md"
      aria-label={'drag-handle'}
      colorScheme="grab"
      cursor={'grab'}
      {...props}
    />
  );
};
