import { chakraComponents } from 'chakra-react-select';
import PropTypes from 'prop-types';
import { Checkbox, chakra } from '@chakra-ui/react';

export const OptionNameId = (props) => {
  const { name, id } = props.data;
  return (
    <chakraComponents.Option {...props}>
      <chakra.div>
        <chakra.div aria-label={name}>{name}</chakra.div>
        <chakra.div style={{ color: 'darkgray' }}>{id}</chakra.div>
      </chakra.div>
    </chakraComponents.Option>
  );
};
OptionNameId.propTypes = {
  data: PropTypes.object,
};

export const OptionName = (props) => {
  const { name, city, state } = props.data;
  return (
    <chakraComponents.Option {...props}>
      <chakra.div>
        <chakra.div aria-label={name}>{name}</chakra.div>
        <chakra.span style={{ color: 'darkgray' }}>{`${city}, ${state}`}</chakra.span>
      </chakra.div>
    </chakraComponents.Option>
  );
};
OptionName.propTypes = {
  data: PropTypes.object,
};

export const OptionShortName = (props) => {
  const { short_name, division } = props.data;

  const selectId = (id) => {
    return props.data.id === id;
  };

  const selectAllStyle = {
    backgroundColor: 'brand.100',
    cursor: 'pointer',
  };

  return (
    <>
      <chakraComponents.Option {...props}>
        <chakra.div>
          <chakra.div display="flex">
            {selectId('select_all') && (
              <Checkbox
                id={props.data.id}
                size="md"
                colorScheme="cyan"
                isChecked={props.data.checked}
                marginRight="8px"
                marginLeft="-25px"
                alignItems="center"
                isDisabled={props.data.disabled}
              />
            )}
            <chakra.div
              aria-label={short_name}
              aria-disabled={props.isDisabled}
              data-disabled={props.isDisabled}
              sx={selectId('select_all') || selectId('deselect_all') ? selectAllStyle : null}
            >
              {short_name}
            </chakra.div>
          </chakra.div>
          <chakra.span style={{ color: 'darkgray' }}>{division}</chakra.span>
        </chakra.div>
      </chakraComponents.Option>
      {selectId('select_all') || selectId('deselect_all') ? <div style={{ borderTop: '1px solid #ddd', marginTop: '2px' }} /> : null}
    </>
  );
};
OptionShortName.propTypes = {
  data: PropTypes.object,
};

export const OptionFirstLastName = (props) => {
  const { first_name, last_name, email } = props.data;
  return (
    <chakraComponents.Option {...props}>
      <chakra.div>
        <chakra.div aria-label={first_name}>{first_name + ' ' + last_name}</chakra.div>
        <chakra.div style={{ color: 'darkgray' }}>{email}</chakra.div>
      </chakra.div>
    </chakraComponents.Option>
  );
};
OptionFirstLastName.propTypes = {
  data: PropTypes.object,
};

export const OptionRole = (props) => {
  const { role, label } = props.data;
  return (
    <chakraComponents.Option {...props}>
      <chakra.div>
        <chakra.div aria-label={label}>{label}</chakra.div>
        <chakra.div style={{ color: 'darkgray' }}>{role}</chakra.div>
      </chakra.div>
    </chakraComponents.Option>
  );
};
OptionRole.propTypes = {
  data: PropTypes.object,
};

export const OptionFirstLastNameDivision = (props) => {
  const { first_name, last_name, email, division } = props.data;
  const desc = division ? `${email} - ${division}` : email;
  return (
    <chakraComponents.Option {...props}>
      <chakra.div>
        <chakra.div aria-label={first_name}>{first_name + ' ' + last_name}</chakra.div>
        <chakra.div style={{ color: 'darkgray' }}>{desc}</chakra.div>
      </chakra.div>
    </chakraComponents.Option>
  );
};
OptionFirstLastNameDivision.propTypes = {
  data: PropTypes.object,
};

export const OptionNameCityAddress = (props) => {
  const { label, city, address, state, facility_type } = props.data;
  return (
    <chakraComponents.Option {...props}>
      <chakra.div>
        <chakra.div aria-label={label}>{label}</chakra.div>
        <chakra.div sx={{ color: 'gray.400' }}>{address && city && state ? address + ', ' + city + ', ' + state : 'No Address found'}</chakra.div>
        {facility_type && (
          <chakra.div fontSize="sm" style={{ color: 'darkgray' }}>
            {'Type: ' + facility_type}
          </chakra.div>
        )}
      </chakra.div>
    </chakraComponents.Option>
  );
};
OptionNameCityAddress.propTypes = {
  data: PropTypes.object,
};
