import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  HStack,
  TableContainer,
  Table,
  Tr,
  Th,
  Thead,
  Tbody,
  Td,
  Box,
  Tooltip,
} from '@chakra-ui/react';
import { BsPersonGear, BsTrash } from 'react-icons/bs';
import { LiaUsersCogSolid } from 'react-icons/lia';
import { Select } from 'chakra-react-select';
import { useSelector } from 'react-redux';
import { selectRateCardById } from '../contract/contractSlice';
import { selectModalities } from '../modality/modalitySlice';
import TableButton from '../common/table/TableButton';
import { useAddModalityMutation, useRemoveModalityMutation } from '@/app/services/nucleus.js';
import { AnimatePresence } from 'framer-motion';
import AlertComponent from '../common/AlertComponent';
import TableFooter from '../common/table/TableFooter';
import useTable from '@/hooks/useTable';
import { SingleValueShortName } from '../common/select/SingleValue';
import { OptionShortName } from '../common/select/Option';
import AnimatedRow from '../common/motion/AnimatedRow';
import LoadingWrapper from '../common/motion/LoadingWrapper';
import { chakraStyles } from '../common/select/styles';
import { isArrayValue } from '@/utils/helpers';
import ModalOverlayComponent from '../common/ModalOverlayComponent';
import { useNotification } from '@/hooks/useNotification';

const EditModalities = ({ specialties, contractId, isOpen, onClose, disabledSpecialties }) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const rateCard = useSelector(selectRateCardById);
  const modalities = useSelector(selectModalities);
  const [specialty, setSpecialty] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showEmpty, setShowEmpty] = useState(false);
  const [stagedSpecialties, setStagedSpecialties] = useState([]);
  const [stagingRemoval, setStagingRemoval] = useState(false);
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(stagedSpecialties, page, 4);

  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [stagedSpecialties, page, range, slice]);

  const [addModality, { isLoading: addModalityLoading, isSuccess: addModalitySuccess, isError: addModalityError, error: addModalityErrorMsg }] =
    useAddModalityMutation();

  const [
    removeModality,
    { isLoading: removeModalityLoading, isSuccess: removeModalitySuccess, isError: removeModalityError, error: removeModalityErrorMsg },
  ] = useRemoveModalityMutation();

  useEffect(() => {
    if (isOpen) {
      let editSpecialty = [];
      if (isArrayValue(rateCard?.modality_ids)) {
        editSpecialty = modalities.filter((spec) => spec.short_name && rateCard.modality_ids.includes(spec.id));
      }
      setStagedSpecialties(editSpecialty);
      setShowEmpty(editSpecialty.length === 0);
    }
  }, [isOpen, rateCard]);

  const addModalitySuccessCallback = () => {
    setSpecialty([]);
    setShowError(false);
  };
  useNotification(addModalitySuccess, addModalitySuccessCallback, addModalitySuccess, 'success', 'Profession/Specialty added');
  const removeModalitySuccessCallback = () => {
    addModalitySuccessCallback();
    let editSpecialty = [];
    if (isArrayValue(rateCard?.modality_ids)) {
      editSpecialty = modalities.filter((spec) => spec.short_name && rateCard.modality_ids.includes(spec.id));
    }
    setStagedSpecialties(editSpecialty);
    setStagingRemoval(false);
  };
  useNotification(removeModalitySuccess, removeModalitySuccessCallback, removeModalitySuccess, 'success', 'Profession/Specialty removed');

  useEffect(() => {
    if (addModalityError) {
      setShowError(true);
    }
  }, [addModalityError]);

  useEffect(() => {
    if (removeModalityError) {
      setShowError(true);
      setStagingRemoval(false);
    }
  }, [removeModalityError]);

  useEffect(() => {
    if (isOpen) {
      setSpecialty([]);
      setShowError(false);
      setStagingRemoval(false);
    }
  }, [isOpen]);

  const handleSaveModality = (e, rateCardId, modalityId) => {
    e.preventDefault();
    addModality({ contractId, rateCardId, modalityId })
      .unwrap()
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemoveModalityActions = (e, rateCardId, modalityId) => {
    e.preventDefault();
    const specialtiesWithOverlay = stagedSpecialties.map((modality) => {
      if (modality.id === modalityId) {
        modality = { ...modality, overlay: true };
      }
      return modality;
    });
    setStagedSpecialties(specialtiesWithOverlay);
    setStagingRemoval(true);
  };

  const cancelModalityDelete = (e) => {
    e.preventDefault();
    let editSpecialty = [];
    if (isArrayValue(rateCard?.modality_ids)) {
      editSpecialty = modalities.filter((spec) => spec.short_name && rateCard.modality_ids.includes(spec.id));
    }
    setStagedSpecialties(editSpecialty);
    setStagingRemoval(false);
  };

  const confirmModalityDelete = (e, rateCardId, modalityId) => {
    e.preventDefault();
    removeModality({ contractId, rateCardId, modalityId })
      .unwrap()
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSpecialtyChange = (value, { action }) => {
    if (action === 'select-option') {
      setSpecialty(value);
    } else if (action === 'clear') {
      setSpecialty([]);
    }
    setShowError(false);
  };

  return (
    <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} isCentered size="xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent maxW={'800px'}>
        <ModalHeader>
          <HStack>
            <BsPersonGear style={{ height: '25px', width: '25px' }} />
            <Text>Editing</Text>
            <Text>{rateCard?.name}</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton isDisabled={removeModalityLoading || addModalityLoading} />
        <ModalBody pb={6}>
          <VStack spacing={4}>
            <FormControl id="add_specialty">
              <HStack>
                <Select
                  chakraStyles={chakraStyles}
                  focusBorderColor="brand.700"
                  options={specialties}
                  value={specialty}
                  aria-label={'specialty'}
                  onChange={handleSpecialtyChange}
                  colorScheme="blue"
                  isClearable
                  getOptionLabel={(option) => option.short_name}
                  getOptionValue={(option) => option.id}
                  isOptionDisabled={(option) => disabledSpecialties.includes(option.id)}
                  isSearchable
                  placeholder="Add a profession/specialty"
                  components={{ SingleValue: SingleValueShortName, Option: OptionShortName }}
                />
                <Button
                  isLoading={addModalityLoading}
                  isDisabled={specialty.length === 0}
                  variant="cyan"
                  onClick={(e) => handleSaveModality(e, rateCard?.id, specialty.id)}
                >
                  Add
                </Button>
              </HStack>
            </FormControl>
            <TableContainer pt={6} width={'100%'} style={{ position: 'relative' }} minH={360}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Division</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <AnimatePresence>
                    {slice.map((val, i) => (
                      <AnimatedRow key={i} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        {val?.overlay ? (
                          <ModalOverlayComponent
                            loading={removeModalityLoading}
                            confirmText="Are you sure you want to remove this profession/specialty?"
                            handleDelete={(e) => confirmModalityDelete(e, rateCard?.id, val.id)}
                            handleCancel={cancelModalityDelete}
                          />
                        ) : (
                          <React.Fragment>
                            <Td>{val.short_name}</Td>
                            <Td>{val.division}</Td>
                            <Td>
                              <Tooltip hasArrow placement="top" label="Remove Profession/Specialty">
                                <Box p="0" as="span">
                                  <TableButton
                                    isDisabled={addModalityLoading || removeModalityLoading || stagingRemoval}
                                    aria-label="remove"
                                    icon={<BsTrash />}
                                    onClick={(e) => handleRemoveModalityActions(e, rateCard?.id, val.id)}
                                  />
                                </Box>
                              </Tooltip>
                            </Td>
                          </React.Fragment>
                        )}
                      </AnimatedRow>
                    ))}
                  </AnimatePresence>
                </Tbody>
              </Table>
              {showEmpty && (
                <LoadingWrapper py={16} height={'40px'} alignItems="center">
                  <Text>No profession/specialties assigned</Text>
                </LoadingWrapper>
              )}
              {showError && addModalityError && (
                <AlertComponent
                  icon={<LiaUsersCogSolid style={{ height: '24px', width: '24px' }} />}
                  status="error"
                  title={specialty?.short_name}
                  description={addModalityErrorMsg?.data?.detail}
                />
              )}
              {showError && removeModalityError && (
                <AlertComponent
                  icon={<LiaUsersCogSolid style={{ height: '24px', width: '24px' }} />}
                  status="error"
                  title={specialty?.short_name}
                  description={removeModalityErrorMsg?.data?.detail}
                />
              )}
            </TableContainer>
            {range && range.length > 1 && (
              <Box width="100%">
                <TableFooter range={range} setPage={setPage} page={page} />
              </Box>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} isDisabled={addModalityLoading || removeModalityLoading || stagingRemoval}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

EditModalities.propTypes = {
  specialties: PropTypes.array,
  contractId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  disabledSpecialties: PropTypes.array,
};

export default EditModalities;
