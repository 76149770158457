import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';

export function PrivateOutlet({ children }) {
  const auth = useAuth();
  const location = useLocation();
  if (auth.token === '' || !auth?.token) {
    return <Navigate to={`/login`} replace state={{ from: location }} />;
  }
  return children;
}
