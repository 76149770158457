import { Box, Flex, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Layout from '../common/Layout';
import { Outlet } from 'react-router-dom';
import { useRole } from '@/hooks/useRole';
import { useGetCertificationsQuery } from '@/app/services/nucleus';
import AlertComponent from '../common/AlertComponent';

const Certifications = () => {
  useRole('support');
  const [errorTitle, setErrorTitle] = useState('');
  const [errorDesc, setErrorDesc] = useState('');

  const { isLoading, isError, error: certificationsError } = useGetCertificationsQuery();

  useEffect(() => {
    if (isError) {
      setErrorTitle('Certifications Error');
      setErrorDesc(certificationsError?.data?.detail);
    }
  });

  return (
    <Box data-test-id="pact-certifications">
      <Layout pageTitle={'Pact'}>
        {isLoading ? (
          <Flex alignItems="center" justifyContent="center" py={200}>
            <Spinner color="brand.900" size="lg" />
          </Flex>
        ) : isError ? (
          <AlertComponent status="warning" title={errorTitle} description={errorDesc} />
        ) : (
          <Outlet />
        )}
      </Layout>
    </Box>
  );
};

export default Certifications;
