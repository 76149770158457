import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectContractFacilities } from '@/features/contract/contractSlice.js';
import { getIndexById, intersection, isArrayEmpty, isArrayValue, not } from '@/utils/helpers.js';

/**
 * TransferList hook - this hook filters transfer list data that appears in the left pane
 *
 * @param {Object[]} facility - the data in the right panel that represents our final result. used as a dep
 * @param {function} onNoResult - function to set the no result state
 * @param {string} nameFilter - the filter string
 * @param {boolean} displayDisabled - show disabled facilities
 * @param {String[]} disabledFacilityIds - list of disabled facility ids
 * @param {Object[]} selected - the selected items in transfer list
 * @param {function} onSelected - function to set the selected items
 * @param {Object[]} transferRight - the data in the right panel
 * @param {Object} stateFilter - the selected state filter
 * @param {Object[]} facilitiesByName - the facilities filtered by name or address
 * @param {function} onTransferLeft - function to set the left panel data
 */
export const useTransferList = (
  facility,
  onNoResult,
  nameFilter,
  displayDisabled,
  disabledFacilityIds,
  selected,
  onSelected,
  transferRight,
  stateFilter,
  facilitiesByName,
  onTransferLeft,
) => {
  const facilities = useSelector(selectContractFacilities);

  useEffect(() => {
    let left = facilities;
    onNoResult(false);
    if (!displayDisabled) {
      left = facilities.filter((f) => !disabledFacilityIds.includes(f.id));
    }
    if (stateFilter) {
      left = left.filter((f) => f.state === stateFilter.label);
    }
    // other filters may be applied. We need to intersect the results with search results
    if (isArrayValue(facilitiesByName)) {
      left = intersection(left, facilitiesByName, getIndexById);
    }
    // if the user has pushed items to the right list, we need to remove them from the left list when it's recalculated
    if (isArrayValue(transferRight)) {
      left = not(left, transferRight, getIndexById);
    }
    // if the users selection is not visible due to filters, we remove the selections
    if (isArrayValue(selected)) {
      const sel = intersection(selected, left, getIndexById);
      onSelected(sel);
    }
    if (isArrayEmpty(facilitiesByName) && facilitiesByName.length === 0 && nameFilter !== '') {
      onNoResult(true);
    }
    onTransferLeft(left);
  }, [displayDisabled, disabledFacilityIds, facilities, stateFilter, facilitiesByName, facility]);

  return null;
};
