import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import { store } from './app/store';
import { extendTheme } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { ErrorBoundary } from '@appsignal/react';
import { appsignal } from './appsignal';

import '@fontsource/roboto/400.css';
import '@fontsource/montserrat/400.css';
import { triageAscii } from './assets/triage-ascii';

async function deferRender() {
  if (import.meta.env.VITE_MSW_DEV === 'true') {
    const { worker } = await import('./mocks/browser');
    return worker.start();
  } else {
    return Promise.resolve();
  }
}
// INFO: set VITE_MSW_DEV in .env.development to use MSW in development.

const theme = extendTheme({
  components: {
    Button: {
      variants: {
        purple: {
          bg: 'brand.900',
          color: 'white',
          _hover: {
            _disabled: {
              bg: 'brand.800',
            },
            bg: 'brand.800',
          },
        },
        white: {
          bg: 'gray.50',
          color: 'gray.800',
          _hover: {
            _disabled: {
              bg: 'gray.100',
            },
            bg: 'gray.100',
          },
        },
        grab: {
          bg: 'transparent',
          color: 'gray.400',
          _hover: {
            _disabled: {
              bg: 'gray.200',
            },
            color: 'gray.500',
            bg: 'transparent',
          },
        },
        cyan: {
          bg: 'brand.700',
          color: 'white',
          _hover: {
            _disabled: {
              bg: 'cyan.500',
            },
            bg: 'cyan.500',
          },
        },
        red: {
          bg: 'red.500',
          color: 'white',
          _hover: {
            _disabled: {
              bg: 'red.600',
            },
            bg: 'red.400',
          },
        },
        blue: {
          bg: 'brand.800',
          color: 'white',
          _hover: {
            _disabled: {
              bg: 'blue.700',
            },
            bg: 'blue.600',
          },
        },
      },
    },
  },
  layerStyles: {
    base: {
      color: 'purple.600',
      bg: 'gray.900',
    },
    secondary: {
      color: 'cyan.600',
    },
    link: {
      color: 'blue.600',
    },
  },
  styles: {
    global: {
      'html, body': {
        height: '100vh',
        backgroundColor: 'gray.50',
      },
      body: {
        color: 'gray.700',
      },
      a: {
        color: 'blue.600',
      },
    },
  },
  fonts: {
    heading: `'Montserrat', sans-serif`,
    body: `'Roboto', sans-serif`,
  },
  colors: {
    lightgray: {
      50: '#FdFdFd',
      100: '#f8f8f8',
      200: '#E8E9E9',
      300: '#D2D4D4',
      400: '#ACAFAF',
      500: '#959898',
      600: '#7E8181',
      700: '#646868',
      800: '#4B4E4E',
      900: '#323434',
    },
    gray: {
      50: '#F8F8F8',
      100: '#E8E9E9',
      200: '#D2D4D4',
      300: '#ACAFAF',
      400: '#959898',
      500: '#7E8181',
      600: '#646868',
      700: '#4B4E4E',
      800: '#323434',
      900: '#191A1A',
    },
    cyan: {
      50: '#E5F8FF',
      100: '#B8ECFF',
      200: '#8ADFFF',
      300: '#5CD3FF',
      400: '#2EC6FF',
      500: '#00B8FD',
      600: '#00A3E0',
      700: '#006F99',
      800: '#004A66',
      900: '#002533',
    },
    blue: {
      50: '#E5F3FF',
      100: '#B8DFFF',
      200: '#8ACAFF',
      300: '#5CB5FF',
      400: '#2EA0FF',
      500: '#008BFF',
      600: '#0070CC',
      700: '#005499',
      800: '#004680',
      900: '#001C33',
    },
    purple: {
      50: '#EFEEF6',
      100: '#D4D0E7',
      200: '#B8B1D7',
      300: '#9C93C8',
      400: '#8075B8',
      500: '#6456A9',
      600: '#514689',
      700: '#3C3465',
      800: '#282244',
      900: '#141122',
    },
    red: {
      25: '#FEF4F4',
      50: '#FCE9E9',
      100: '#F6C0C0',
      200: '#F19898',
      300: '#EB6F6F',
      400: '#E64747',
      500: '#E01F1F',
      600: '#B41818',
      700: '#871212',
      800: '#5A0C0C',
      900: '#2D0606',
    },
    brand: {
      900: '#514689',
      800: '#005499',
      700: '#00a3e0',
    },
  },
});
triageAscii();
console.log('Pact v' + import.meta.env.VITE_PACT_VERSION);

deferRender().then(() => {
  ReactDOM.createRoot(document.getElementById('root')).render(
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <ErrorBoundary instance={appsignal}>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </ChakraProvider>
    </Provider>,
  );
});
