import React from 'react';
import { Box } from '@chakra-ui/react';

const FacilityListItem = ({ item }) => {
  const address = item?.address ? item.address : 'No address available';
  const city = item?.city ? item.city : 'No city available';
  const state = item?.state ? item.state : 'No state available';
  const showAlt = item?.address || item?.city || item?.state;
  return (
    <Box>
      <Box pt={2} pb={showAlt ? 0 : 2} fontSize="md" width="380px" textOverflow="ellipsis" overflow="hidden" maxHeight="40px" whiteSpace="nowrap">
        {item?.name}
      </Box>
      {showAlt && (
        <Box pb={2} fontSize="sm" width="380px" color="gray.300" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
          {address + ', ' + city + ', ' + state}
        </Box>
      )}
    </Box>
  );
};
export default FacilityListItem;
