import PropTypes from 'prop-types';
import { IconButton } from '@chakra-ui/react';

const TableButton = ({ icon, label, color, ...rest }) => {
  return <IconButton sx={{ border: '0px' }} icon={icon} aria-label={label} colorScheme={color || 'cyan'} variant="outline" {...rest} />;
};

TableButton.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  color: PropTypes.string,
};

export default TableButton;
