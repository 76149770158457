import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  facilityMappingSortBy: 'name',
  facilityMappingSortDirection: 'asc',
};

export const mappingSlice = createSlice({
  name: 'mapping',
  initialState,
  reducers: {
    setFacilityMappingSortBy: (state, { payload }) => {
      state.facilityMappingSortBy = payload;
    },
    setFacilityMappingSortDirection: (state, { payload }) => {
      state.facilityMappingSortDirection = payload;
    },
  },
});

export const { setFacilityMappingSortBy, setFacilityMappingSortDirection } = mappingSlice.actions;

export const selectFacilityMappingSortBy = (state) => state.mapping.facilityMappingSortBy;
export const selectFacilityMappingSortDirection = (state) => state.mapping.facilityMappingSortDirection;

export default mappingSlice.reducer;
