import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormErrorMessage, HStack } from '@chakra-ui/react';
import { useLazySearchContractsQuery } from '@/app/services/nucleus';
import { AsyncSelect, createFilter, components } from 'chakra-react-select';
import { SingleValueName } from '../common/select/SingleValue';
import { OptionNameId } from '../common/select/Option';
import { useSelector } from 'react-redux';
import { selectContractId } from '@/features/contract/contractSlice.js';

const ContractSearchInput = (props) => {
  const { isHidden, ...rest } = props;
  return <components.Input {...rest} isHidden={false} />;
};

const ContractSearch = ({
  width,
  contractDisabled,
  isInvalid,
  contractMaxWidth,
  contractRequiredError,
  contractSearchError,
  isReset,
  onContractInputChange,
  onContractChange,
}) => {
  const id = useSelector(selectContractId);
  const [contractInput, setContractInput] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [localContract, setLocalContract] = useState(null);

  useEffect(() => {
    if (isReset) {
      setContractInput('');
      setSearchResults(null);
    }
  }, [isReset]);

  const [searchContracts, { isError: isSearchContractsError, error: searchContractsError }] = useLazySearchContractsQuery();

  const handleStageContract = (value, { action }) => {
    setLocalContract(value);
    setContractInput(value?.label ?? '');
    typeof onContractChange === 'function' && onContractChange(value, action);
    if (action === 'clear') {
      setLocalContract(null);
    }
  };

  const handleInputChange = (inputValue, { action }) => {
    typeof onContractInputChange === 'function' && onContractInputChange(inputValue, action);
    if (action === 'input-change') {
      setContractInput(inputValue);
    }
  };

  const loadOptions = (inputValue, callback) => {
    let payload = { search: inputValue };
    searchContracts(payload)
      .unwrap()
      .then((payload) => {
        setSearchResults(payload);
        return callback(payload);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <HStack spacing={4} alignItems="start" width={width || 'auto'}>
      <FormControl maxW={contractMaxWidth || 'auto'} isInvalid={isSearchContractsError || isInvalid} aria-label="contract_search_control">
        <AsyncSelect
          id={'contract_search'}
          defaultOptions={contractInput !== '' ? searchResults : null}
          isDisabled={contractDisabled}
          aria-label={'contract_search'}
          chakraStyles={{
            dropdownIndicator: (defaultStyles) => ({
              ...defaultStyles,
              display: 'none',
            }),
          }}
          focusBorderColor="brand.700"
          value={localContract}
          inputValue={contractInput}
          onChange={handleStageContract}
          isOptionDisabled={(option) => option.id === id}
          onInputChange={handleInputChange}
          isClearable
          isSearchable
          placeholder="Search Contracts"
          loadOptions={(value, cb) => loadOptions(value, cb)}
          options={searchResults}
          noOptionsMessage={() => null}
          filterOption={createFilter({ ignoreAccents: false })}
          components={{
            Input: ContractSearchInput,
            SingleValue: SingleValueName,
            Option: OptionNameId,
          }}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
        <FormErrorMessage aria-label="contract_search_error">
          {contractRequiredError || contractSearchError || searchContractsError?.data?.detail}
        </FormErrorMessage>
      </FormControl>
    </HStack>
  );
};

ContractSearch.propTypes = {
  width: PropTypes.string,
  contractDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  contractMaxWidth: PropTypes.string,
  contractRequiredError: PropTypes.string,
  contractSearchError: PropTypes.string,
  isReset: PropTypes.bool,
  onContractInputChange: PropTypes.func,
  onContractChange: PropTypes.func,
};

ContractSearchInput.propTypes = {
  isHidden: PropTypes.bool,
};

export default ContractSearch;
