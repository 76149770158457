import PropTypes from 'prop-types';
import { Box, HStack, Icon, Text } from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';
import TruncatedTextComponent from '../common/TruncatedTextComponent';

const JobErrorListComponent = ({ errors }) => {
  if (Array.isArray(errors)) {
    return errors.map((err, i) => (
      <Box key={err.type + i} status="error" mt={6} bgColor="#FFF4F4" p={4}>
        <Box>
          <HStack>
            <Icon boxSize={6} as={FiAlertCircle} color={'red'} />
            <Box maxWidth="sm">
              <Text as="b">
                <TruncatedTextComponent length={150} title="Error Title" text={err.msg} />
              </Text>
              <Box>
                <b>Row: </b>
                {err.loc[1]}
              </Box>
              <Box>
                <b>Field: </b>
                {err.loc[2]}
              </Box>
            </Box>
          </HStack>
        </Box>
      </Box>
    ));
  }
};
JobErrorListComponent.propTypes = {
  errors: PropTypes.array,
};

export default JobErrorListComponent;
