import { Box, Heading, Flex, Tooltip, Button, useDisclosure, Stack, Spinner, Text, HStack } from '@chakra-ui/react';
import { useDisableAuthUserMutation, useGetAuthUsersQuery, useResetAuthUserMutation } from '@/app/services/nucleus';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthUserSortBy,
  selectAuthUserSortDirection,
  selectAuthUserSearchStatus,
  setAuthUserSearchStatus,
  setAuthUserSortBy,
  setAuthUserSortDirection,
  setAuthUserId,
} from './userSlice';
import { ERROR, LOADING } from '@/utils/constants';
import { createColumnHelper } from '@tanstack/react-table';
import { BsPencilSquare, BsPersonAdd, BsPersonFillCheck, BsPersonSlash } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { outputRoles } from './userHelper';
import { MdLockReset } from 'react-icons/md';
import ModalComponent from '../common/ModalComponent';
import AlertComponent from '../common/AlertComponent';
import { useNotification } from '@/hooks/useNotification';
import { useRole } from '@/hooks/useRole';
import DataTableSearchAndFiltering from '../common/table/DataTableSearchAndFiltering';
import TableButton from '../common/table/TableButton';

const UserManagement = () => {
  useRole('admin');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userModal = useDisclosure();
  const urlChange = useLocation();

  const authUserSearchStatus = useSelector(selectAuthUserSearchStatus);
  const sortBy = useSelector(selectAuthUserSortBy);
  const sortDirection = useSelector(selectAuthUserSortDirection);
  const columnHelper = createColumnHelper();
  const [currentUser, setCurrentUser] = useState(null);
  const [userText, setUserText] = useState('');

  const { data: users, isLoading, isError, error: usersError } = useGetAuthUsersQuery();

  const [
    reset,
    {
      isLoading: resetAuthUserLoading,
      isSuccess: resetAuthUserSuccess,
      isError: resetAuthUserError,
      error: resetAuthUserErrorMsg,
      reset: resetAuthUserReset,
    },
  ] = useResetAuthUserMutation();

  const [
    disable,
    {
      isLoading: disableAuthUserLoading,
      isSuccess: disableAuthUserSuccess,
      isError: disableAuthUserError,
      error: disableAuthUserErrorMsg,
      reset: disableAuthUserReset,
    },
  ] = useDisableAuthUserMutation();

  useNotification(resetAuthUserSuccess, resetAuthUserReset, resetAuthUserSuccess, 'success', userText === 'Reset' ? 'User reset' : 'User enabled');
  useNotification(disableAuthUserSuccess, disableAuthUserReset, disableAuthUserSuccess, 'success', 'User disabled');

  const userColumns = [
    columnHelper.accessor('first_name', {
      header: 'First Name',
    }),
    columnHelper.accessor('last_name', {
      header: 'Last Name',
    }),
    columnHelper.accessor('email', {
      header: 'Email',
    }),
    columnHelper.accessor('roles', {
      header: 'Role',
      cell: ({ getValue }) => {
        const value = getValue();
        return outputRoles(value);
      },
    }),
    columnHelper.accessor('', {
      cell: (info) => {
        const user = info.row.original;
        return (
          <>
            <HStack>
              <Tooltip hasArrow placement="top" label="Edit User">
                <Box p="0" as="span">
                  <TableButton aria-label="edit" icon={<BsPencilSquare />} onClick={(e) => handleEditUser(e, user?.id)} />
                </Box>
              </Tooltip>
              {user?.is_over_max_login_attempts && !user?.disabled_at && (
                <Tooltip hasArrow placement="top" label="Reset User">
                  <Box p="0" as="span">
                    <TableButton aria-label="reset-button" icon={<MdLockReset size="22px" />} onClick={(e) => handleResetUser(e, user?.id)} />
                  </Box>
                </Tooltip>
              )}
              {user?.disabled_at ? (
                <Tooltip hasArrow placement="top" label="Enable User">
                  <Box p="0" as="span">
                    <TableButton aria-label="enable-button" icon={<BsPersonFillCheck size="20px" />} onClick={(e) => handleEnableUser(e, user?.id)} />
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip hasArrow placement="top" label="Disable User">
                  <Box p="0" as="span">
                    <TableButton aria-label="disable-button" icon={<BsPersonSlash size="20px" />} onClick={(e) => handleDisableUser(e, user?.id)} />
                  </Box>
                </Tooltip>
              )}
            </HStack>
          </>
        );
      },
      header: 'Action',
    }),
  ];

  useEffect(() => {
    if (!userModal.isOpen) {
      setCurrentUser(null);
      resetAuthUserReset();
      disableAuthUserReset();
    }
  }, [userModal.isOpen]);

  const userAction = (userText) => {
    if (userText === 'Disable') {
      disable({ id: currentUser })
        .unwrap()
        .then((payload) => {
          if (payload) {
            userModal.onClose();
          }
        })
        .catch((error) => console.log('disable user rejected', error));
    } else if (userText === 'Reset' || userText === 'Enable') {
      reset({ id: currentUser })
        .unwrap()
        .then((payload) => {
          if (payload) {
            userModal.onClose();
          }
        })
        .catch((error) => console.log(`${userText} user rejected`, error));
    }
  };

  const updateUserAction = (e, id, label) => {
    e.preventDefault();
    setUserText(label);
    userModal.onOpen();
    setCurrentUser(id);
  };

  const handleDisableUser = (e, id) => {
    updateUserAction(e, id, 'Disable');
  };

  const handleResetUser = (e, id) => {
    updateUserAction(e, id, 'Reset');
  };

  const handleEnableUser = (e, id) => {
    updateUserAction(e, id, 'Enable');
  };

  const handleEditUser = (e, id) => {
    dispatch(setAuthUserId(id));
    navigate(`/user_management/${id}`, { state: { from: urlChange } });
  };

  const handleUserSearchStatus = (text) => {
    dispatch(setAuthUserSearchStatus(text));
  };

  const handleUserSortBy = (column) => {
    dispatch(setAuthUserSortBy(column));
  };

  const handleUserSortDirection = (direction) => {
    dispatch(setAuthUserSortDirection(direction));
  };

  const handleNewUser = (e) => {
    e.preventDefault();
    navigate('/user_management/new');
  };

  const handleTableLoaded = (table) => {
    if (users) {
      users.map((user, index) => {
        let color = '';
        if (user?.disabled_at) {
          color = 'gray.50';
        } else if (user?.is_over_max_login_attempts) {
          color = 'red.25';
        }
        table.options.meta?.addRowColor(index, color);
      });
    }
  };

  const icons = {
    Enable: <BsPersonFillCheck size="24px" />,
    Disable: <BsPersonSlash size="24px" />,
    Reset: <MdLockReset size="24px" />,
  };

  const getIcon = (text) => icons[text];

  return (
    <React.Fragment>
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        User Management
      </Heading>
      <DataTableSearchAndFiltering
        title="User Management"
        originalData={users}
        searchStatus={authUserSearchStatus}
        isSearchLoading={authUserSearchStatus === LOADING}
        isSearchError={authUserSearchStatus === ERROR}
        isDataLoading={isLoading}
        isDataError={isError || usersError}
        dataErrorMsg={usersError?.data?.detail}
        dataErrorTitle={'Error fetching users'}
        dataColumns={userColumns}
        searchColumns={['first_name', 'last_name', 'email']}
        sortBy={sortBy}
        sortDirection={sortDirection}
        handleSortBy={handleUserSortBy}
        handleSortDirection={handleUserSortDirection}
        handleSearchStatus={handleUserSearchStatus}
        handleTableLoaded={handleTableLoaded}
      >
        <Button leftIcon={<BsPersonAdd />} onClick={handleNewUser} variant="purple" type="submit">
          Add User
        </Button>
      </DataTableSearchAndFiltering>
      <ModalComponent
        icon={getIcon(userText)}
        size="sm"
        title={`${userText} User`}
        primaryText="Yes"
        secondaryText="No"
        handleConfirm={() => userAction(userText)}
        onOpen={userModal.onOpen}
        isOpen={userModal.isOpen}
        onClose={userModal.onClose}
        isError={false}
      >
        <Stack spacing={3}>
          {resetAuthUserLoading || disableAuthUserLoading ? (
            <Flex alignItems="center" justifyContent={'center'}>
              <Spinner color="brand.900" size="lg" />
            </Flex>
          ) : (
            <Box>
              <Text>{`Are you sure you want to ${userText.toLowerCase()} this user?`}</Text>
              {(resetAuthUserError || disableAuthUserError) && (
                <AlertComponent
                  status="error"
                  title={`Error: Unable to ${userText} user`}
                  description={resetAuthUserError ? resetAuthUserErrorMsg?.data?.detail : disableAuthUserErrorMsg?.data?.detail}
                />
              )}
            </Box>
          )}
        </Stack>
      </ModalComponent>
    </React.Fragment>
  );
};

export default UserManagement;
