import ModalComponent from '@/features/common/ModalComponent';
import PropTypes from 'prop-types';
import { FaRegCopy } from 'react-icons/fa';
import { Box, Card, CardBody, Flex, Spinner, Stack, StackDivider, Text } from '@chakra-ui/react';
import AlertComponent from '@/features/common/AlertComponent';
import { PUBLISHED } from '@/utils/constants';
import React from 'react';

const CopyContractModal = ({
  source,
  children,
  onConfirm,
  modal,
  isContractsLoading,
  isCloneLoading,
  cloneError,
  contractError,
  cloneErrorMsg,
  contractErrorMsg,
}) => {
  return (
    <ModalComponent
      icon={<FaRegCopy />}
      size="md"
      title="Copy Contract"
      primaryText="Copy"
      secondaryText="Cancel"
      handleConfirm={onConfirm}
      onOpen={modal.onOpen}
      isOpen={modal.isOpen}
      onClose={modal.onClose}
      isError={cloneError}
    >
      <Stack spacing={3}>
        {isCloneLoading || isContractsLoading ? (
          <Flex alignItems="center" justifyContent="center" flexDirection={'column'} minH={'150px'}>
            <Spinner color="brand.900" size="lg" />
            {isContractsLoading && (
              <Box>
                <Text fontSize="sm" color="purple.600" mt={2}>
                  Loading contracts
                </Text>
              </Box>
            )}
          </Flex>
        ) : (
          <Box>
            <Box pb={3}>
              <Text>Are you sure you want to copy this contract?</Text>
            </Box>
            <Card>
              <CardBody>
                <Stack divider={<StackDivider />} spacing={3}>
                  <Text fontSize="sm" color="blue.600">
                    <Text as="span" color="gray.500" pr={4}>
                      Name:
                    </Text>
                    {source?.name}
                  </Text>
                  <Text fontSize="sm" color="blue.600">
                    <Text as="span" color="gray.500" pr={4}>
                      Status:
                    </Text>
                    {children}
                  </Text>
                  {source?.status === PUBLISHED && (
                    <Text fontSize="sm" color="blue.600">
                      <Text as="span" color="gray.500" pr={4}>
                        Published Status:
                      </Text>
                      {source?.published_status}
                    </Text>
                  )}
                  <Text fontSize="sm" color="blue.600">
                    <Text as="span" color="gray.500" pr={4}>
                      Effective Date:
                    </Text>
                    {source?.starts_at}
                  </Text>
                  {(cloneError || contractError) && (
                    <AlertComponent
                      status="error"
                      title="Error"
                      description={cloneError ? cloneErrorMsg?.data?.detail : contractErrorMsg?.data?.detail}
                    />
                  )}
                </Stack>
              </CardBody>
            </Card>
          </Box>
        )}
      </Stack>
    </ModalComponent>
  );
};
CopyContractModal.propTypes = {
  source: PropTypes.object,
  children: PropTypes.node,
  onConfirm: PropTypes.func,
  modal: PropTypes.object,
  isContractsLoading: PropTypes.bool,
  isCloneLoading: PropTypes.bool,
  cloneError: PropTypes.bool,
  contractError: PropTypes.bool,
  cloneErrorMsg: PropTypes.object,
  contractErrorMsg: PropTypes.object,
};
export default CopyContractModal;
