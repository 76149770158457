import PropTypes from 'prop-types';
import { Button, HStack, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, ModalHeader, ModalCloseButton, Text } from '@chakra-ui/react';

const ModalComponent = ({ title, icon, children, isOpen, onClose, size, isError, handleConfirm, primaryText, secondaryText, buttonWidth }) => {
  const maxWidthBySize = (size) => {
    switch (size) {
      case 'xl':
        return '1210px';
      case 'lg':
        return '900px';
      case 'md':
        return '600px';
      case 'sm':
        return '400px';
      case 'xs':
        return '200px';
      default:
        return '600px';
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size || 'md'}>
      <ModalOverlay />
      <ModalContent maxWidth={maxWidthBySize(size)}>
        {icon === null || typeof icon === 'undefined' ? (
          <ModalHeader>{title}</ModalHeader>
        ) : (
          <ModalHeader>
            <HStack>
              {icon}
              <Text>{title}</Text>
            </HStack>
          </ModalHeader>
        )}
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          {primaryText && (
            <Button
              variant="cyan"
              mr={buttonWidth === '100%' ? 0 : 3}
              onClick={(e) => handleConfirm(e)}
              isDisabled={isError}
              width={buttonWidth || 'auto'}
            >
              {primaryText}
            </Button>
          )}
          {secondaryText && (
            <Button onClick={onClose} width={buttonWidth || 'auto'}>
              {secondaryText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  size: PropTypes.string,
  isError: PropTypes.bool,
  handleConfirm: PropTypes.func,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
};

export default ModalComponent;
