import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { FiLayout } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { chakraStyles } from '../common/select/styles';
import { selectRolesList } from '@/features/enum/enumSlice';
import AlertComponent from '@/features/common/AlertComponent';
import LoaderOverlay from '@/features/common/LoaderOverlay';

const AddFeatureModal = ({ isOpen, onClose, isLoading, isError, errorMsg, handleSubmit }) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const labelColor = useColorModeValue('gray.500', 'gray.400');
  const roles = useSelector(selectRolesList);
  const [featureName, setFeatureName] = useState('');
  const [role, setRole] = useState(null);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setFeatureName('');
      setRole(null);
      setEnabled(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleNameChange = (e) => {
    setFeatureName(e.target.value);
  };

  const handleRoles = (selectOptions) => {
    setRole(selectOptions || []);
  };

  const handleEnabled = (e) => {
    setEnabled(e.target.checked);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const roleIds = role.map((r) => r.value);
    handleSubmit({ name: featureName, roleIds, enabled });
  };

  return (
    <Modal
      title="Add Feature"
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size="2xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <form id="add_feature" onSubmit={submitHandler}>
          <ModalHeader>
            <HStack>
              <FiLayout />
              <Text>Add Feature</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4} position={'relative'}>
              <LoaderOverlay loading={isLoading} />
              <FormControl isInvalid={false} aria-label={'feature-control'}>
                <Grid templateColumns="100px auto">
                  <GridItem pt={3}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="name" mb={0} textAlign="end">
                      Name
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Input
                      id="name"
                      focusBorderColor={'brand.700'}
                      type="text"
                      isRequired={false}
                      isDisabled={false}
                      value={featureName}
                      onChange={handleNameChange}
                    />
                  </GridItem>
                </Grid>
              </FormControl>
              <FormControl isInvalid={false} aria-label={'feature-control'}>
                <Grid templateColumns="100px auto">
                  <GridItem pt={3}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="roles" mb={0} textAlign="end">
                      Roles
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Select
                      id="roles"
                      aria-label="feature-roles"
                      focusBorderColor="brand.700"
                      chakraStyles={chakraStyles}
                      options={roles}
                      isMulti
                      onChange={handleRoles}
                      value={role}
                      colorScheme="blue"
                      placeholder="Select a role"
                    />
                  </GridItem>
                </Grid>
              </FormControl>
              <FormControl isInvalid={false} aria-label={'feature-enable'}>
                <Grid templateColumns="100px auto">
                  <GridItem pt={3}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="roles" mb={0} textAlign="end">
                      Enabled
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Switch
                      pt={3}
                      aria-label="add-feature-enable"
                      id="enable"
                      size="md"
                      colorScheme="cyan"
                      isChecked={enabled}
                      onChange={handleEnabled}
                    />
                  </GridItem>
                </Grid>
              </FormControl>
              {isError && <AlertComponent status="error" title="Error adding feature" description={errorMsg?.data?.detail} />}
              <HStack id="add_feature" width="100%" py={3} px={8} justifyContent={'end'}>
                <Button onClick={onClose}>Cancel</Button>
                <Button type={'submit'} variant={'purple'} isDisabled={featureName === ''}>
                  Submit
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
};
AddFeatureModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  errorMsg: PropTypes.object,
  handleSubmit: PropTypes.func,
};
export default AddFeatureModal;
