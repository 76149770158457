import PropTypes from 'prop-types';
import { useState } from 'react';
import { IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

const PasswordInput = ({ isDisabled, label, value, handleChange, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  return (
    <InputGroup>
      <Input
        {...rest}
        aria-label={label || ''}
        isDisabled={isDisabled}
        type={showPassword ? 'text' : 'password'}
        size="md"
        value={value}
        onChange={(e) => handleChange(e)}
      />
      <InputRightElement>
        <IconButton
          onClick={handleShowPassword}
          icon={showPassword ? <BsEye size="25px" /> : <BsEyeSlash size="25px" />}
          variant="ghost"
          size="sm"
          aria-label={`show ${label || 'password'}`}
        />
      </InputRightElement>
    </InputGroup>
  );
};
PasswordInput.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
};
export default PasswordInput;
