import React, { useEffect, useState } from 'react';
import { Flex, Spinner, TableContainer } from '@chakra-ui/react';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import TableComponent from '@/features/common/table/TableComponent';
import PaginationComponent from '@/features/common/PaginationComponent';
import { scrollbar, pageOptions } from '@/utils/constants';
import PropTypes from 'prop-types';
import { addColorData, updateTableData } from '@/features/common/table/helper';

// NOTE: This is a general purpose data table component to work with server side pagination
const SSDataTable = ({ loading, data, columns, maxWidth, showScrollbar, pageCount, pagination, onPaginationChange, editRowLoading }) => {
  const [pageSize, setPageSize] = useState(null);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (data) {
      setTableData(() => [...data]);
    }
  }, [data]);

  useEffect(() => {
    if (pagination?.pageSize) {
      setPageSize({ value: pagination.pageSize, label: pagination.pageSize });
    }
  }, [pagination]);

  const table = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setTableData((old) => updateTableData(rowIndex, columnId, old, value));
      },
      resetRowData: (rowIndex) => {
        setTableData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...data[index],
              };
            }
            return row;
          }),
        );
      },
      addRowColor: (rowIndex, value) => {
        setTableData((old) => addColorData(rowIndex, old, value));
      },
      addEditFlag: (rowIndex, columnId, value) => {
        setTableData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...row,
                edit: value,
              };
            }
            return row;
          }),
        );
      },
    },
    state: { pagination },
    pageCount,
  });

  const handlePageSize = (val) => {
    setPageSize(val);
  };

  return (
    <TableContainer css={showScrollbar && scrollbar} width={'100%'}>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" mt={20}>
          <Spinner color="brand.900" size="lg" />
        </Flex>
      ) : (
        <TableComponent
          rowLoading={editRowLoading}
          table={table}
          columns={columns}
          showNoResults={loading === false && data && data.length === 0}
          maxWidth={maxWidth}
        />
      )}
      {data && data.length > 10 && !loading && (
        <PaginationComponent table={table} pageSize={pageSize} handlePageSize={handlePageSize} pageOptions={pageOptions} />
      )}
    </TableContainer>
  );
};
SSDataTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  columns: PropTypes.array,
  maxWidth: PropTypes.string,
  showScrollbar: PropTypes.bool,
  pageCount: PropTypes.number,
  pagination: PropTypes.object,
  onPaginationChange: PropTypes.func,
  editRowLoading: PropTypes.bool,
};
export default SSDataTable;
