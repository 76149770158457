import React from 'react';
import { components } from 'chakra-react-select';
import { BsCaretDownFill } from 'react-icons/bs';

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <BsCaretDownFill />
    </components.DropdownIndicator>
  );
};
