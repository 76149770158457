import React from 'react';
import PropTypes from 'prop-types';
import AlertComponent from '../common/AlertComponent';
import { Alert, AlertIcon, Text } from '@chakra-ui/react';

const DisplayArchivePublishContent = ({ error, errorMsg, isArchive }) =>
  error ? (
    <AlertComponent status="error" title="Error" description={errorMsg} />
  ) : (
    <React.Fragment>
      <Text>Are you sure you want to {isArchive ? 'archive' : 'publish'} this contract?</Text>
      <Alert status="warning" borderRadius={6}>
        <AlertIcon />
        Warning: {isArchive ? 'Archiving' : 'Publishing'} a contract cannot be undone.
      </Alert>
    </React.Fragment>
  );

DisplayArchivePublishContent.propTypes = {
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  isArchive: PropTypes.bool,
};

export default DisplayArchivePublishContent;
