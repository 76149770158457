import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectDivisionsList, selectJobSourcesList } from '@/features/enum/enumSlice';
import { selectModalities } from '@/features/modality/modalitySlice';
import { isArrayValue } from '@/utils/helpers';
import ModalComponent from '@/features/common/ModalComponent';
import { Select } from 'chakra-react-select';
import { chakraStyles } from '@/features/common/select/styles';
import { SingleValueShortName } from '@/features/common/select/SingleValue';
import { OptionRole, OptionShortName } from '@/features/common/select/Option';
import PropTypes from 'prop-types';
import FacilitySearch from '@/features/facility/FacilitySearch';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import { selectUsersList } from '@/features/user/userSlice.js';

const ENABLE_MODALITY = true;
const ENABLE_FACILITY = true;

const ContractFilters = ({ modal, contracts, onSaveFilter }) => {
  const inputRef = useRef(null);
  const divisions = useSelector(selectDivisionsList);
  const modalities = useSelector(selectModalities);
  const sources = useSelector(selectJobSourcesList);
  const users = useSelector(selectUsersList);
  const labelColor = useColorModeValue('gray.500', 'gray.400');

  const [division, setDivision] = useState(null);
  const [modality, setModality] = useState(null);
  const [source, setSource] = useState(null);
  const [facility, setFacility] = useState(null);
  const [salesRep, setSalesRep] = useState(null);
  const [facilityInput, setFacilityInput] = useState('');
  const [additionalFiltersCache, setAdditionalFiltersCache] = useSessionStorage('adv_contract_filters', null);
  const [additionalFilters, setAdditionalFilters] = useState({
    division: {
      label: 'Division',
      value: null,
    },
    facility: {
      label: 'Facility',
      value: null,
    },
    source: {
      label: 'Source',
      value: null,
    },
    modality: {
      label: 'Modality',
      value: null,
    },
    sales_rep: {
      label: 'Sales Rep',
      value: null,
    },
  });

  useEffect(() => {
    if (additionalFiltersCache) {
      setDivision(additionalFiltersCache.division?.value);
      setModality(additionalFiltersCache.modality?.value);
      setSource(additionalFiltersCache.source?.value);
      setFacility(additionalFiltersCache.facility?.value);
      setSalesRep(additionalFiltersCache.sales_rep?.value);
      setAdditionalFilters(additionalFiltersCache);
      filterData(additionalFiltersCache);
    }
  }, [contracts, additionalFiltersCache]);

  const specialties = useMemo(() => {
    let ret = [];
    if (isArrayValue(modalities)) {
      ret = modalities.filter((modal) => modal?.short_name);
      ret = ret.map((mod) => {
        return {
          ...mod,
          value: mod.id,
        };
      });
    }
    return ret;
  }, [modalities]);

  const filterData = (filters) => {
    const payload = {
      division: filters.division?.value?.value,
      source: filters.source?.value?.value,
      modality: filters.modality?.value?.value,
      facility: filters.facility?.value?.value,
      sales_rep: filters.sales_rep?.value?.value,
    };
    if (!payload.division && !payload.source && !payload.modality && !payload.facility && !payload.sales_rep) {
      onSaveFilter(null);
    } else {
      onSaveFilter(payload);
    }
  };

  const addFilter = () => {
    const newFilters = {
      ...additionalFilters,
      division: {
        label: 'Division',
        value: division,
      },
      facility: {
        label: 'Facility',
        value: facility,
      },
      source: {
        label: 'Source',
        value: source,
      },
      modality: {
        label: 'Modality',
        value: modality,
      },
      sales_rep: {
        label: 'Sales Rep',
        value: salesRep,
      },
    };
    filterData(newFilters);
    setAdditionalFilters(newFilters);
    setAdditionalFiltersCache(newFilters);
    modal.onClose();
  };

  const removeFilter = (e, filter) => {
    const label = filter.label.replace(' ', '_').toLowerCase();
    const updateFilterData = {
      ...additionalFilters,
      [label]: {
        label: filter.label,
        value: null,
      },
    };
    setAdditionalFilters(updateFilterData);
    setAdditionalFiltersCache(updateFilterData);
    filterData(updateFilterData);
    switch (label) {
      case 'division':
        setDivision(null);
        break;
      case 'facility':
        setFacility(null);
        setFacilityInput('');
        break;
      case 'modality':
        setModality(null);
        break;
      case 'source':
        setSource(null);
        break;
      case 'sales_rep':
        setSalesRep(null);
        break;
      default:
        break;
    }
  };

  const handleChangeDivision = (val, { action }) => {
    if (action === 'select-option') {
      setDivision(val);
    } else if (action === 'clear') {
      setDivision(null);
    }
  };

  const handleChangeModality = (val, { action }) => {
    if (action === 'select-option') {
      setModality(val);
    } else if (action === 'clear') {
      setModality(null);
    }
  };

  const handleChangeSource = (val, { action }) => {
    if (action === 'select-option') {
      setSource(val);
    } else if (action === 'clear') {
      setSource(null);
    }
  };

  const handleChangeSalesRep = (val, { action }) => {
    if (action === 'select-option') {
      setSalesRep(val);
    } else if (action === 'clear') {
      setSalesRep(null);
    }
  };

  const handleFacilityChange = (value, action) => {
    setFacility(value);
    setFacilityInput(value?.label);
  };

  const handleStateChange = (value, action, facility) => {
    if (action === 'select-option') {
      // clear the facility alert box
      if (facility?.label) {
        setFacility(null);
        setFacilityInput('');
      }
    } else if (action === 'clear') {
      setFacility(null);
      setFacilityInput('');
    }
  };

  const entries = useMemo(() => Object.entries(additionalFilters).filter(([key, value]) => value.value), [additionalFilters]);

  return (
    <React.Fragment>
      {entries.length > 0 && (
        <HStack spacing={4} mb={4}>
          {entries.map(([key, item]) => {
            return (
              <Tag key={key} borderRadius={4} colorScheme="cyan" variant="subtle">
                <TagLabel>{item.value?.label ?? item.value?.short_name}</TagLabel>
                <TagCloseButton onClick={(e) => removeFilter(e, item)} />
              </Tag>
            );
          })}
        </HStack>
      )}
      <ModalComponent
        size="lg"
        title="Add Filters"
        primaryText="Save"
        secondaryText="Cancel"
        handleConfirm={addFilter}
        onOpen={modal.onOpen}
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        isError={false}
      >
        <Box>
          <Text>Select from the list of filters below.</Text>
          <VStack spacing={4} position={'relative'} pt={2}>
            <FormControl isInvalid={false} aria-label="division-filter-control">
              <Grid templateColumns="120px auto">
                <GridItem pt={3}>
                  <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="division-select" mb={0} textAlign="end">
                    Division
                  </FormLabel>
                </GridItem>
                <GridItem>
                  <Select
                    aria-label="division-select"
                    chakraStyles={chakraStyles}
                    focusBorderColor="brand.700"
                    options={divisions}
                    onChange={handleChangeDivision}
                    isClearable
                    isSearchable
                    value={division}
                    placeholder="Select a division"
                  />
                </GridItem>
              </Grid>
            </FormControl>
            {ENABLE_MODALITY && (
              <FormControl isInvalid={false} aria-label={'modality-filter-control'}>
                <Grid templateColumns="120px auto">
                  <GridItem pt={3}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="modality-select" mb={0} textAlign="end">
                      Modality
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Select
                      chakraStyles={chakraStyles}
                      focusBorderColor="brand.700"
                      aria-label="modality-select"
                      options={specialties}
                      isSearchable
                      value={modality}
                      isClearable={true}
                      onChange={handleChangeModality}
                      getOptionLabel={(option) => option.short_name}
                      getOptionValue={(option) => option.id}
                      placeholder={'Select profession/specialty'}
                      components={{ SingleValue: SingleValueShortName, Option: OptionShortName }}
                    />
                  </GridItem>
                </Grid>
              </FormControl>
            )}
            <FormControl isInvalid={false} aria-label={'source-system-control'}>
              <Grid templateColumns="120px auto">
                <GridItem pt={3}>
                  <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="source-select" mb={0} textAlign="end">
                    Source
                  </FormLabel>
                </GridItem>
                <GridItem>
                  <Select
                    chakraStyles={chakraStyles}
                    focusBorderColor="brand.700"
                    aria-label="source-select"
                    options={sources}
                    isSearchable
                    value={source}
                    isClearable={true}
                    onChange={handleChangeSource}
                    placeholder={'Select contract source'}
                  />
                </GridItem>
              </Grid>
            </FormControl>
            <FormControl isInvalid={false} aria-label={'sales-rep-control'}>
              <Grid templateColumns="120px auto">
                <GridItem pt={3}>
                  <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="sales-rep-select" mb={0} textAlign="end">
                    Sales Rep
                  </FormLabel>
                </GridItem>
                <GridItem>
                  <Select
                    chakraStyles={chakraStyles}
                    focusBorderColor="brand.700"
                    aria-label="sales-rep-select"
                    options={users}
                    isSearchable
                    value={salesRep}
                    isClearable={true}
                    onChange={handleChangeSalesRep}
                    placeholder={'Select user'}
                    components={{ Option: OptionRole }}
                  />
                </GridItem>
              </Grid>
            </FormControl>
            {ENABLE_FACILITY && (
              <FormControl isInvalid={false} aria-label={'source-system-control'}>
                <Grid templateColumns="120px auto">
                  <GridItem pt={3}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="source" mb={0} textAlign="end">
                      Facility
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <FacilitySearch
                      initialRef={inputRef}
                      onFacilityChange={handleFacilityChange}
                      onStateChange={handleStateChange}
                      facilityPlaceholder="Search Facilities"
                      facility={facility}
                      input={facilityInput}
                      facilityMaxWidth={'560px'}
                      isReset={modal.isOpen}
                      width="100%"
                    />
                  </GridItem>
                </Grid>
              </FormControl>
            )}
          </VStack>
        </Box>
      </ModalComponent>
    </React.Fragment>
  );
};

ContractFilters.propTypes = {
  modal: PropTypes.object,
  contracts: PropTypes.array,
  onSaveFilter: PropTypes.func,
};
export default ContractFilters;
