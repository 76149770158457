import { Box, Button, ButtonGroup, Tooltip } from '@chakra-ui/react';
import TableButton from '@/features/common/table/TableButton';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import TableCell from '@/features/common/table/TableCell';

export const createMappingColumns = ({
  helper,
  currentMapping,
  isEditing,
  onSave,
  onCancel,
  onEdit,
  onRemove,
  handleMapToCell,
  handleActionsCell,
}) => {
  return [
    helper.accessor('source_system', {
      header: 'Source',
      disableSortBy: true,
    }),
    helper.accessor('map_from', {
      cell: ({ getValue, row, column, table }) =>
        currentMapping?.id === row?.original?.id && isEditing ? (
          <TableCell getValue={getValue} row={row} column={column} table={table} />
        ) : (
          getValue()
        ),
      header: 'Map From',
      disableSortBy: true,
      size: 200,
    }),
    helper.accessor('map_to', {
      cell: (info) => {
        return handleMapToCell(info);
      },
      header: 'Map To',
      disableSortBy: true,
    }),
    helper.accessor('', {
      cell: ({ row, column, table }) => {
        if (handleActionsCell(row)) {
          return (
            <ButtonGroup isAttached variant="outline" size={'sm'}>
              <Button aria-label="yes" onClick={() => onSave(row, column, table)}>
                Save
              </Button>
              <Button aria-label="no" onClick={() => onCancel(row, column, table)}>
                Cancel
              </Button>
            </ButtonGroup>
          );
        } else {
          return (
            <>
              <Tooltip hasArrow placement="top" label="Edit Facility Mapping">
                <Box p="0" as="span">
                  <TableButton aria-label="edit" icon={<BsPencilSquare />} onClick={(e) => onEdit(e, row, column, table)} />
                </Box>
              </Tooltip>
              <Tooltip hasArrow placement="top" label="Remove Facility Mapping">
                <Box p="0" as="span">
                  <TableButton aria-label="remove" icon={<BsTrash />} onClick={(e) => onRemove(e, row)} />
                </Box>
              </Tooltip>
            </>
          );
        }
      },
      header: 'Action',
      size: 172,
    }),
  ];
};
