import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Flex, useDisclosure, Spinner, Stack, HStack, FormControl, FormLabel, Tooltip, Box, Tag, TagLabel } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import LinkButton from '../common/LinkButton';
import { createColumnHelper } from '@tanstack/react-table';
import { BsPersonAdd, BsPersonDash, BsPencilSquare, BsTrash, BsArrowLeftRight } from 'react-icons/bs';
import {
  setSalesRepSearchStatus,
  selectSalesRepSearchStatus,
  setRepId,
  selectSalesRepSortBy,
  selectSalesRepSortDirection,
  setSalesRepSortBy,
  setSalesRepSortDirection,
  selectGetContractErrorMsg,
  selectGetContractStatus,
} from '../contract/contractSlice';
import TableButton from '../common/table/TableButton';
import AddSalesRep from './AddSalesRep';
import {
  useAddSalesRepMutation,
  useGetContractRepsQuery,
  useRemoveSalesRepMutation,
  useTransferSalesRepMutation,
  useUpdateSalesRepMutation,
} from '@/app/services/nucleus.js';
import EditSalesRep from './EditSalesRep';
import ModalComponent from '../common/ModalComponent';
import AlertComponent from '../common/AlertComponent';
import { SingleValueFirstLastName } from '../common/select/SingleValue';
import { OptionFirstLastNameDivision } from '../common/select/Option';
import { ClearIndicator } from '../common/select/ClearIndicator';
import { chakraStyles } from '../common/select/styles';
import { selectSalesReps, selectTargetSalesRep, setTargetSalesRep } from '../user/userSlice';
import { ERROR, LOADING, SALES } from '@/utils/constants';
import DataTableSearchAndFiltering from '@/features/common/table/DataTableSearchAndFiltering';
import { useParams } from 'react-router-dom';
import { useNotification } from '@/hooks/useNotification';
import { isArrayValue } from '@/utils/helpers';
import LoaderOverlay from '../common/LoaderOverlay';
import TruncatedArrayComponent from '@/features/common/TruncatedArrayComponent.jsx';

const SalesRepTab = ({ usersError }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();

  const addSalesRepModal = useDisclosure();
  const editSalesRepModal = useDisclosure();
  const removeSalesRepModal = useDisclosure();
  const transferSalesRepModal = useDisclosure();

  const salesRepStatus = useSelector(selectSalesRepSearchStatus);
  const allSalesReps = useSelector(selectSalesReps);
  const sortBy = useSelector(selectSalesRepSortBy);
  const sortDirection = useSelector(selectSalesRepSortDirection);
  const getContractStatus = useSelector(selectGetContractStatus);
  const contractError = useSelector(selectGetContractErrorMsg);
  const targetSalesRep = useSelector(selectTargetSalesRep);
  const [selectedSalesRep, setSelectedSalesRep] = useState({});

  const { data: contractSalesReps, isFetching: isSalesRepFetching } = useGetContractRepsQuery({ id });

  useEffect(() => {
    if (contractSalesReps) {
      console.log(JSON.stringify(contractSalesReps, null, 2));
    }
  }, [contractSalesReps]);

  const [
    addSalesRep,
    { isLoading: addSalesRepLoading, isSuccess: addSalesRepSuccess, isError: addSalesRepError, error: addSalesRepErrorMsg, reset: resetRepApiState },
  ] = useAddSalesRepMutation();

  const [
    updateSalesRep,
    {
      isLoading: updateSalesRepLoading,
      isSuccess: updateSalesRepSuccess,
      isError: updateSalesRepError,
      error: updateSalesRepErrorMsg,
      reset: resetUpdateRepApiState,
    },
  ] = useUpdateSalesRepMutation();

  const [
    removeSalesRep,
    {
      isLoading: removeSalesRepLoading,
      isSuccess: removeSalesRepSuccess,
      isError: removeSalesRepError,
      error: removeSalesRepErrorMsg,
      reset: resetRemoveRepApiState,
    },
  ] = useRemoveSalesRepMutation();

  const [
    transferSalesRep,
    {
      isLoading: transferSalesRepLoading,
      isSuccess: transferSalesRepSuccess,
      isError: transferSalesRepError,
      error: transferSalesRepErrorMsg,
      reset: resetTransferRep,
    },
  ] = useTransferSalesRepMutation();

  // INFO: rep.id is unique for each individual association.
  //  rep_id is tied to each individual rep and can be in multiple associations
  const selectedRepDivisions = useMemo(() => {
    if (selectedSalesRep?.id && isArrayValue(contractSalesReps)) {
      // get the matching instance of rep.id from the sales_rep_assoc
      const assoc = contractSalesReps.find((u) => u.id === selectedSalesRep?.id);
      return assoc?.divisions;
    } else {
      return [];
    }
  }, [selectedSalesRep, contractSalesReps]);

  // INFO: get a list of all reps attached to the contract in a different division than selectedSalesRep?.division
  const repPks = useMemo(() => {
    let reps = [];
    if (isArrayValue(contractSalesReps) && isArrayValue(selectedSalesRep?.divisions)) {
      const outsideDivision = contractSalesReps.filter((rep) => rep.divisions.some((r) => !selectedSalesRep.divisions.includes(r)));
      reps = outsideDivision.reduce((a, rep) => [...a, rep.id], []);
    }
    return [...new Set(reps)];
  }, [selectedSalesRep, contractSalesReps]);

  // INFO: supplement user data with extra fields from contract reps
  const allRepsWithDivision = useMemo(() => {
    if (isArrayValue(allSalesReps) && isArrayValue(contractSalesReps)) {
      let newReps = [...allSalesReps];
      // INFO: merge the contractSalesReps with allSalesReps
      allSalesReps.forEach((rep, i) => {
        const match = contractSalesReps.find((cRep) => cRep.rep_id === rep.id);
        const newMatch = {
          id: match?.rep_id,
          pk: match?.id,
          first_name: rep?.first_name,
          last_name: rep?.last_name,
          email: rep?.email,
          deleted_at: rep?.deleted_at,
          division: match?.division,
        };
        // figure out where to insert the newMatch
        const index = newReps.findIndex((m) => m.first_name === rep.first_name && m.last_name === rep.last_name);
        if (match) {
          newReps.splice(index, 1, newMatch);
        }
      });
      return newReps;
    } else {
      return [];
    }
  }, [allSalesReps, contractSalesReps]);

  useNotification(addSalesRepSuccess, addSalesRepModal.onClose, addSalesRepSuccess, 'success', 'Sales rep added');
  useNotification(updateSalesRepSuccess, editSalesRepModal.onClose, updateSalesRepSuccess, 'success', 'Sales rep updated');
  useNotification(transferSalesRepSuccess, transferSalesRepModal.onClose, transferSalesRepSuccess, 'success', 'Transferred sales rep assignments');
  useNotification(removeSalesRepSuccess, removeSalesRepModal.onClose, removeSalesRepSuccess, 'success', 'Sales rep removed');

  useEffect(() => {
    if (!transferSalesRepModal.isOpen) {
      dispatch(setTargetSalesRep(null));
      resetTransferRep();
    }
  }, [transferSalesRepModal.isOpen]);

  useEffect(() => {
    if (!editSalesRepModal.isOpen) {
      dispatch(setRepId(''));
    }
  }, [editSalesRepModal.isOpen]);

  useEffect(() => {
    if (!removeSalesRepModal.isOpen) {
      resetRemoveRepApiState();
    }
  }, [removeSalesRepModal.isOpen]);

  // sales rep helpers
  const salesRepColumns = [
    columnHelper.accessor('name', {
      cell: (info) => {
        const val = info.getValue();
        return info.row.original?.deleted_at ? (
          <React.Fragment>
            {val + ' '}
            <Tag borderRadius={4} variant="subtle">
              <TagLabel>deleted</TagLabel>
            </Tag>
          </React.Fragment>
        ) : info.row.original?.role !== SALES ? (
          <React.Fragment>
            {val + ' '}
            <Tag borderRadius={4} variant="subtle" bg={'yellow.100'}>
              <TagLabel>{info.row.original?.role || 'null'}</TagLabel>
            </Tag>
          </React.Fragment>
        ) : (
          val
        );
      },
      header: 'Name',
      size: 200,
    }),
    columnHelper.accessor('divisions', {
      cell: (info) => <TruncatedArrayComponent title="Divisions" fieldName="divisions" row={info.row.original} />,
      header: 'Division',
      size: 250,
    }),
    columnHelper.accessor('facility_names', {
      cell: (info) => <TruncatedArrayComponent title="Facilities" fieldName="facility_names" row={info.row.original} />,
      header: 'Facilities',
      size: 180,
    }),
    columnHelper.accessor('', {
      cell: (info) => {
        return (
          <React.Fragment>
            <Tooltip hasArrow placement="top" label="Edit Sales Rep">
              <Box p="0" as="span">
                <TableButton aria-label="edit" icon={<BsPencilSquare />} onClick={(e) => handleEditSalesRepActions(e, info)} />
              </Box>
            </Tooltip>
            <Tooltip hasArrow placement="top" label="Transfer Assignments">
              <Box p="0" as="span">
                <TableButton aria-label="transfer" icon={<BsArrowLeftRight />} onClick={(e) => handleTransferActions(e, info)} />
              </Box>
            </Tooltip>
            <Tooltip hasArrow placement="top" label="Remove Sales Rep">
              <Box p="0" as="span">
                <TableButton aria-label="remove" icon={<BsTrash />} onClick={(e) => handleRemoveSalesRepActions(e, info)} />
              </Box>
            </Tooltip>
          </React.Fragment>
        );
      },
      header: 'Action',
    }),
  ];

  const handleTransferFacilities = (e) => {
    e.preventDefault();
    transferSalesRep({ contractId: id, associationId: selectedSalesRep.id, repId: targetSalesRep.id })
      .unwrap()
      .then((payload) => console.log('successfully transfered rep assignments'))
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTransferActions = (e, info) => {
    e.preventDefault();
    setSelectedSalesRep(info.row.original);
    transferSalesRepModal.onOpen();
  };

  const handleEditSalesRepActions = (e, info) => {
    e.preventDefault();
    dispatch(setRepId(info.row.original.rep_id));
    setSelectedSalesRep(info.row.original);
    editSalesRepModal.onOpen();
  };

  const handleRemoveSalesRepActions = (e, info) => {
    e.preventDefault();
    setSelectedSalesRep(info.row.original);
    removeSalesRepModal.onOpen();
  };

  const handleSalesRepSearchStatus = (text) => {
    dispatch(setSalesRepSearchStatus(text));
  };

  const handleAddSalesRep = (e) => {
    addSalesRepModal.onOpen();
  };

  const handleUpdateSalesRep = (e, repId, repPk, facilities, divisions) => {
    e.preventDefault();
    const facilityIds = facilities.reduce((prev, cur) => [...prev, cur.id], []);
    updateSalesRep({ contractId: id, repId, repPk, facilityIds, divisions })
      .unwrap()
      .then((payload) => console.log('added sales rep'))
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSaveSalesRep = (e, repId, facilities, divisions) => {
    e.preventDefault();
    const facilityIds = facilities.reduce((prev, cur) => [...prev, cur.id], []);
    addSalesRep({ contractId: id, repId, facilityIds, divisions })
      .unwrap()
      .then((payload) => console.log('added sales rep'))
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemoveSalesRep = (e) => {
    e.preventDefault();
    removeSalesRep({ contractId: id, repId: selectedSalesRep.id })
      .unwrap()
      .then((payload) => console.log('removed sales rep'))
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStageRep = (value, { action }) => {
    if (action === 'select-option') {
      dispatch(setTargetSalesRep(value));
      if (transferSalesRepError) {
        resetTransferRep();
      }
    } else if (action === 'clear') {
      dispatch(setTargetSalesRep(null));
    }
  };

  const handleSalesRepSortBy = (column) => {
    dispatch(setSalesRepSortBy(column));
  };

  const handleSalesRepSortDirection = (direction) => {
    dispatch(setSalesRepSortDirection(direction));
  };

  const handleTableLoaded = (table) => {
    if (contractSalesReps) {
      contractSalesReps.map((user, index) => {
        let color = '';
        if (user?.deleted_at) {
          color = 'gray.50';
        } else if (user?.role !== SALES) {
          color = 'yellow.50';
        }
        table.options.meta?.addRowColor(index, color);
      });
    }
  };

  return (
    <React.Fragment>
      <LoaderOverlay loading={isSalesRepFetching} />
      <DataTableSearchAndFiltering
        title="Sales Reps"
        originalData={contractSalesReps}
        searchStatus={salesRepStatus}
        isSearchLoading={salesRepStatus === LOADING}
        isSearchError={salesRepStatus === ERROR}
        isDataLoading={getContractStatus === LOADING || isSalesRepFetching}
        isDataError={getContractStatus === ERROR || usersError}
        dataErrorMsg={contractError?.data?.detail}
        dataErrorTitle={`Error fetching contract ${id}`}
        dataColumns={salesRepColumns}
        searchColumns={['name', 'facility_names']}
        sortBy={sortBy}
        sortDirection={sortDirection}
        handleSortBy={handleSalesRepSortBy}
        handleSortDirection={handleSalesRepSortDirection}
        handleSearchStatus={handleSalesRepSearchStatus}
        handleTableLoaded={handleTableLoaded}
      >
        <LinkButton icon={<BsPersonAdd />} onClick={(e) => handleAddSalesRep(e)}>
          Add Sales Rep
        </LinkButton>
      </DataTableSearchAndFiltering>
      <EditSalesRep
        id={id}
        rep={selectedSalesRep}
        repDivisions={selectedRepDivisions}
        onOpen={editSalesRepModal.onOpen}
        onClose={editSalesRepModal.onClose}
        onUpdate={handleUpdateSalesRep}
        isOpen={editSalesRepModal.isOpen}
        isLoading={updateSalesRepLoading}
        isError={updateSalesRepError}
        errorMessage={updateSalesRepErrorMsg}
        reset={resetUpdateRepApiState}
      />
      <AddSalesRep
        id={id}
        onOpen={addSalesRepModal.onOpen}
        onClose={addSalesRepModal.onClose}
        isOpen={addSalesRepModal.isOpen}
        isLoading={addSalesRepLoading}
        isError={addSalesRepError}
        reset={resetRepApiState}
        errorMessage={addSalesRepErrorMsg}
        onSaveHandler={handleSaveSalesRep}
      />
      <ModalComponent
        title="Remove Sales Rep"
        size="md"
        primaryText="Yes"
        secondaryText="No"
        handleConfirm={handleRemoveSalesRep}
        onOpen={removeSalesRepModal.onOpen}
        isOpen={removeSalesRepModal.isOpen}
        onClose={removeSalesRepModal.onClose}
        isError={removeSalesRepError}
      >
        <Stack spacing={3}>
          {removeSalesRepLoading ? (
            <Flex alignItems="center" justifyContent="center">
              <Spinner color="brand.900" size="lg" />
            </Flex>
          ) : removeSalesRepError ? (
            <AlertComponent status="error" title="Error Removing Sales Rep" description={removeSalesRepErrorMsg?.data?.detail} />
          ) : (
            <React.Fragment>
              <Text>Are you sure you want to remove this sales rep?</Text>
              <AlertComponent
                icon={<BsPersonDash style={{ height: '24px', width: '24px' }} />}
                title={selectedSalesRep?.name}
                description={`${selectedSalesRep?.divisions?.join(', ')}`}
              />
            </React.Fragment>
          )}
        </Stack>
      </ModalComponent>
      <ModalComponent
        title="Transfer Sales Rep Assignment"
        size="md"
        primaryText="Transfer Assignments"
        secondaryText="Cancel"
        handleConfirm={handleTransferFacilities}
        onOpen={transferSalesRepModal.onOpen}
        isOpen={transferSalesRepModal.isOpen}
        onClose={transferSalesRepModal.onClose}
        isError={transferSalesRepError || targetSalesRep === null}
      >
        <Stack spacing={3}>
          {transferSalesRepLoading ? (
            <Flex alignItems="center" justifyContent="center">
              <Spinner color="brand.900" size="lg" />
            </Flex>
          ) : transferSalesRepError ? (
            <AlertComponent status="error" title="Error Transferring Sales Rep Assignment" description={transferSalesRepErrorMsg?.data?.detail} />
          ) : (
            <Stack>
              <HStack spacing={4} direction="row" justifyContent={'start'}>
                <FormLabel fontWeight={'bold'}>Original Sales Rep:</FormLabel>
                <Text mb={2}>{selectedSalesRep?.name}</Text>
              </HStack>
              <FormControl id="sales_rep">
                <Select
                  chakraStyles={chakraStyles}
                  focusBorderColor="brand.700"
                  options={allRepsWithDivision.filter((r) => r.id !== selectedSalesRep.rep_id && !r.deleted_at)}
                  aria-label={'transferTarget'}
                  isSearchable
                  isClearable
                  value={targetSalesRep}
                  onChange={handleStageRep}
                  components={{ SingleValue: SingleValueFirstLastName, Option: OptionFirstLastNameDivision, ClearIndicator }}
                  getOptionLabel={(option) => `${option.first_name} ${option.last_name} ${option.email} ${option.division}`}
                  getOptionValue={(option) => option.id}
                  isOptionDisabled={(option) => repPks.includes(option.pk)}
                  placeholder="Select a sales rep"
                />
              </FormControl>
            </Stack>
          )}
        </Stack>
      </ModalComponent>
    </React.Fragment>
  );
};

SalesRepTab.propTypes = {
  usersError: PropTypes.bool,
};

export default SalesRepTab;
