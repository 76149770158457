import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListIcon } from '@chakra-ui/react';

const ComplexityRules = ({ rulesData, icons, styleProps }) => {
  return (
    <List space={3} style={styleProps}>
      {rulesData?.map((rule, index) => (
        <ListItem key={index} color={rule.value ? 'green.500' : 'red.500'} fontSize={'sm'}>
          <ListIcon
            aria-label={rule.value ? icons.validAriaLabel : icons.invalidAriaLabel}
            as={rule.value ? icons.validIcon : icons.invalidIcon}
            color={rule.value ? 'green.500' : 'red.500'}
          />
          {rule.label}
        </ListItem>
      ))}
    </List>
  );
};
ComplexityRules.propTypes = {
  rulesData: PropTypes.array,
  icons: PropTypes.object,
  styleProps: PropTypes.object,
};

export default ComplexityRules;
