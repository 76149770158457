import { chakra, shouldForwardProp } from '@chakra-ui/react';
import { motion, isValidMotionProp } from 'framer-motion';

const AnimatedCell = chakra(motion.td, {
  baseStyle: {
    textAlign: 'start',
    backgroundColor: 'orange.50',
    paddingInlineStart: '1.5rem',
    paddingInlineEnd: '1.5rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    lineHeight: '1.25rem',
    borderBottom: '1px',
    borderColor: 'gray.200',
  },
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export default AnimatedCell;
