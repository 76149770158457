import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Flex, useDisclosure, Spinner, Stack, Tooltip, Box } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { BsTrash, BsPlus, BsPencilSquare, BsPostcard } from 'react-icons/bs';
import { LiaUsersCogSolid } from 'react-icons/lia';
import {
  selectContractRateCards,
  setRateCardSearchStatus,
  selectRateCardSearchStatus,
  selectContractSearchStatus,
  selectContractRateCardData,
  setRateCardId,
  selectContractId,
  selectContractDivisions,
  selectRateCardSortBy,
  selectRateCardSortDirection,
  setRateCardSortBy,
  setRateCardSortDirection,
  selectGetContractErrorMsg,
  selectGetContractStatus,
} from '../contract/contractSlice';
import TableButton from '../common/table/TableButton';
import { ERROR, LOADING } from '@/utils/constants';
import { assignRateCardShortNames } from '../contract/contractActions';
import LinkButton from '../common/LinkButton';
import RateCardModal from './RateCardModal';
import { useAddRateMutation, useEditRateMutation, useRemoveRateMutation } from '@/app/services/nucleus';
import { setNotificationLogMessage } from '../notification/notificationSlice';
import EditModalities from './EditModalities';
import { selectModalities } from '../modality/modalitySlice';
import ModalComponent from '../common/ModalComponent';
import AlertComponent from '../common/AlertComponent';
import { useParams } from 'react-router-dom';
import { isArrayValue } from '@/utils/helpers';
import DataTableSearchAndFiltering from '@/features/common/table/DataTableSearchAndFiltering';
import { searchColumns } from '@/features/contract/contractHelper';
import { useNotification } from '@/hooks/useNotification';
import TruncatedArrayComponent from '@/features/common/TruncatedArrayComponent.jsx';

const RateCardTab = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [editRateCard, setEditRateCard] = useState(false);
  const [rateCard, setRateCard] = useState({});
  const columnHelper = createColumnHelper();
  const rateCardModalProps = useDisclosure();
  const removeRateCardModal = useDisclosure();
  const modalitiesModalProps = useDisclosure();
  const sortBy = useSelector(selectRateCardSortBy);
  const sortDirection = useSelector(selectRateCardSortDirection);
  const contractId = useSelector(selectContractId);
  const rateCardStatus = useSelector(selectRateCardSearchStatus);
  const searchStatus = useSelector(selectContractSearchStatus);
  const contractRateCardData = useSelector(selectContractRateCardData);
  const contractRateCards = useSelector(selectContractRateCards);
  const divisions = useSelector(selectContractDivisions);
  const modalities = useSelector(selectModalities);
  const getContractStatus = useSelector(selectGetContractStatus);
  const contractError = useSelector(selectGetContractErrorMsg);

  const specialties = useMemo(() => {
    let ret = [];
    if (isArrayValue(modalities) && isArrayValue(divisions)) {
      ret = modalities.filter((modal) => modal?.short_name && divisions.includes(modal.division));
    }
    return ret;
  }, [modalities, divisions]);

  const [addRate, { isLoading: addRateLoading, isSuccess: addRateSuccess, isError: addRateError, error: addRateErrorMsg, reset: addRateReset }] =
    useAddRateMutation();

  const [
    editRate,
    { isLoading: editRateLoading, isSuccess: editRateSuccess, isError: editRateError, error: editRateErrorMsg, reset: editRateReset },
  ] = useEditRateMutation();

  const [
    removeRate,
    { isLoading: removeRateLoading, isSuccess: removeRateSuccess, isError: removeRateError, error: removeRateErrorMsg, reset: removeRateReset },
  ] = useRemoveRateMutation();

  // get a list of modalitiy ids already assigned to rate cards on the contract
  const disabledSpecialties = useMemo(() => {
    let ids = [];
    if (isArrayValue(contractRateCards)) {
      for (const card of contractRateCards) {
        if (isArrayValue(card?.modality_ids)) {
          card?.modality_ids.forEach((i) => {
            ids.push(i);
          });
        }
      }
    }
    return ids;
  }, [contractRateCards]);

  useEffect(() => {
    if (isArrayValue(contractRateCardData) && modalities) {
      dispatch(assignRateCardShortNames(contractRateCardData));
    }
  }, [contractRateCardData, modalities]);

  useEffect(() => {
    if (removeRateCardModal.isOpen === false) {
      setRateCard({});
      dispatch(setRateCardId(null));
    }
  }, [removeRateCardModal.isOpen]);

  useEffect(() => {
    if (rateCardModalProps.isOpen === false) {
      setRateCard({});
      dispatch(setRateCardId(null));
    }
  }, [rateCardModalProps.isOpen]);

  useEffect(() => {
    let msg = '';
    msg = addRateSuccess ? 'added' : msg;
    msg = editRateSuccess ? 'saved' : msg;
    if (addRateSuccess || editRateSuccess) {
      rateCardModalProps.onClose();
      dispatch(
        setNotificationLogMessage({
          status: 'success',
          msg: `Rate card ${msg}`,
        }),
      );
    }
  }, [addRateSuccess, editRateSuccess]);

  useNotification(removeRateSuccess, removeRateCardModal.onClose, removeRateSuccess, 'success', 'Rate card removed');

  const rateCardColumns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: 'Rate Card Summary',
    }),
    columnHelper.accessor('shortNames', {
      cell: (info) => <TruncatedArrayComponent title="Profession/Specialty" fieldName="short_names" row={info.row.original} />,
      header: 'Profession/Specialty',
    }),
    columnHelper.accessor('', {
      cell: (info) => {
        return (
          <React.Fragment>
            <Tooltip hasArrow placement="top" label="Edit Rate Card">
              <Box p="0" as="span">
                <TableButton aria-label="edit" icon={<BsPencilSquare />} onClick={(e) => handleEditRateCardAction(e, info)} />
              </Box>
            </Tooltip>
            <Tooltip hasArrow placement="top" label="Edit Profession/Specialty">
              <Box p="0" as="span">
                <TableButton aria-label="specialty" icon={<LiaUsersCogSolid />} onClick={(e) => handleProfessionSpecialtyAction(e, info)} />
              </Box>
            </Tooltip>
            <Tooltip hasArrow placement="top" label="Remove Rate Card">
              <Box p="0" as="span">
                <TableButton aria-label="remove" icon={<BsTrash />} onClick={(e) => handleRemoveRateCardAction(e, info)} />
              </Box>
            </Tooltip>
          </React.Fragment>
        );
      },
      header: 'Actions',
      size: 190,
    }),
  ];

  const handleProfessionSpecialtyAction = (e, info) => {
    e.preventDefault();
    modalitiesModalProps.onOpen();
    setRateCard(info.row.original);
    dispatch(setRateCardId(info.row.original.id));
  };

  const handleEditRateCardAction = (e, info) => {
    setEditRateCard(true);
    setRateCard(info.row.original);
    dispatch(setRateCardId(info.row.original.id));
    rateCardModalProps.onOpen();
    e.preventDefault();
  };

  const handleRemoveRateCardAction = (e, info) => {
    e.preventDefault();
    setRateCard(info.row.original);
    removeRateCardModal.onOpen();
  };

  const handleRateCardSearchStatus = (text) => {
    dispatch(setRateCardSearchStatus(text));
  };

  const handleAddRateCard = (e) => {
    setEditRateCard(false);
    rateCardModalProps.onOpen();
    e.preventDefault();
  };

  const handleSaveRateCard = (e, data) => {
    e.preventDefault();
    let payload = {};
    payload.contractId = contractId;
    payload.name = data.name;
    for (const item of data.code) {
      const op = item.field + '_operator';
      payload[item.field] = item.value;
      payload[op] = item.operator;
    }
    payload.modalityIds = data.specialty.reduce((prev, cur) => [...prev, cur.id], []);
    if (editRateCard && rateCard?.id) {
      payload.rateCardId = rateCard.id;
      editRate(payload)
        .unwrap()
        .catch((error) => {
          console.log(error);
        });
    } else {
      addRate(payload)
        .unwrap()
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleRemoveRateCard = (e) => {
    e.preventDefault();
    removeRate({ contractId, rateCardId: rateCard?.id })
      .unwrap()
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRateCardSortBy = (column) => {
    dispatch(setRateCardSortBy(column));
  };

  const handleRateCardSortDirection = (direction) => {
    dispatch(setRateCardSortDirection(direction));
  };

  return (
    <React.Fragment>
      <DataTableSearchAndFiltering
        title="Rate Cards"
        originalData={contractRateCards}
        searchStatus={rateCardStatus}
        isSearchLoading={rateCardStatus === LOADING}
        isSearchError={rateCardStatus === ERROR || searchStatus === ERROR}
        isDataLoading={getContractStatus === LOADING}
        isDataError={getContractStatus === ERROR}
        dataErrorMsg={contractError?.data?.detail}
        dataErrorTitle={`Error fetching contract ${id}`}
        dataColumns={rateCardColumns}
        searchColumns={searchColumns}
        sortBy={sortBy}
        sortDirection={sortDirection}
        handleSortBy={handleRateCardSortBy}
        handleSortDirection={handleRateCardSortDirection}
        handleSearchStatus={handleRateCardSearchStatus}
      >
        <LinkButton icon={<BsPlus />} onClick={(e) => handleAddRateCard(e)}>
          Add Rate Card
        </LinkButton>
      </DataTableSearchAndFiltering>
      <RateCardModal
        specialties={specialties}
        onOpen={rateCardModalProps.onOpen}
        onClose={rateCardModalProps.onClose}
        isOpen={rateCardModalProps.isOpen}
        isLoading={editRateCard ? editRateLoading : addRateLoading}
        isError={editRateCard ? editRateError : addRateError}
        isEdit={editRateCard}
        rateCard={rateCard}
        errorMessage={editRateCard ? editRateErrorMsg : addRateErrorMsg}
        disabledSpecialties={disabledSpecialties}
        reset={editRateCard ? editRateReset : addRateReset}
        saveRateCardHandler={handleSaveRateCard}
      />
      <EditModalities
        specialties={specialties}
        contractId={contractId}
        isOpen={modalitiesModalProps.isOpen}
        onClose={modalitiesModalProps.onClose}
        disabledSpecialties={disabledSpecialties}
      />
      <ModalComponent
        title="Remove Rate Card"
        primaryText="Yes"
        secondaryText="No"
        handleConfirm={handleRemoveRateCard}
        onOpen={removeRateCardModal.onOpen}
        isOpen={removeRateCardModal.isOpen}
        onClose={removeRateCardModal.onClose}
        isError={removeRateError}
      >
        <Stack spacing={3}>
          {removeRateLoading ? (
            <Flex alignItems="center" justifyContent="center">
              <Spinner color="brand.900" size="lg" />
            </Flex>
          ) : removeRateError ? (
            <AlertComponent status="error" title="Error removing rate card" description={removeRateErrorMsg?.data?.detail} />
          ) : (
            <React.Fragment>
              <Text>Are you sure you want to remove this rate card?</Text>
              <AlertComponent
                icon={<BsPostcard style={{ height: '24px', width: '24px' }} />}
                title={rateCard?.name}
                description={`${rateCard?.shortNames || ' '}`}
              />
            </React.Fragment>
          )}
        </Stack>
      </ModalComponent>
    </React.Fragment>
  );
};

export default RateCardTab;
