import { BsX } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { chakra } from '@chakra-ui/react';

export const ClearIndicator = (props) => {
  const { innerProps } = props;
  const handleClearValue = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.clearValue();
  };
  return (
    <chakra.div
      {...innerProps}
      aria-hidden={false}
      aria-label={'clear-icon'}
      role="button"
      cursor={'pointer'}
      padding={'4px'}
      mr={'4px'}
      _hover={{
        bg: '#F0F0F0',
        color: 'gray.500',
        borderRadius: '6px',
      }}
      onClick={handleClearValue}
    >
      <BsX size="26px" />
    </chakra.div>
  );
};
ClearIndicator.propTypes = {
  getStyles: PropTypes.func,
  innerProps: PropTypes.object,
};
