import React from 'react';
import { isArrayValue, isRealValue } from '@/utils/helpers';
import { Tag } from '@chakra-ui/react';

export const outputRoles = (roles) => {
  const items = isArrayValue(roles) ? [...roles] : [];
  items.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  return items.length > 0
    ? items.map((role) => {
        return React.createElement(
          Tag,
          {
            size: 'sm',
            key: role,
            colorScheme: 'yellow',
            m: 1,
          },
          role,
        );
      })
    : null;
};

export const parseErrorLocation = (errorMessage, formFields) => {
  const details = errorMessage?.data?.detail || [];
  let errorFields = { ...formFields };
  for (const err of details) {
    const field = err?.loc[1];
    Object.keys(formFields).forEach((key) => {
      if (key === field) {
        errorFields[key].error = err?.msg.charAt(0).toUpperCase() + err?.msg.slice(1);
        errorFields[key].valid = false;
      }
    });
  }
  return errorFields;
};

export const passwordRules = (password) => [
  { label: 'Password must be at least 8 characters long', value: password && password.length >= 8 },
  { label: 'Password has a special character', value: /[^a-zA-Z0-9]/.test(password) },
  { label: 'Password has a number', value: /\d/.test(password) },
  { label: 'Password has a capital letter', value: /[A-Z]/.test(password) },
];
