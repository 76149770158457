import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { BsThreeDotsVertical, BsSave, BsUpload } from 'react-icons/bs';
import { FaRegCopy } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Stack,
  StackDivider,
  useColorModeValue,
  VStack,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useDisclosure,
  Spinner,
  Flex,
  FormControl,
  Alert,
  AlertIcon,
  Badge,
} from '@chakra-ui/react';
import { ARCHIVED, DETAILS, DRAFT, FACILITIES, PUBLISHED, RATE_CARDS, SALES_REP_ASSIGNMENT, tabsHelper } from '@/utils/constants';
import {
  selectContractStatus,
  selectRateCardSortBy,
  selectRateCardSortDirection,
  selectSalesRepSortBy,
  selectSalesRepSortDirection,
  setContractId,
  setContractStatus,
  setGetContractStatus,
} from './contractSlice';
import {
  useGetContractQuery,
  useCopyContractMutation,
  useRemoveContractMutation,
  useUpdateStatusMutation,
  useGetContractRepsQuery,
} from '@/app/services/nucleus';
import AlertComponent from '../common/AlertComponent';
import FacilityTab from '../facility/FacilityTab';
import RateCardTab from '../rateCard/RateCardTab';
import SalesRepTab from '../salesRep/SalesRepTab';
import { selectUsersErrorMessage } from '../user/userSlice';
import { assignContractData } from '../contract/contractActions';
import ModalComponent from '../common/ModalComponent';
import ContractEditForm from './ContractEditForm';
import DisplayArchivePublishContent from './DisplayArchivePublishContent';
import { selectFacilitySortBy, selectFacilitySortDirection } from '../facility/facilitySlice';
import { handleStatusColor } from '@/features/contract/contractHelper';
import { useNotification } from '@/hooks/useNotification';
import ContractSearch from './ContractSearch';

const ContractsDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, active_tab } = useParams();
  const urlChange = useLocation();
  const copyContractModal = useDisclosure();
  const updateStatusModal = useDisclosure();
  const userErrorMsg = useSelector(selectUsersErrorMessage);
  const status = useSelector(selectContractStatus);
  const facilitySortBy = useSelector(selectFacilitySortBy);
  const facilitySortDir = useSelector(selectFacilitySortDirection);
  const rateCardSortBy = useSelector(selectRateCardSortBy);
  const rateCardSortDir = useSelector(selectRateCardSortDirection);
  const salesRepSortBy = useSelector(selectSalesRepSortBy);
  const salesRepSortDir = useSelector(selectSalesRepSortDirection);

  const [contractSource, setContractSource] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [isArchive, setIsArchive] = useState(false);
  const [statusColor, setStatusColor] = useState('gray');

  // NOTE: we need to know which status the user is TRYING to set (i.e. the request to update status can fail)
  const [tempStatus, setTempStatus] = useState('');

  const {
    data: contract,
    isSuccess: isContractSuccess,
    isLoading: isContractLoading,
    isError: isContractError,
    error: contractError,
  } = useGetContractQuery({ id });

  const [copy, { isLoading: copyLoading, isSuccess: copySuccess, isError: copyError, error: copyErrorMsg }] = useCopyContractMutation();

  const [remove, { isLoading: removeLoading, isSuccess: removeSuccess, isError: removeError, error: removeErrorMsg, reset: removeContractReset }] =
    useRemoveContractMutation();

  const [
    updateStatus,
    { isLoading: statusLoading, isSuccess: statusSuccess, isError: statusError, error: statusErrorMsg, reset: updateStatusReset },
  ] = useUpdateStatusMutation();

  const { isError: isGetRepsError, error: getRepError } = useGetContractRepsQuery({ id });

  useNotification(copySuccess, copyContractModal.onClose, copySuccess, 'success', 'Contract copied successfully');
  useNotification(statusSuccess, updateStatusModal.onClose, statusSuccess, 'success', 'Contract status updated successfully');
  useNotification(statusError, updateStatusModal.onClose, tempStatus === DRAFT, 'error', 'Error saving Draft');
  useNotification(removeSuccess, updateStatusModal.onClose, removeSuccess, 'success', 'Contract archived successfully');

  // NOTE: after individual contract is loaded we parse out facilities, reps, rateCards, and client
  useEffect(() => {
    if (id !== '' && isContractSuccess) {
      dispatch(setContractId(id));
      dispatch(assignContractData(contract));
    }
  }, [id, isContractSuccess, contract]);

  const tabNavHelper = (active_tab) => {
    switch (active_tab) {
      case DETAILS:
        return { tab: 0, sortBy: null, sortDir: null };
      case FACILITIES:
        return { tab: 1, sortBy: facilitySortBy, sortDir: facilitySortDir };
      case RATE_CARDS:
        return { tab: 2, sortBy: rateCardSortBy, sortDir: rateCardSortDir };
      case SALES_REP_ASSIGNMENT:
        return { tab: 3, sortBy: salesRepSortBy, sortDir: salesRepSortDir };
      default:
        return { tab: 0, sortBy: 'name', sortDir: 'asc' };
    }
  };

  useEffect(() => {
    if (active_tab) {
      const { tab } = tabNavHelper(active_tab);
      handleTabsChange(tab);
    } else {
      navigate(`/contracts/${id}/${DETAILS}`, { state: { from: urlChange } });
    }
  }, [active_tab]);

  useEffect(() => {
    if (copyContractModal.isOpen === false) {
      setContractSource(null);
    }
  }, [copyContractModal.isOpen]);

  const borderColor = useColorModeValue('gray.300', 'gray.600');

  const handleCopyContract = (e) => {
    e.preventDefault();
    copy({ contractId: contract?.id, copyContractId: contractSource?.id })
      .unwrap()
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCopyDialog = (e) => {
    e.preventDefault();
    copyContractModal.onOpen();
  };

  const handleStagePublish = (e) => {
    setIsArchive(false);
    e.preventDefault();
    updateStatusModal.onOpen();
    updateStatusReset();
  };

  const handleStageArchive = (e) => {
    setIsArchive(true);
    e.preventDefault();
    updateStatusModal.onOpen();
    updateStatusReset();
  };

  const handleUpdateStatusClose = () => {
    updateStatusModal.onClose();
    updateStatusReset();
    removeContractReset();
  };

  const handlePublishContract = (e) => {
    e.preventDefault();
    if (isArchive) {
      handleArchiveContract();
    } else {
      handleContractStatus(e, PUBLISHED);
    }
  };

  const handleArchiveContract = () => {
    setTempStatus(ARCHIVED);
    remove({ contractId: contract?.id })
      .unwrap()
      .then((payload) => dispatch(setContractStatus(ARCHIVED)))
      .catch((error) => {
        console.log(error);
      });
  };

  const handleContractStatus = (e, statusText) => {
    e.preventDefault();
    setTempStatus(statusText);
    updateStatus({ contractId: contract?.id, status: statusText })
      .unwrap()
      .then((payload) => dispatch(setContractStatus(statusText)))
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSetContractSource = (contract, action) => {
    setContractSource(contract);
  };

  useEffect(() => {
    if (status) {
      setStatusColor(handleStatusColor(status));
    }
  }, [status]);

  const handleTabsChange = (index) => {
    const tab = tabsHelper(index);
    const { sortBy, sortDir } = tabNavHelper(tab);
    let query = `/contracts/${id}/${tab}`;
    if (tab !== DETAILS) {
      query += `?sortBy=${sortBy}&sortDirection=${sortDir}`;
    }
    dispatch(setGetContractStatus(''));
    navigate(query, { state: { from: urlChange } });
    setTabIndex(index);
  };

  return (
    <Box pos="relative">
      {isContractError || isGetRepsError ? (
        <AlertComponent
          mb={6}
          status="error"
          title="Error Fetching Contract"
          description={isContractError ? contractError?.data?.detail : getRepError?.data?.detail}
        />
      ) : (
        <React.Fragment>
          <HStack justifyContent="space-between">
            <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
              {contract?.name} <Badge colorScheme={statusColor}>{status}</Badge>
            </Heading>
            <HStack>
              <Button
                isDisabled={status === PUBLISHED || status === ARCHIVED}
                onClick={(e) => handleContractStatus(e, DRAFT)}
                variant="outline"
                leftIcon={<BsSave />}
              >
                Mark as Draft
              </Button>
              <Button isDisabled={status === ARCHIVED} onClick={(e) => handleStagePublish(e)} variant="purple" leftIcon={<BsUpload />}>
                {status === PUBLISHED ? 'Republish' : 'Publish'}
              </Button>
              <Menu>
                <MenuButton
                  bgColor={'white'}
                  aria-label="contract configuration"
                  px={3}
                  py={3}
                  transition="all 0.2s"
                  borderRadius="md"
                  borderWidth="1px"
                  _hover={{ bg: 'gray.200' }}
                  _expanded={{ bg: 'gray.300' }}
                >
                  <BsThreeDotsVertical />
                </MenuButton>
                <MenuList>
                  <MenuItem isDisabled={status === ARCHIVED} onClick={handleCopyDialog}>
                    Copy Contract
                  </MenuItem>
                  <MenuItem isDisabled={status === ARCHIVED} onClick={handleStageArchive}>
                    Archive
                  </MenuItem>
                </MenuList>
              </Menu>
              <ModalComponent
                icon={<FaRegCopy />}
                size="md"
                title={`${isArchive ? 'Archive' : 'Publish'} Contract`}
                primaryText={isArchive ? 'Archive' : 'Publish'}
                secondaryText="Cancel"
                handleConfirm={handlePublishContract}
                onOpen={updateStatusModal.onOpen}
                isOpen={updateStatusModal.isOpen}
                onClose={handleUpdateStatusClose}
                isError={statusError || removeError}
              >
                <Stack spacing={3}>
                  {statusLoading || removeLoading ? (
                    <Flex alignItems="center" justifyContent="center">
                      <Spinner color="brand.900" size="lg" />
                    </Flex>
                  ) : (
                    <DisplayArchivePublishContent
                      error={statusError || removeError}
                      errorMsg={statusErrorMsg?.data?.detail || removeErrorMsg?.data?.detail}
                      isArchive={isArchive}
                    />
                  )}
                </Stack>
              </ModalComponent>
              <ModalComponent
                icon={<FaRegCopy />}
                size="md"
                title="Copy Contract Configuration"
                primaryText="Copy Contract Configuration"
                secondaryText="Cancel"
                handleConfirm={handleCopyContract}
                onOpen={copyContractModal.onOpen}
                isOpen={copyContractModal.isOpen}
                onClose={copyContractModal.onClose}
                isError={copyError || contractSource === null}
              >
                <Stack spacing={3}>
                  {copyLoading || isContractLoading ? (
                    <Flex alignItems="center" justifyContent="center" flexDirection={'column'}>
                      <Spinner color="brand.900" size="lg" />
                      {isContractLoading && (
                        <Box>
                          <Text fontSize="sm" color="purple.600" mt={2}>
                            Loading contracts
                          </Text>
                        </Box>
                      )}
                    </Flex>
                  ) : copyError || isContractError ? (
                    <AlertComponent status="error" title="Error" description={copyError ? copyErrorMsg?.data?.detail : contractError?.data?.detail} />
                  ) : (
                    <React.Fragment>
                      <FormControl id="copy">
                        <ContractSearch isReset={copyContractModal.isOpen} onContractChange={handleSetContractSource} width="100%" />
                      </FormControl>
                      <Alert status="warning" borderRadius={6}>
                        <AlertIcon />
                        Warning: Existing Facilities, Rate Cards and Sales Rep mapping will be replaced with the mappings on the selected contract.
                      </Alert>
                    </React.Fragment>
                  )}
                </Stack>
              </ModalComponent>
            </HStack>
          </HStack>
          <Tabs index={tabIndex} mt={6} colorScheme="cyan" isFitted onChange={handleTabsChange}>
            <TabList>
              <Tab>Contract Details</Tab>
              <Tab>Facilities</Tab>
              <Tab>Rate Cards</Tab>
              <Tab>Sales Rep Assignment</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <VStack spacing={8} align="start" divider={<StackDivider borderColor={borderColor} />}>
                  <Stack spacing={5} width={'100%'} pos={'relative'}>
                    <Box as="header">
                      <Heading lineHeight={1} fontWeight={400} as="h5" size="md" py={3}>
                        Contract Overview
                      </Heading>
                    </Box>
                    {!isContractLoading && <ContractEditForm />}
                  </Stack>
                </VStack>
              </TabPanel>
              <TabPanel>{active_tab === FACILITIES && <FacilityTab />}</TabPanel>
              <TabPanel>{active_tab === RATE_CARDS && <RateCardTab />}</TabPanel>
              <TabPanel>{active_tab === SALES_REP_ASSIGNMENT && <SalesRepTab usersError={Boolean(userErrorMsg !== '')} />}</TabPanel>
            </TabPanels>
          </Tabs>
        </React.Fragment>
      )}
    </Box>
  );
};

export default ContractsDetail;
