import React, { useMemo, useState } from 'react';
import { DndContext, KeyboardSensor, MeasuringStrategy, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { DragHandle, SortableItem } from './SortableItem';
import SortableOverlay from './SortableOverlay';
import { VStack } from '@chakra-ui/react';
import { Virtuoso } from 'react-virtuoso';

export const SortableList = ({ items, onChange, renderItem }) => {
  const [active, setActive] = useState(null);

  const activeItem = useMemo(() => {
    if (items) {
      return items.find((item) => item.id === active?.id);
    }
  }, [active, items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const measuringConfig = {
    droppable: {
      strategy: MeasuringStrategy.Always,
    },
  };

  return (
    <DndContext
      sensors={sensors}
      measuring={measuringConfig}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);
          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={items || []}>
        <VStack
          aria-label="Sortable list"
          spacing={0}
          p={0}
          marginLeft={'-25px'}
          marginRight={'-25px'}
          role="application"
          borderTop={items && items.length ? '1px' : '0px'}
          borderColor="gray.200"
        >
          <Virtuoso
            style={{ height: '100vh', width: '100%' }}
            data={items}
            totalCount={items ? items.length : 0}
            itemContent={(index, item) => {
              const isFirst = index === 0;
              const isLast = items ? index === items.length - 1 : false;
              return <React.Fragment key={item.id}>{renderItem(item, isFirst, isLast)}</React.Fragment>;
            }}
          />
        </VStack>
      </SortableContext>
      <SortableOverlay>{activeItem ? renderItem(activeItem) : null}</SortableOverlay>
    </DndContext>
  );
};

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
