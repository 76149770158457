import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { FiLayout } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { chakraStyles } from '../common/select/styles';
import { selectJobSourcesList } from '@/features/enum/enumSlice';
import AlertComponent from '@/features/common/AlertComponent';
import FacilitySearch from '@/features/facility/FacilitySearch';
import LoaderOverlay from '@/features/common/LoaderOverlay';
import { SingleValueShortName } from '@/features/common/select/SingleValue';
import { OptionShortName } from '@/features/common/select/Option';
import { selectModalities } from '@/features/modality/modalitySlice';
import { isArrayValue } from '@/utils/helpers';

const AddMappingModal = ({ type, isOpen, onClose, isLoading, isError, errorMsg, handleSubmit }) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const inputRef = useRef(null);
  const labelColor = useColorModeValue('gray.500', 'gray.400');
  const jobSources = useSelector(selectJobSourcesList);
  const modalities = useSelector(selectModalities);

  const [mapFrom, setMapFrom] = useState('');
  const [source, setSource] = useState(null);
  const [mapToId, setMapToId] = useState(null);
  const [mapping, setMapping] = useState(null);
  const [facilityInput, setFacilityInput] = useState('');
  const typeLower = type.toLowerCase();

  const specialties = useMemo(() => {
    let ret = [];
    if (isArrayValue(modalities)) {
      ret = modalities.filter((modal) => modal?.short_name);
    }
    return ret;
  }, [modalities]);

  useEffect(() => {
    if (!isOpen) {
      setMapFrom('');
      setSource(null);
      setMapping(null);
      setFacilityInput('');
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleMapFromChange = (e) => {
    setMapFrom(e.target.value);
  };

  const handleJobSourceChange = (selectOptions) => {
    setSource(selectOptions || []);
  };

  const handleMappingChange = (value, action) => {
    setMapping(value);
    setMapToId(value?.id ?? value?.value);
    setFacilityInput(value?.label);
  };

  const handleStateChange = (value, action, mapping) => {
    if (action === 'select-option') {
      // clear the mapping alert box
      if (mapping?.label) {
        setMapping(null);
        setFacilityInput('');
      }
    } else if (action === 'clear') {
      setMapping(null);
      setFacilityInput('');
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    handleSubmit({ map_from: mapFrom, map_to_id: mapToId, source_system: source?.label });
  };

  return (
    <Modal
      title={`Add ${type} Mapping`}
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size="3xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <form id={`add_${typeLower}_mapping`} onSubmit={submitHandler}>
          <ModalHeader>
            <HStack>
              <FiLayout />
              <Text>{`Add ${type} Mapping`}</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4} position={'relative'}>
              <LoaderOverlay loading={isLoading} />
              <FormControl isInvalid={false} aria-label={'map-from-control'}>
                <Grid templateColumns="120px auto">
                  <GridItem pt={3}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="name" mb={0} textAlign="end">
                      Map From
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Input
                      id="name"
                      focusBorderColor={'brand.700'}
                      type="text"
                      isRequired={false}
                      isDisabled={false}
                      value={mapFrom}
                      onChange={handleMapFromChange}
                    />
                  </GridItem>
                </Grid>
              </FormControl>
              <FormControl isInvalid={false} aria-label={'source-system-control'}>
                <Grid templateColumns="120px auto">
                  <GridItem pt={3}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="source" mb={0} textAlign="end">
                      Source System
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Select
                      id="source"
                      aria-label={`${typeLower}-mapping-source`}
                      focusBorderColor="brand.700"
                      chakraStyles={chakraStyles}
                      options={jobSources}
                      onChange={handleJobSourceChange}
                      value={source}
                      colorScheme="blue"
                      placeholder="Select a source system"
                    />
                  </GridItem>
                </Grid>
              </FormControl>
              <FormControl isInvalid={false} aria-label={'map-to-control'}>
                <Grid templateColumns="120px auto">
                  <GridItem pt={3}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="roles" mb={0} textAlign="end">
                      Map To
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    {type === 'Facility' ? (
                      <FacilitySearch
                        initialRef={inputRef}
                        onFacilityChange={handleMappingChange}
                        onStateChange={handleStateChange}
                        facilityPlaceholder="Search Facilities"
                        facility={mapping}
                        input={facilityInput}
                        facilityMaxWidth={'470px'}
                        isReset={isOpen}
                        width="100%"
                      />
                    ) : (
                      <Box>
                        <Select
                          chakraStyles={chakraStyles}
                          focusBorderColor="brand.700"
                          aria-label="specialty"
                          options={specialties}
                          isSearchable
                          value={mapping}
                          isClearable={true}
                          onChange={handleMappingChange}
                          getOptionLabel={(option) => option.short_name}
                          getOptionValue={(option) => option.id}
                          placeholder={'Select profession/specialty'}
                          components={{ SingleValue: SingleValueShortName, Option: OptionShortName }}
                        />
                        <FormErrorMessage aria-label="specialty-error">Specialty is required</FormErrorMessage>
                      </Box>
                    )}
                  </GridItem>
                </Grid>
              </FormControl>
              {isError && <AlertComponent status="error" title={`Error adding ${typeLower} mapping `} description={errorMsg?.data?.detail} />}
              <HStack id={`add_${typeLower}_mapping_submit`} width="100%" py={3} px={8} justifyContent={'end'}>
                <Button onClick={onClose}>Cancel</Button>
                <Button type={'submit'} variant={'purple'} isDisabled={mapFrom === ''}>
                  Submit
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
};
AddMappingModal.propTypes = {
  type: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  errorMsg: PropTypes.object,
  handleSubmit: PropTypes.func,
};
export default AddMappingModal;
