import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectFlagFeatures } from '@/features/flags/flagSlice';
import { CAN_EDIT_TOP_LEVEL_CONTRACT } from '@/utils/constants';

export const useEditContracts = () => {
  const features = useSelector(selectFlagFeatures);

  return useMemo(() => {
    let enable = false;
    if (features) {
      const editContracts = features.find((feat) => feat.name === CAN_EDIT_TOP_LEVEL_CONTRACT);
      if (editContracts?.enabled) {
        enable = true;
      }
    }
    return enable;
  }, [features]);
};
