import React, { useState, useMemo } from 'react';
import {
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FiMoreHorizontal } from 'react-icons/fi';
import { MAX_LIST_LENGTH } from '@/utils/constants';

const cleanString = (string) => {
  const noSpecials = string.replace(/[^a-zA-Z ]/g, '');
  return noSpecials.toLowerCase().replace(/ /g, '_');
};
/**
 * Truncates strings at word boundary
 * WARN: prepare data with src/app/services/helpers.js#addTruncatedProperties
 * @param {string} title - The title appears in the Popover
 * @param {array} row - The list of items to display
 * @param {string} fieldName - The list of items to display
 * @returns {Element}
 */
const TruncatedArrayComponent = ({ title, fieldName, row }) => {
  // create a lowercase all alphabetic string for HTML attributes
  const list = row[`${fieldName}Popover`] ?? [];
  const truncatedString = row[`${fieldName}Truncated`] ?? '';
  const cleanTitle = useMemo(() => {
    return cleanString(title);
  }, [title]);

  if (truncatedString.split(',')?.length < list?.length) {
    return (
      <span>
        <span aria-label={`truncated ${cleanTitle}`}>{truncatedString + ` `}</span>
        <Popover placement="top-start">
          <PopoverTrigger>
            <IconButton variant="ghost" colorScheme={'cyan'} aria-label={`view ${cleanTitle}`} icon={<FiMoreHorizontal />} />
          </PopoverTrigger>
          <PopoverContent minW={{ base: '100%', lg: 'max-content' }} aria-label="popover content">
            <PopoverHeader fontWeight="semibold">{title}</PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <UnorderedList>
                {list.map((facility, i) => {
                  return <ListItem key={`${facility.id}-${i}`}>{facility.value}</ListItem>;
                })}
              </UnorderedList>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </span>
    );
  } else {
    return <span aria-label={cleanTitle}>{truncatedString}</span>;
  }
};

TruncatedArrayComponent.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  truncatedString: PropTypes.string,
};

export default TruncatedArrayComponent;
