export const datePickerConfig = (props) => ({
  dateNavBtnProps: {
    colorScheme: 'purple',
    variant: 'outline',
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      borderColor: 'red.300',
      _hover: {
        color: 'white',
        background: 'blue.700',
      },
    },
    isInRangeBtnProps: {
      color: 'yellow',
    },
    selectedBtnProps: {
      background: 'purple.500',
      color: 'white',
    },
    todayBtnProps: {
      color: 'gray.700',
      background: 'purple.100',
    },
  },
  inputProps: {
    'aria-label': props.name,
  },
  popoverCompProps: {
    popoverContentProps: {
      'aria-label': props.name + '-popover',
      background: 'white',
      color: 'gray.700',
    },
    popoverBodyProps: {},
  },
  calendarPanelProps: {
    wrapperProps: {
      borderColor: 'white',
    },
    contentProps: {
      borderWidth: 0,
    },
    headerProps: {
      padding: '5px',
    },
    dividerProps: {
      display: 'none',
    },
  },
  weekdayLabelProps: {
    fontWeight: 'normal',
  },
  dateHeadingProps: {
    fontWeight: 'semibold',
  },
});
