import { Box, Flex, Spinner } from '@chakra-ui/react';
import Layout from '../common/Layout';
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGetAuthUsersQuery } from '@/app/services/nucleus';
import { useRole } from '@/hooks/useRole';
import AlertComponent from '../common/AlertComponent';

const User = () => {
  useRole('admin');
  const [errorTitle, setErrorTitle] = useState('');
  const [errorDesc, setErrorDesc] = useState('');

  const { isLoading, isError, error: usersError } = useGetAuthUsersQuery();

  useEffect(() => {
    if (isError) {
      setErrorTitle('Users Error');
      setErrorDesc(usersError?.data?.detail);
    }
  }, [isError]);

  return (
    <Box data-test-id="pact-users">
      <Layout pageTitle={'Pact'}>
        {isLoading ? (
          <Flex alignItems="center" justifyContent="center" py={200}>
            <Spinner color="brand.900" size="lg" />
          </Flex>
        ) : isError ? (
          <AlertComponent status="warning" title={errorTitle} description={errorDesc} />
        ) : (
          <Outlet />
        )}
      </Layout>
    </Box>
  );
};

export default User;
