import { Box, Heading, VStack } from '@chakra-ui/react';
import NewUserForm from './NewUserForm';
import { useDispatch } from 'react-redux';
import { setNotificationLogMessage } from '../notification/notificationSlice';

const user = {
  id: null,
  first_name: '',
  last_name: '',
  email: '',
  roles: [],
  admin: false,
  username: '',
  password: '',
};

const NewUser = () => {
  const dispatch = useDispatch();

  const onSaveHandler = () => {
    dispatch(setNotificationLogMessage({ msg: 'Successfully added user', status: 'success' }));
  };

  return (
    <VStack spacing={4} alignItems={'left'}>
      <Box as="header">
        <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
          New User
        </Heading>
      </Box>
      <NewUserForm user={user} isNew onSave={onSaveHandler} />
    </VStack>
  );
};

export default NewUser;
