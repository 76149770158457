import React from 'react';
import AnimatedCell from './motion/AnimatedCell';
import LoadingWrapper from './motion/LoadingWrapper';
import { Button, ButtonGroup, Spinner } from '@chakra-ui/react';

const ModalOverlayComponent = ({ loading, confirmText, confirmBtnText, cancelBtnText, handleDelete, handleCancel }) => {
  return loading ? (
    <React.Fragment>
      <AnimatedCell colSpan="4" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <LoadingWrapper height={'40px'} alignItems="center">
          <Spinner />
        </LoadingWrapper>
      </AnimatedCell>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <AnimatedCell colSpan="2" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        {confirmText}
      </AnimatedCell>
      <AnimatedCell colSpan="2" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <ButtonGroup isAttached variant="outline">
          <Button aria-label="yes" onClick={handleDelete}>
            {confirmBtnText || 'Yes'}
          </Button>
          <Button aria-label="no" onClick={handleCancel}>
            {cancelBtnText || 'No'}
          </Button>
        </ButtonGroup>
      </AnimatedCell>
    </React.Fragment>
  );
};
export default ModalOverlayComponent;
