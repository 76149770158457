import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Text,
  Flex,
  List,
  ListItem,
  ListIcon,
  Spinner,
} from '@chakra-ui/react';
import AlertComponent from '../common/AlertComponent';
import { BsFillPersonFill, BsBuildingX } from 'react-icons/bs';
import { isArrayValue, isRealValue } from '@/utils/helpers';
import { useGetContractRepsQuery } from '@/app/services/nucleus.js';

const RemoveFacility = ({ id, facility, isOpen, onClose, isLoading, isError, errorMessage, removeFacilityHandler }) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [attachedReps, setAttachedReps] = useState([]);

  const { data: salesReps } = useGetContractRepsQuery({ id });

  const fetchRepsByFacilityId = (facilityId, reps) => {
    let repArray = [];
    for (const rep of reps) {
      if (isRealValue(rep?.facility_ids) && Array.isArray(rep?.facility_ids)) {
        if (isRealValue(rep.facility_ids.find((f) => f === facilityId))) {
          repArray.push(rep);
        }
      }
    }
    return repArray;
  };

  useEffect(() => {
    if (isOpen && isArrayValue(salesReps)) {
      setAttachedReps(fetchRepsByFacilityId(facility?.id, salesReps));
    }
  }, [isOpen, salesReps]);

  const handleRemoveFacility = (id) => {
    removeFacilityHandler(id);
  };

  return (
    <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Remove Facility</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={3}>
            {isLoading ? (
              <Flex alignItems="center" justifyContent="center">
                <Spinner color="brand.900" size="lg" />
              </Flex>
            ) : isError ? (
              <AlertComponent status="error" title={'Error removing facility.'} description={errorMessage || 'Please contact an administrator'} />
            ) : (
              <React.Fragment>
                <Text>Are you sure you want to remove this facility?</Text>
                <AlertComponent
                  icon={<BsBuildingX style={{ height: '36px', width: '36px' }} />}
                  title={facility?.name}
                  description={`${facility?.city}, ${facility?.state}`}
                />
                {attachedReps.length > 0 && (
                  <React.Fragment>
                    <Text>Sales reps assigned to this facility:</Text>
                    {attachedReps.map((rep, i) => (
                      <List key={`rep-${i}`} spacing={3}>
                        <ListItem>
                          <ListIcon as={BsFillPersonFill} color="green.500" />
                          {rep.name + ' - ' + rep.division}
                        </ListItem>
                      </List>
                    ))}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant="cyan" mr={3} onClick={() => handleRemoveFacility(facility?.id)}>
            Yes
          </Button>
          <Button onClick={onClose}>No</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

RemoveFacility.propTypes = {
  id: PropTypes.string,
  facility: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  removeFacilityHandler: PropTypes.func,
};

export default RemoveFacility;
