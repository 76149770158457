import PropTypes from 'prop-types';
import { Button } from '@chakra-ui/react';

const LinkButton = ({ icon, children, ...rest }) => {
  return (
    <Button leftIcon={icon} variant="link" color="brand.700" {...rest}>
      {children}
    </Button>
  );
};

LinkButton.propTypes = {
  icon: PropTypes.element,
  children: PropTypes.node,
};

export default LinkButton;
