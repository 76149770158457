import React from 'react';
import PropTypes from 'prop-types';
import { Box, Spinner, Flex } from '@chakra-ui/react';
import { SEARCHING } from '@/utils/constants';
import AlertComponent from './AlertComponent';
import { useSelector } from 'react-redux';
import { selectNotificationLogMessage } from '../notification/notificationSlice';

const SearchContainer = ({ feature, status, loading, isError, children }) => {
  const error = useSelector(selectNotificationLogMessage);
  return (
    <Box>
      {status === SEARCHING || loading ? (
        <Flex alignItems="center" justifyContent="center" mt={20}>
          <Spinner color="brand.900" size="lg" />
        </Flex>
      ) : isError ? (
        <AlertComponent status="error" title={`Error fetching ${feature.toLowerCase()}.`} description={error || 'Please contact an administrator'} />
      ) : (
        children
      )}
    </Box>
  );
};

SearchContainer.propTypes = {
  feature: PropTypes.string,
  status: PropTypes.string,
  loading: PropTypes.bool,
  isError: PropTypes.bool,
  children: PropTypes.node,
};

export default SearchContainer;
