import { Box, Heading, VStack } from '@chakra-ui/react';
import NewCertificationsForm from './NewCertificationsForm';
import { useDispatch } from 'react-redux';
import { setNotificationLogMessage } from '../notification/notificationSlice';

const NewCertifications = () => {
  const dispatch = useDispatch();

  const onSaveHandler = () => {
    dispatch(setNotificationLogMessage({ msg: 'Successfully added certification', status: 'success' }));
  };

  return (
    <VStack spacing={4} alignItems={'left'}>
      <Box as="header">
        <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
          New Certification
        </Heading>
      </Box>
      <NewCertificationsForm onSave={onSaveHandler} />
    </VStack>
  );
};

export default NewCertifications;
