import PropTypes from 'prop-types';
import { Box, Flex, Spacer, Stack } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { isArrayValue, isRealValue } from '@/utils/helpers';
import { ClearIndicator } from '@/features/common/select/ClearIndicator';
import SearchInput from '@/features/common/SearchInput';

const SearchComponent = ({
  filterValue,
  filterOptions,
  filterPlaceholder,
  filterLabelStyle,
  isDisabled,
  dataType,
  searchText,
  onFilterComplete,
  onSearchComplete,
  handleClearSearch,
  children,
}) => {
  const handleFilterChange = (value) => {
    onFilterComplete(value);
  };

  const handleSearch = (event) => {
    onSearchComplete(event.target.value);
  };

  const handleClear = () => {
    handleClearSearch();
  };

  return (
    <Stack direction={['column', 'row']} minWidth="fit-content" alignItems="center" gap="0">
      <Flex gap={5} justifyContent="space-between" width="100%" flexWrap="nowrap" mb={5}>
        <Box width={{ base: '100%', md: '35%' }}>
          <SearchInput isDisabled={isDisabled} searchText={searchText} dataType={dataType} onChange={handleSearch} onClear={handleClear} />
        </Box>
        {isArrayValue(filterOptions) && (
          <Select
            chakraStyles={{
              multiValueLabel: (base) => ({
                ...base,
                ...(isRealValue(filterLabelStyle) && filterLabelStyle),
              }),
            }}
            focusBorderColor="brand.700"
            isMulti={true}
            aria-label="table-filter"
            options={filterOptions}
            isSearchable
            value={filterValue}
            isClearable={true}
            onChange={handleFilterChange}
            placeholder={filterPlaceholder}
            components={{ ClearIndicator }}
          />
        )}
        <Spacer />
        {children}
      </Flex>
    </Stack>
  );
};

SearchComponent.propTypes = {
  filterValue: PropTypes.array,
  filterOptions: PropTypes.array,
  filterPlaceholder: PropTypes.string,
  filterLabelStyle: PropTypes.object,
  isDisabled: PropTypes.bool,
  dataType: PropTypes.string,
  searchText: PropTypes.string,
  onFilterComplete: PropTypes.func,
  onSearchComplete: PropTypes.func,
  handleClearSearch: PropTypes.func,
  children: PropTypes.node,
};

export default SearchComponent;
