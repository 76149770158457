import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TableContainer } from '@chakra-ui/react';
import { useReactTable, getCoreRowModel, getSortedRowModel, getPaginationRowModel } from '@tanstack/react-table';
import TableComponent from '@/features/common/table/TableComponent';
import PaginationComponent from '@/features/common/PaginationComponent';
import { scrollbar, pageOptions } from '@/utils/constants';
import { addColorData, updateTableData } from '@/features/common/table/helper';

const DataTable = ({ loading, data, columns, maxWidth, showScrollbar, sortBy, sortDirection, onSort, onLoaded }) => {
  const [sorting, setSorting] = useState(sortBy && sortBy !== 'none' ? [{ id: sortBy, desc: sortDirection === 'desc' }] : []);
  const [pageSize, setPageSize] = useState({ value: 20, label: 20 });
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (data) {
      setTableData(() => data);
    }
  }, [data]);

  useEffect(() => {
    if (sorting && typeof onSort === 'function') {
      onSort(sorting);
    }
  }, [sorting]);

  const table = useReactTable({
    columns,
    data: tableData,
    initialState: {
      pagination: {
        pageSize: pageSize.value,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
    },
    meta: {
      addRowColor: (rowIndex, value) => {
        setTableData((old) => addColorData(rowIndex, old, value));
      },
      updateData: (rowIndex, columnId, value) => {
        setTableData((old) => updateTableData(rowIndex, columnId, old, value));
      },
    },
  });

  useEffect(() => {
    if (table && data && onLoaded) {
      onLoaded(table);
    }
  }, [table, data]);

  const handlePageSize = (val) => {
    setPageSize(val);
  };

  return (
    <TableContainer css={showScrollbar && scrollbar}>
      <TableComponent table={table} columns={columns} showNoResults={loading === false && data && data.length === 0} maxWidth={maxWidth} />
      {data && data.length > 10 && (
        <PaginationComponent table={table} pageSize={pageSize} handlePageSize={handlePageSize} pageOptions={pageOptions} total={tableData.length} />
      )}
    </TableContainer>
  );
};

DataTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  columns: PropTypes.array,
  maxWidth: PropTypes.string,
  showScrollbar: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  onSort: PropTypes.func,
};

export default DataTable;
