import { createSlice } from '@reduxjs/toolkit';
import { nucleusApi } from '@/app/services/nucleus';
import { LOADED, LOADING, ERROR } from '@/utils/constants';
import { parseNucleusError } from '@/utils/helpers';

const initialState = {
  certifications: [],
  status: '',
  errorMessage: '',
  searchStatus: '',
  sortBy: 'name',
  sortDirection: 'asc',
};

export const certificationsSlice = createSlice({
  name: 'certifications',
  initialState,
  reducers: {
    setCertifications: (state, { payload }) => {
      state.certifications = payload;
    },
    setSearchStatus: (state, { payload }) => {
      state.searchStatus = payload;
    },
    setSortBy: (state, { payload }) => {
      state.sortBy = payload;
    },
    setSortDirection: (state, { payload }) => {
      state.sortDirection = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(nucleusApi.endpoints.getCertifications.matchRejected, (state, result) => {
      const { payload } = result;
      state.status = ERROR;
      state.errorMessage = parseNucleusError(payload);
    });
    builder.addMatcher(nucleusApi.endpoints.getCertifications.matchPending, (state, result) => {
      state.status = LOADING;
    });
    builder.addMatcher(nucleusApi.endpoints.getCertifications.matchFulfilled, (state, result) => {
      const { payload } = result;
      state.status = LOADED;
      state.errorMessage = '';
      state.certifications = payload;
    });
  },
});

export const { setCertifications, setSearchStatus, setSortBy, setSortDirection } = certificationsSlice.actions;

export const selectCertifiction = (state) => state.certifications.certifications;
export const selectCertificationStatus = (state) => state.certifications.status;
export const selectCertificationErrorMessage = (state) => state.certifications.errorMessage;
export const selectCertificationSearchStatus = (state) => state.certifications.searchStatus;
export const selectCertificationSortBy = (state) => state.certifications.sortBy;
export const selectCertificationSortDirection = (state) => state.certifications.sortDirection;

export default certificationsSlice.reducer;
