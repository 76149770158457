import React, { useState } from 'react';
import { Box, Heading, Switch, Tooltip, Text, useDisclosure, Button } from '@chakra-ui/react';
import Layout from '@/features/common/Layout';
import { useRole } from '@/hooks/useRole';
import DataTableSearchAndFiltering from '@/features/common/table/DataTableSearchAndFiltering';
import { ERROR, LOADING } from '@/utils/constants';
import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFlagSearchStatus,
  selectFlagSortBy,
  selectFlagSortDirection,
  setFlagSearchStatus,
  setFlagSortBy,
  setFlagSortDirection,
} from '@/features/flags/flagSlice';
import ModalComponent from '../common/ModalComponent';
import { useAddFeatureMutation, useGetFeaturesQuery, useUpdateFeatureMutation } from '@/app/services/nucleus';
import { useNotification } from '@/hooks/useNotification';
import { BsPlus } from 'react-icons/bs';
import AddFeatureModal from '@/features/flags/AddFeatureModal';

const FeatureManagement = () => {
  useRole('superadmin');
  const dispatch = useDispatch();
  const confirmToggleModal = useDisclosure();
  const addFeatureModal = useDisclosure();
  const columnHelper = createColumnHelper();
  const flagSearchStatus = useSelector(selectFlagSearchStatus);
  const sortBy = useSelector(selectFlagSortBy);
  const sortDirection = useSelector(selectFlagSortDirection);
  const [toggleText, setToggleText] = useState('');
  const [toggleData, setToggleData] = useState({});

  const { data: features, isLoading: isFeaturesLoading, isError: isFeaturesError, error: featuresError } = useGetFeaturesQuery();

  const [updateFeature, { isLoading: isUpdateFeatureLoading, isSuccess: isUpdateFeatureSuccess, reset: updateFeatureReset }] =
    useUpdateFeatureMutation();

  const [addFeature, { isLoading: addFeatureLoading, isSuccess: addFeatureSuccess, isError: addFeatureError, error: addFeatureErrorMsg }] =
    useAddFeatureMutation();

  useNotification(addFeatureSuccess, null, addFeatureSuccess, 'success', 'Feature Added');
  useNotification(isUpdateFeatureSuccess, updateFeatureReset, isUpdateFeatureSuccess, 'success', 'Feature Updated');

  const stageToggleFeature = (e, getValue, row, table) => {
    e.preventDefault();
    const enabled = getValue();
    setToggleText(enabled ? 'Disable' : 'Enable');
    setToggleData({ row, table, enabled });
    confirmToggleModal.onOpen();
  };

  const toggleFeature = () => {
    const { row, table, enabled } = toggleData;
    table.options.meta?.updateData(row.index, 'enabled', !enabled);

    updateFeature({ featureId: row.original.id, enabled: !enabled })
      .unwrap()
      .then((payload) => {
        confirmToggleModal.onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTableLoaded = (table) => {
    if (features) {
      features.map((feature, index) => {
        if (!feature?.enabled) {
          table.options.meta?.addRowColor(index, 'gray.50');
        }
      });
    }
  };

  const featureColumns = [
    columnHelper.accessor('name', {
      header: 'Name',
    }),
    columnHelper.accessor('enabled', {
      header: 'Enabled',
      cell: ({ getValue, row, table }) => {
        const enabled = getValue();
        return (
          <Tooltip hasArrow placement="top" label="Toggle Feature">
            <Box p="0" as="span">
              <Switch
                id="enable"
                aria-label="feature-enable"
                size="sm"
                colorScheme="cyan"
                isChecked={enabled}
                onChange={(e) => stageToggleFeature(e, getValue, row, table)}
              />
            </Box>
          </Tooltip>
        );
      },
    }),
    columnHelper.accessor('created_by', {
      header: 'Created By',
      cell: ({ getValue }) => {
        const user = getValue();
        return user?.username ? user.username : 'Nucleus';
      },
    }),
    columnHelper.accessor('modified_by', {
      header: 'Modified By',
      cell: ({ getValue }) => {
        const user = getValue();
        return user?.username ? user.username : 'Nucleus';
      },
    }),
  ];

  const handleAddFeature = (payload) => {
    const { name, roleIds, enabled } = payload;
    const featurePayload = {
      name,
      enabled,
      allowed_roles: roleIds,
    };

    addFeature({ feature: featurePayload })
      .unwrap()
      .then((payload) => {
        addFeatureModal.onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNewFeature = () => {
    addFeatureModal.onOpen();
  };

  const handleFlagSearchStatus = (text) => {
    dispatch(setFlagSearchStatus(text));
  };

  const handleFlagSortBy = (column) => {
    dispatch(setFlagSortBy(column));
  };

  const handleFlagSortDirection = (direction) => {
    dispatch(setFlagSortDirection(direction));
  };

  return (
    <Box data-testid="feature-management">
      <Layout pageTitle={'Pact'}>
        <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
          Feature Management
        </Heading>
        <Box mt="6" p={0}>
          <DataTableSearchAndFiltering
            title="Feature Management"
            originalData={features}
            searchStatus={flagSearchStatus}
            isSsearchStatus={flagSearchStatus === LOADING}
            isearchStatus={flagSearchStatus === ERROR}
            isDataLoading={isFeaturesLoading || isUpdateFeatureLoading}
            isDataError={isFeaturesError}
            dataErrorMsg={featuresError?.data?.detail}
            dataErrorTitle={'Error fetching feature flags'}
            dataColumns={featureColumns}
            searchColumns={['name', 'modified_by', 'created_by']}
            sortBy={sortBy}
            sortDirection={sortDirection}
            handleSortBy={handleFlagSortBy}
            handleSortDirection={handleFlagSortDirection}
            handleSearchStatus={handleFlagSearchStatus}
            handleTableLoaded={handleTableLoaded}
          >
            <Button leftIcon={<BsPlus />} onClick={handleNewFeature} variant="purple">
              New
            </Button>
          </DataTableSearchAndFiltering>
        </Box>
        <ModalComponent
          size="md"
          title="Confirm Feature Toggle"
          primaryText="Yes"
          secondaryText="No"
          handleConfirm={toggleFeature}
          onOpen={confirmToggleModal.onOpen}
          isOpen={confirmToggleModal.isOpen}
          onClose={confirmToggleModal.onClose}
          isError={false}
        >
          <Box>
            <Text>{`Are you sure you want to ${toggleText.toLowerCase()} this feature?`}</Text>
          </Box>
        </ModalComponent>
        <AddFeatureModal
          onOpen={addFeatureModal.onOpen}
          onClose={addFeatureModal.onClose}
          isOpen={addFeatureModal.isOpen}
          isLoading={addFeatureLoading}
          isError={addFeatureError}
          errorMsg={addFeatureErrorMsg}
          handleSubmit={handleAddFeature}
        />
      </Layout>
    </Box>
  );
};

export default FeatureManagement;
