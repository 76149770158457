import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetMeQuery } from '@/app/services/nucleus';

export const useRole = (role) => {
  const navigate = useNavigate();
  const { data: user, isSuccess: isGetUserSuccess } = useGetMeQuery();

  useEffect(() => {
    switch (role) {
      case 'superadmin':
        if (user && !user?.superadmin) {
          navigate('/401');
        }
        break;
      case 'admin':
        if (user && !user?.admin && !user?.superadmin) {
          navigate('/401');
        }
        break;
      default:
        if (user && !user?.roles?.includes(role) && !user?.admin && !user?.superadmin) {
          navigate('/401');
        }
    }
  }, [isGetUserSuccess]);

  return null;
};
