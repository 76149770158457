import { createSlice } from '@reduxjs/toolkit';
import { nucleusApi } from '@/app/services/nucleus';

const initialState = {
  status: '',
  sources: [],
  uploadErrorMsg: null,
};

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    setJobStatus: (state, { payload }) => {
      state.status = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(nucleusApi.endpoints.jobSources.matchFulfilled, (state, { payload }) => {
      if (payload && payload.length > 0) {
        state.sources = payload;
      }
    });
    builder.addMatcher(nucleusApi.endpoints.uploadJobs.matchRejected, (state, { payload }) => {
      state.uploadErrorMsg = payload?.data?.detail;
    });
  },
});

export const { setJobStatus } = jobSlice.actions;

export const selectJobStatus = (state) => state.job.status;
export const selectJobUploadErrorMsg = (state) => state.job.uploadErrorMsg;
export const selectJobSources = (state) => state.job.sources;

export default jobSlice.reducer;
