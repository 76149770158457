import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Divider,
  AbsoluteCenter,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Spinner,
  VStack,
  Badge,
} from '@chakra-ui/react';
import { useRole } from '@/hooks/useRole';
import Layout from '@/features/common/Layout.jsx';
import { Select } from 'chakra-react-select';
import { chakraStyles } from '@/features/common/select/styles.js';
import { selectEntityTypeList, selectEventSourceList } from '@/features/enum/enumSlice.js';
import { FiSearch } from 'react-icons/fi';
import { useLazyGetEntityQuery } from '@/app/services/nucleus.js';
import { isArrayEmpty, isArrayValue, isRealValue } from '@/utils/helpers.js';
import AlertComponent from '@/features/common/AlertComponent.jsx';

const EntityLookup = () => {
  useRole('support');
  const [source, setSource] = useState(null);
  const [entity, setEntity] = useState(null);
  const [systemId, setSystemId] = useState('');
  const [entityResult, setEntityResult] = useState(null);

  const sourceList = useSelector(selectEventSourceList);
  const entityTypeList = useSelector(selectEntityTypeList);

  const [getEntity, { isFetching: isEntityFetching, isError: isEntityError, error: entityError }] = useLazyGetEntityQuery();

  const handleSourceChange = (value, action) => {
    setSource(value);
  };

  const handleEntityTypeChange = (value, action) => {
    setEntity(value);
  };

  const handleSystemIdChange = (event) => {
    setSystemId(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await getEntity({ entity: entity?.value, source: source?.value, systemId }).unwrap();
      if (isRealValue(result)) {
        setEntityResult(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DataHeading = ({ label, empty, bg }) => {
    return (
      <Box pos="relative" width="100%" p={2} key={label}>
        <Divider />
        <AbsoluteCenter bg={`${bg}.50`} px={4}>
          <i>{label + ' '}</i>
          {empty && <Badge colorScheme={bg}>Empty</Badge>}
        </AbsoluteCenter>
      </Box>
    );
  };

  const ReadOnlyField = ({ heading, label, value }) => {
    return (
      <FormControl mt={2} ml={heading ? 8 : 0} key={label + value}>
        <FormLabel>{label}</FormLabel>
        <Input
          isReadOnly
          type="text"
          value={value || 'null'}
          placeholder={!value ? 'null' : ''}
          bgColor={'white'}
          color={'gray.500'}
          _placeholder={{ color: 'brand.500' }}
        />
      </FormControl>
    );
  };

  const entityResultParser = (entity, heading) => {
    if (entity && typeof entity === 'object') {
      return Object.entries(entity).map(([key, value]) => {
        if (isArrayValue(value)) {
          return value.map((val, i) => {
            const heading = `${key} ${i}`;
            return (
              <Box key={heading} p={3} bg={'gray.50'} width={'100%'} borderRadius={6}>
                <DataHeading bg={'gray'} label={heading} />
                {entityResultParser(val, heading)}
              </Box>
            );
          });
        } else if (value && typeof value === 'object') {
          let heading = key;
          return (
            <Box key={key} p={3} bg={'yellow.50'} width={'100%'} borderRadius={6} mt={4}>
              <DataHeading bg={'yellow'} label={heading} empty={isArrayEmpty(value)} />
              {entityResultParser(value, heading)}
            </Box>
          );
        } else {
          return <ReadOnlyField key={key} label={key} value={value} />;
        }
      });
    }
  };

  return (
    <Box data-testid="pact-margin-rules">
      <Layout pageTitle={'Pact'}>
        <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
          Entity Lookup
        </Heading>
        <Box mt="6" p={0} pos={'relative'}>
          {isEntityFetching && (
            <Flex
              alignItems="center"
              justifyContent="center"
              pos={'absolute'}
              bg={'rgba(255,255,255,0.6)'}
              height={'100%'}
              zIndex={1200}
              width="100%"
            >
              <Spinner color="brand.900" size="lg" />
            </Flex>
          )}
          <form id="add_facility" onSubmit={handleSubmit}>
            <VStack spacing={4} justifyContent={'space-between'} maxWidth={'60%'}>
              <FormControl id="source">
                <Box>
                  <Select
                    id="source-select"
                    aria-label="source-select"
                    chakraStyles={chakraStyles}
                    focusBorderColor="brand.700"
                    options={sourceList}
                    onChange={handleSourceChange}
                    isClearable
                    isSearchable
                    value={source}
                    placeholder="Select a source"
                  />
                  <FormErrorMessage aria-label="source-error">Error setting source</FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl id="entity-type">
                <Box>
                  <Select
                    id="entity-type-select"
                    aria-label="entity-type-select"
                    chakraStyles={chakraStyles}
                    focusBorderColor="brand.700"
                    options={entityTypeList}
                    onChange={handleEntityTypeChange}
                    isClearable
                    isSearchable
                    value={entity}
                    placeholder="Select an entity type"
                  />
                  <FormErrorMessage aria-label="source-error">Error setting source</FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl id="system-id">
                <Input
                  id="system-id-input"
                  aria-label="system-id-input"
                  focusBorderColor={'brand.700'}
                  type="text"
                  value={systemId}
                  onChange={handleSystemIdChange}
                  placeholder="System ID"
                />
                <FormErrorMessage aria-label="system-id-error">Error setting system ID</FormErrorMessage>
              </FormControl>
              <FormControl id="system-id" py={6}>
                <Button leftIcon={<FiSearch />} variant="purple" type="submit">
                  Search
                </Button>
              </FormControl>
            </VStack>
          </form>
          <Divider />
          {isEntityError ? (
            <AlertComponent mb={6} status="error" title="Error Fetching Entity" description={entityError?.data?.detail} />
          ) : (
            <Box mt={4}>
              {entityResult && (
                <Heading as="h4" size="md" fontWeight="bold" color="brand.900" mb={4}>
                  Results
                </Heading>
              )}
              <VStack spacing={4} justifyContent={'space-between'} maxWidth={'60%'}>
                {isRealValue(entityResult) && entityResultParser(entityResult)}
              </VStack>
            </Box>
          )}
        </Box>
      </Layout>
    </Box>
  );
};

export default EntityLookup;
