import React, { useEffect, useMemo } from 'react';
import { Badge, Box, Button, Card, Divider, Heading, HStack, Text, useDisclosure } from '@chakra-ui/react';
import Layout from '@/features/common/Layout';
import { useGetMeQuery, useUpdateMyPasswordMutation } from '@/app/services/nucleus';
import ChangePasswordModal from './ChangePasswordModal';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import { setUserPassword } from '@/features/user/userSlice';
import { useDispatch } from 'react-redux';
import { outputRoles } from './userHelper';

const Profile = () => {
  const dispatch = useDispatch();
  const changePasswordModal = useDisclosure();
  const [pactData, setPactData] = useSessionStorage('pact_data', {});
  const { data: user, isSuccess: isGetUserSuccess } = useGetMeQuery();

  const [
    updatePassword,
    {
      isLoading: updatePasswordLoading,
      isSuccess: updatePasswordSuccess,
      isError: updatePasswordError,
      error: updatePasswordErrorMsg,
      reset: updatePasswordReset,
    },
  ] = useUpdateMyPasswordMutation();

  useEffect(() => {
    if (pactData?.key) {
      dispatch(setUserPassword(atob(pactData.key)));
    }
  }, [pactData]);

  const userData = useMemo(() => {
    return [
      { label: '', title: true, value: user?.first_name + ' ' + user?.last_name },
      { label: 'Username:', value: user?.username },
      { label: 'Email:', value: user?.email },
      { label: 'Roles:', value: outputRoles(user?.roles) },
      { label: 'Administrator:', value: user?.admin ? <Badge colorScheme={'green'}>TRUE</Badge> : <Badge>FALSE</Badge> },
      { label: 'Disabled:', value: user?.disabled_at ? <Badge>TRUE</Badge> : <Badge>FALSE</Badge> },
    ];
  }, [user]);

  const handleSubmit = ({ password, confirmPassword, currentPassword }) => {
    updatePassword({ password, confirmPassword, currentPassword })
      .unwrap()
      .then((payload) => {
        if (payload) {
          changePasswordModal.onClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box data-testid="pact-contracts">
      <Layout pageTitle={'Pact'}>
        <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
          User Profile
        </Heading>
        <Box mt="6" p={0}>
          {isGetUserSuccess ? (
            <Box>
              <Card maxW={'sm'}>
                <Box p="6">
                  {userData.map((data, index) => {
                    if (data?.title) {
                      return (
                        <React.Fragment key="title">
                          <Box mt={0} mb={5}>
                            <Text fontSize="xl" fontWeight="semibold" lineHeight="short">
                              {data.value}
                            </Text>
                          </Box>
                          <Divider my={2} />
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={data.label}>
                          <HStack gap={4}>
                            <Text color={'gray.500'} minW={'100px'}>
                              {data.label}
                            </Text>
                            <Text color={'brand.900'} aria-label={`${data.label.replace(':', '').toLowerCase()}-value`}>
                              {data.value}
                            </Text>
                          </HStack>
                          <Divider my={2} />
                        </React.Fragment>
                      );
                    }
                  })}
                  <HStack gap={4} mt={5}>
                    <Button variant="purple" size="sm" onClick={changePasswordModal.onOpen}>
                      Change my password
                    </Button>
                  </HStack>
                </Box>
              </Card>
            </Box>
          ) : (
            <Box>
              <Text as="p">Unable to fetch user</Text>
            </Box>
          )}
        </Box>
      </Layout>
      <ChangePasswordModal
        onClose={changePasswordModal.onClose}
        isOpen={changePasswordModal.isOpen}
        onOpen={changePasswordModal.onOpen}
        requireCurrentPassword={true}
        onSubmit={handleSubmit}
        request={{
          isLoading: updatePasswordLoading,
          isError: updatePasswordError,
          error: updatePasswordErrorMsg,
          isSuccess: updatePasswordSuccess,
          reset: updatePasswordReset,
        }}
      />
    </Box>
  );
};

export default Profile;
