import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Box } from '@chakra-ui/react';
import { isRealValue } from '@/utils/helpers.js';

const AlertComponent = ({ status, title, description, icon, ...rest }) => {
  const [desc, setDesc] = useState();

  // NOTE: we are testing out dynamically calling appsignal
  // anytime this component is used with a valid description.
  // This should cover 99% of Pact's "handled" errors
  useEffect(() => {
    if (isRealValue(description) && status === 'error') {
      let err = '';
      if (Array.isArray(description)) {
        const loc = description[0]?.loc;
        const query = Array.isArray(loc) && isRealValue(loc) ? loc.join(', ') : '';
        err = query + ' ' + description[0]?.msg;
      } else {
        err = description;
      }
      setDesc(err);
    } else {
      setDesc(description);
    }
  }, [description]);

  return (
    <Alert status={status || 'error'} {...rest} borderRadius={6}>
      {icon || <AlertIcon />}
      <Box pl={4}>
        <AlertTitle>{title || 'Error'}</AlertTitle>
        <AlertDescription>{desc || 'Please contact an administrator.'}</AlertDescription>
      </Box>
    </Alert>
  );
};
AlertComponent.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  icon: PropTypes.element,
};

export default AlertComponent;
