import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'chakra-react-select';
import { DropdownIndicator } from './select/DropdownIndicator';
import { BsChevronDoubleLeft, BsChevronLeft, BsChevronRight, BsChevronDoubleRight } from 'react-icons/bs';
import { Flex, Text, Button, Icon, HStack, Spacer } from '@chakra-ui/react';

const PaginationComponent = ({ table, pageSize, handlePageSize, pageOptions, total }) => {
  const portalRef = useRef(null);

  const handlePageSizeChange = (val) => {
    table.setPageSize(val.value);
    if (typeof handlePageSize === 'function') {
      handlePageSize(val);
    }
  };

  return (
    <HStack width="100%" mt={'40px'}>
      <Flex justify="start">
        <Text mr={6} fontSize="sm">
          Total <strong>{total}</strong>
        </Text>
      </Flex>
      <Spacer />
      <Flex ref={portalRef} align={'center'} justify={'end'} gap={'10px'}>
        <Text fontSize="sm" mr={-1}>
          Rows per page
        </Text>
        <Select
          focusBorderColor="brand.700"
          aria-label="page-select"
          components={{ DropdownIndicator: DropdownIndicator }}
          chakraStyles={{
            singleValue: (defaultStyles) => ({
              ...defaultStyles,
              fontWeight: 'bold',
              cursor: 'pointer',
            }),
          }}
          styles={{
            dropdownIndicator: (provided) => ({
              ...provided,
              padding: '3px',
              marginRight: '10px',
            }),
          }}
          variant={'unstyled'}
          placeholder="20"
          size="sm"
          isSearchable={false}
          menuPosition="fixed"
          menuPortalTarget={portalRef.current}
          value={pageSize}
          onChange={handlePageSizeChange}
          options={pageOptions}
        />
        <Text mr={6} fontSize="sm">
          Page{' '}
          <strong>
            {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </strong>{' '}
        </Text>
        <Button size={'xs'} onClick={() => table.setPageIndex(0)} isDisabled={!table.getCanPreviousPage()}>
          <Icon boxSize={4} as={BsChevronDoubleLeft} />
        </Button>{' '}
        <Button size={'xs'} onClick={() => table.previousPage()} isDisabled={!table.getCanPreviousPage()}>
          <Icon boxSize={4} as={BsChevronLeft} />
        </Button>{' '}
        <Button size={'xs'} onClick={() => table.nextPage()} isDisabled={!table.getCanNextPage()}>
          <Icon boxSize={4} as={BsChevronRight} />
        </Button>{' '}
        <Button size={'xs'} onClick={() => table.setPageIndex(table.getPageCount() - 1)} isDisabled={!table.getCanNextPage()}>
          <Icon boxSize={4} as={BsChevronDoubleRight} />
        </Button>{' '}
      </Flex>
    </HStack>
  );
};

PaginationComponent.propTypes = {
  table: PropTypes.object,
  pageSize: PropTypes.object,
  handlePageSize: PropTypes.func,
  pageOptions: PropTypes.array,
  total: PropTypes.number,
};
export default PaginationComponent;
