import { createSelector, createSlice } from '@reduxjs/toolkit';
import { nucleusApi } from '@/app/services/nucleus';
import { parseNucleusError } from '@/utils/helpers';
import { ERROR, LOADED, LOADING } from '@/utils/constants';

const initialState = {
  id: '',
  repId: '',
  rateCardId: null,
  facilities: [],
  userReps: [],
  salesReps: [],
  rateCardData: [],
  rateCards: [],
  divisions: [],
  salesRepAssociations: [],
  salesRepSearchStatus: '',
  facilitySearchStatus: '',
  rateCardSearchStatus: '',
  client: null,
  status: '',
  searchStatus: '',
  errorMessage: '',
  addFacilityError: '',
  sortBy: 'none',
  sortDirection: 'asc',
  rateCardSortBy: 'name',
  rateCardSortDirection: 'asc',
  salesRepSortBy: 'name',
  salesRepSortDirection: 'asc',
  getContractStatus: '',
  getContractErrorMsg: '',
};

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setContractFacilities: (state, { payload }) => {
      state.facilities = payload;
    },
    setContractSortBy: (state, { payload }) => {
      state.sortBy = payload;
    },
    setRateCardSortBy: (state, { payload }) => {
      state.rateCardSortBy = payload;
    },
    setRateCardSortDirection: (state, { payload }) => {
      state.rateCardSortDirection = payload;
    },
    setSalesRepSortBy: (state, { payload }) => {
      state.salesRepSortBy = payload;
    },
    setContractClient: (state, { payload }) => {
      state.client = payload;
    },
    setSalesRepSortDirection: (state, { payload }) => {
      state.salesRepSortDirection = payload;
    },
    setContractSortDirection: (state, { payload }) => {
      state.sortDirection = payload;
    },
    setContractSalesRepAssociations: (state, { payload }) => {
      state.salesRepAssociations = payload;
    },
    setContractStatus: (state, { payload }) => {
      state.status = payload;
    },
    setContractSearchStatus: (state, { payload }) => {
      state.searchStatus = payload;
    },
    setGetContractStatus: (state, { payload }) => {
      state.getContractStatus = payload;
    },
    setContractDivisions: (state, { payload }) => {
      state.divisions = payload;
    },
    setContractId: (state, { payload }) => {
      state.id = payload;
    },
    setRateCardId: (state, { payload }) => {
      state.rateCardId = payload;
    },
    setContractRateCardData: (state, { payload }) => {
      state.rateCardData = payload;
    },
    setContractRateCards: (state, { payload }) => {
      state.rateCards = payload;
    },
    setRepId: (state, { payload }) => {
      state.repId = payload;
    },
    setContractSalesReps: (state, { payload }) => {
      state.salesReps = payload;
    },
    setFacilitySearchStatus: (state, { payload }) => {
      state.facilitySearchStatus = payload;
    },
    setRateCardSearchStatus: (state, { payload }) => {
      state.rateCardSearchStatus = payload;
    },
    setSalesRepSearchStatus: (state, { payload }) => {
      state.salesRepSearchStatus = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(nucleusApi.endpoints.contracts.matchRejected, (state, result) => {
      const { payload } = result;
      state.errorMessage = parseNucleusError(payload);
    });
    builder.addMatcher(nucleusApi.endpoints.addFacility.matchRejected, (state, result) => {
      const { payload } = result;
      state.addFacilityError = parseNucleusError(payload);
    });
    builder
      .addMatcher(nucleusApi.endpoints.getContract.matchPending, (state, result) => {
        state.getContractStatus = LOADING;
      })
      .addMatcher(nucleusApi.endpoints.getContract.matchFulfilled, (state, result) => {
        state.getContractStatus = LOADED;
        state.getContractErrorMsg = '';
      })
      .addMatcher(nucleusApi.endpoints.getContract.matchRejected, (state, result) => {
        const { payload } = result;
        state.getContractErrorMsg = payload;
        state.getContractStatus = ERROR;
      });
  },
});

export const {
  setContractFacilities,
  setContractSortBy,
  setContractSortDirection,
  setRateCardSortBy,
  setRateCardSortDirection,
  setSalesRepSortBy,
  setContractClient,
  setSalesRepSortDirection,
  setContractDivisions,
  setContractRateCardData,
  setContractSalesRepAssociations,
  setContractStatus,
  setGetContractStatus,
  setSalesRepSearchStatus,
  setContractId,
  setRateCardId,
  setRepId,
  setContractSalesReps,
  setContractRateCards,
  setFacilitySearchStatus,
  setRateCardSearchStatus,
  setContractSearchStatus,
} = contractSlice.actions;

export const selectContractStatus = (state) => state.contract.status;
export const selectContractSearchStatus = (state) => state.contract.searchStatus;
export const selectContractFacilities = (state) => state.contract.facilities;
export const selectContractClient = (state) => state.contract.client;
export const selectContractSortBy = (state) => state.contract.sortBy;
export const selectContractSortDirection = (state) => state.contract.sortDirection;
export const selectRateCardSortBy = (state) => state.contract.rateCardSortBy;
export const selectRateCardSortDirection = (state) => state.contract.rateCardSortDirection;
export const selectSalesRepSortBy = (state) => state.contract.salesRepSortBy;
export const selectSalesRepSortDirection = (state) => state.contract.salesRepSortDirection;
export const selectFacilitySearchStatus = (state) => state.contract.facilitySearchStatus;
export const selectRateCardSearchStatus = (state) => state.contract.rateCardSearchStatus;
export const selectSalesRepSearchStatus = (state) => state.contract.salesRepSearchStatus;
export const selectContractSalesRepAssociations = (state) => state.contract.salesRepAssociations;
export const selectContractId = (state) => state.contract.id;
export const selectRateCardId = (state) => state.contract.rateCardId;
export const selectRepId = (state) => state.contract.repId;
export const selectContractDivisions = (state) => state.contract.divisions;
export const selectContractDivisionsList = createSelector(selectContractDivisions, (divisions) => {
  return Array.isArray(divisions) && divisions.map((div, i) => ({ value: div, label: div }));
});
export const selectGetContractStatus = (state) => state.contract.getContractStatus;
export const selectGetContractErrorMsg = (state) => state.contract.getContractErrorMsg;
export const selectContractRateCards = (state) => state.contract.rateCards;
export const selectContractRateCardData = (state) => state.contract.rateCardData;

// memoized selectors
export const selectRateCardById = createSelector([selectRateCardId, selectContractRateCards], (id, rates) => rates?.find((row) => row.id === id));

export default contractSlice.reducer;
