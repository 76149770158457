import React from 'react';
import {
  Avatar,
  Heading,
  IconButton,
  Flex,
  HStack,
  VStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  Text,
  Box,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FiMenu, FiChevronDown } from 'react-icons/fi';
import triageLogo from '/triage.svg';
import { useLogoutMutation } from '@/app/services/nucleus';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserName, selectUserAdmin, selectUserUsername } from '../user/userSlice';
import { handleLogoutAction } from '../login/loginActions';
import { useLocation, useNavigate } from 'react-router-dom';

const TopNav = ({ pageTitle, onOpen, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const fullName = useSelector(selectUserName);
  const username = useSelector(selectUserUsername);
  const admin = useSelector(selectUserAdmin);

  const handleProfileClick = () => {
    navigate('/profile', { state: { from: location } });
  };

  const handleLogout = () => {
    logout()
      .unwrap()
      .catch((error) => {
        console.log(error);
      });
    dispatch(handleLogoutAction());
  };

  const white = useColorModeValue('white', 'gray.900');
  const gray = useColorModeValue('gray.300', 'gray.700');

  return (
    <Flex
      ml={{ base: 0, md: 64 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={white}
      borderBottomWidth="5px"
      borderBottomColor="brand.900"
      justifyContent={'space-between'}
      {...rest}
    >
      <IconButton variant="outline" onClick={onOpen} aria-label="open menu" icon={<FiMenu />} display={{ base: 'flex', md: 'none' }} />

      <Heading as="h1" size={{ lg: 'lg', md: 'md', sm: 'sm' }} fontWeight="bold" color="brand.900">
        {pageTitle}
      </Heading>
      <Box p={4} display={{ base: 'flex', md: 'none' }}>
        <img src={triageLogo} className="logo" alt="Triage logo" width={180} />
      </Box>
      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar name={fullName ?? username} />
                <VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2">
                  <Text fontSize="sm">{fullName ?? username}</Text>
                  <Text fontSize="xs" color="gray.600">
                    {admin ? 'Admin' : 'User'}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg={white} borderColor={gray}>
              <MenuItem onClick={(e) => handleProfileClick()}>Profile</MenuItem>
              <MenuItem onClick={(e) => handleLogout()}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

TopNav.propTypes = {
  pageTitle: PropTypes.string,
  onOpen: PropTypes.func,
};

export default TopNav;
