import { createSlice } from '@reduxjs/toolkit';
import { nucleusApi } from '@/app/services/nucleus';

const initialState = {
  features: [],
  sortDirection: 'asc',
  searchStatus: '',
  sortBy: 'name',
};

export const flagSlice = createSlice({
  name: 'flag',
  initialState,
  reducers: {
    setFeatures: (state, { payload }) => {
      state.features = payload;
    },
    setFlagSearchStatus: (state, { payload }) => {
      state.searchStatus = payload;
    },
    setFlagSortDirection: (state, { payload }) => {
      state.sortDirection = payload;
    },
    setFlagSortBy: (state, { payload }) => {
      state.sortBy = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(nucleusApi.endpoints.getFeatures.matchFulfilled, (state, { payload }) => {
      state.features = payload;
    });
  },
});

export const { setFeatures, setFlagSearchStatus, setFlagSortBy, setFlagSortDirection } = flagSlice.actions;

export const selectFlagFeatures = (state) => state.flag.features;
export const selectFlagSearchStatus = (state) => state.flag.searchStatus;
export const selectFlagSortDirection = (state) => state.flag.sortDirection;
export const selectFlagSortBy = (state) => state.flag.sortBy;

export default flagSlice.reducer;
