import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useAddAuthUserMutation, useUpdateAuthUserMutation, useUpdateAuthUserPasswordMutation } from '@/app/services/nucleus';
import AlertComponent from '../common/AlertComponent';
import { BsPersonFillX } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectRolesList } from '../enum/enumSlice';
import { Select } from 'chakra-react-select';
import { chakraStyles } from '../common/select/styles';
import PasswordInput from '../common/PasswordInput';
import { parseErrorLocation, passwordRules } from './userHelper';
import { FaCheckCircle } from 'react-icons/fa';
import { FaCircleXmark } from 'react-icons/fa6';
import ComplexityRules from '../common/ComplexityRules';
import LoaderOverlay from '../common/LoaderOverlay';
import ChangePasswordModal from './ChangePasswordModal';

const NewUserForm = ({ isNew, user, onSave }) => {
  const navigate = useNavigate();
  const changePasswordModal = useDisclosure();
  const labelColor = useColorModeValue('gray.500', 'gray.400');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState([]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [admin, setAdmin] = useState(false);
  const roles = useSelector(selectRolesList);
  const [fieldValidation, setFieldValidation] = useState(null);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [typingPassword, setTypingPassword] = useState(false);
  const [showComplexityRules, setShowComplexityRules] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const [addAuthUser, { isLoading: addAuthUserLoading, isError: addAuthUserError, error: addAuthUserErrorMsg, reset: addAuthUserReset }] =
    useAddAuthUserMutation();

  const [
    updateAuthUser,
    { isLoading: updateAuthUserLoading, isError: updateAuthUserError, error: updateAuthUserErrorMsg, reset: updateAuthUserReset },
  ] = useUpdateAuthUserMutation();

  const [
    updateAuthUserPassword,
    {
      isLoading: updateAuthUserPasswordLoading,
      isSuccess: updateAuthUserPasswordSuccess,
      isError: updateAuthUserPasswordError,
      error: updateAuthUserPasswordErrorMsg,
      reset: updateAuthUserPasswordReset,
    },
  ] = useUpdateAuthUserPasswordMutation();

  const rulesData = useMemo(() => passwordRules(password), [password]);

  const icons = {
    validIcon: FaCheckCircle,
    invalidIcon: FaCircleXmark,
    validAriaLabel: 'check-icon',
    invalidAriaLabel: 'x-icon',
  };

  useEffect(() => {
    setFieldValidation({
      first_name: {
        valid: true,
        error: '',
      },
      last_name: {
        valid: true,
        error: '',
      },
      email: {
        valid: true,
        error: '',
      },
      username: {
        valid: true,
        error: '',
      },
      password: {
        valid: true,
        error: '',
      },
    });
    addAuthUserReset();
  }, []);

  useEffect(() => {
    const isValid = rulesData.every((val) => val.value === true);
    setPasswordValidation(isValid);
    setSubmitButtonDisabled(!isValid);
  }, [rulesData]);

  useEffect(() => {
    if (user?.id) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
      setUsername(user.username);
      setRole(user.roles.map((r) => ({ label: r, value: r })));
      setAdmin(user.admin);
      setSubmitButtonDisabled(false);
    }
  }, [user]);

  useEffect(() => {
    if (addAuthUserError) {
      const errorLocation = parseErrorLocation(addAuthUserErrorMsg, fieldValidation);
      setFieldValidation(errorLocation);
    }
  }, [addAuthUserError]);

  useEffect(() => {
    if (updateAuthUserError) {
      const errorLocation = parseErrorLocation(updateAuthUserErrorMsg, fieldValidation);
      setFieldValidation(errorLocation);
    }
  }, [updateAuthUserError]);

  useEffect(() => {
    if (submitForm) {
      const allFieldsValid = Object.values(fieldValidation).every((val) => val.valid);
      setSubmitButtonDisabled(!allFieldsValid);
    }
  }, [fieldValidation, submitForm]);

  const handleFirstName = (e) => {
    const val = e.target.value;
    setFirstName(val);
    handleFieldChange('first_name');
  };

  const handleLastName = (e) => {
    const val = e.target.value;
    setLastName(val);
    handleFieldChange('last_name');
  };

  const handleEmail = (e) => {
    const val = e.target.value;
    setEmail(val);
    handleFieldChange('email');
  };

  const handleUsername = (e) => {
    if (isNew) {
      const val = e.target.value;
      setUsername(val);
      handleFieldChange('username');
    } else {
      e.preventDefault();
    }
  };

  const handlePassword = (e) => {
    setTypingPassword(true);
    const val = e.target.value;
    setShowComplexityRules(Boolean(val));
    setPassword(val);
  };

  const handleAdmin = (value) => {
    setAdmin(value === 'true');
  };

  const handleRoles = (selectOptions) => {
    setRole(selectOptions || []);
  };

  const handleFieldChange = (fieldName) => {
    setFieldValidation((prev) => ({
      ...prev,
      [fieldName]: {
        valid: true,
        error: '',
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addAuthUserReset();
    updateAuthUserReset();
    setSubmitForm(true);

    const authUserPayload = {
      id: user.id,
      first_name: firstName,
      last_name: lastName,
      admin,
      email,
      roles: role.map((r) => r.value),
      username,
    };

    if (isNew) {
      addAuthUser({
        authUser: { ...authUserPayload, password },
      })
        .unwrap()
        .then((payload) => {
          onSave(payload);
          navigate('/user_management');
        })

        .catch((error) => {
          console.log(error);
        });
    } else {
      updateAuthUser({ id: user.id, authUser: authUserPayload })
        .unwrap()
        .then((payload) => {
          onSave(payload);
          navigate('/user_management');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleChangePassword = ({ password, confirmPassword }) => {
    updateAuthUserPassword({ id: user.id, password, confirmPassword })
      .unwrap()
      .then((payload) => {
        if (payload) {
          changePasswordModal.onClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <form aria-label={isNew ? 'New User' : 'Edit User'} name="user-form" onSubmit={handleSubmit}>
      <VStack spacing={4} alignItems="flex-start" width="full">
        <LoaderOverlay loading={updateAuthUserLoading} />
        <LoaderOverlay loading={addAuthUserLoading} />

        {addAuthUserError && (
          <AlertComponent
            icon={<BsPersonFillX style={{ height: '24px', width: '24px' }} />}
            status="error"
            title="Error Adding User"
            description={addAuthUserErrorMsg?.status === 422 ? 'Please add a valid user' : addAuthUserErrorMsg?.data?.detail}
          />
        )}

        <FormControl id="firstName" isInvalid={fieldValidation && !fieldValidation['first_name'].valid} aria-label="first-name-control">
          <Grid
            templateColumns={{
              base: '1fr',
              md: '1fr 2fr',
            }}
            gap={4}
            alignItems="center"
            width={{
              base: '100%',
              md: '70%',
            }}
          >
            <FormLabel
              htmlFor="firstName"
              mb="0"
              textAlign={{
                base: 'start',
                md: 'end',
              }}
              color={labelColor}
              whiteSpace="nowrap"
            >
              First Name
            </FormLabel>
            <Input id="firstName" name="firstName" type="text" value={firstName} onChange={handleFirstName} />
            <FormErrorMessage gridColumn="2" mt="-10px">
              {fieldValidation && fieldValidation['first_name'].error}
            </FormErrorMessage>
          </Grid>
        </FormControl>

        <FormControl id="lastName" isInvalid={fieldValidation && !fieldValidation['last_name'].valid} aria-label="last-name-control">
          <Grid
            templateColumns={{
              base: '1fr',
              md: '1fr 2fr',
            }}
            gap={4}
            alignItems="center"
            width={{
              base: '100%',
              md: '70%',
            }}
          >
            <FormLabel
              htmlFor="lastName"
              mb="0"
              color={labelColor}
              textAlign={{
                base: 'start',
                md: 'end',
              }}
              whiteSpace="nowrap"
            >
              Last Name
            </FormLabel>
            <Input id="lastName" name="lastName" type="text" value={lastName} onChange={handleLastName} />
            <FormErrorMessage gridColumn="2" mt="-10px">
              {fieldValidation && fieldValidation['last_name'].error}
            </FormErrorMessage>
          </Grid>
        </FormControl>

        <FormControl id="email" isInvalid={fieldValidation && !fieldValidation['email'].valid} aria-label="email-control">
          <Grid
            templateColumns={{
              base: '1fr',
              md: '1fr 2fr',
            }}
            gap={4}
            alignItems="center"
            width={{
              base: '100%',
              md: '70%',
            }}
          >
            <FormLabel
              htmlFor="email"
              mb="0"
              textAlign={{
                base: 'start',
                md: 'end',
              }}
              color={labelColor}
            >
              Email
            </FormLabel>
            <Input id="email" name="email" type="email" value={email} onChange={handleEmail} />
            <FormErrorMessage gridColumn="2" mt="-10px">
              {fieldValidation && fieldValidation['email'].error}
            </FormErrorMessage>
          </Grid>
        </FormControl>

        <FormControl id="role" aria-label="role-control">
          <Grid
            templateColumns={{
              base: '1fr',
              md: '1fr 2fr',
            }}
            gap={4}
            alignItems="center"
            width={{
              base: '100%',
              md: '70%',
            }}
          >
            <FormLabel
              htmlFor="role"
              mb="0"
              textAlign={{
                base: 'start',
                md: 'end',
              }}
              color={labelColor}
            >
              Role
            </FormLabel>
            <Select
              focusBorderColor="brand.700"
              chakraStyles={chakraStyles}
              options={roles}
              isMulti
              onChange={handleRoles}
              value={role}
              colorScheme="blue"
              getOptionLabel={(option) => `${option.label}`}
              getOptionValue={(option) => option.value}
              placeholder="Select a role"
            />
          </Grid>
        </FormControl>

        <FormControl id="admin-control" aria-label="admin-control">
          <Grid
            templateColumns={{
              base: '1fr',
              md: '1fr 2fr',
            }}
            gap={4}
            alignItems="center"
            width={{
              base: '100%',
              md: '70%',
            }}
          >
            <FormLabel
              id="admin"
              mb="0"
              textAlign={{
                base: 'start',
                md: 'end',
              }}
              color={labelColor}
            >
              Admin
            </FormLabel>
            <RadioGroup aria-labelledby="admin" onChange={handleAdmin} value={admin.toString()} defaultValue="false">
              <Stack direction="row">
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Stack>
            </RadioGroup>
          </Grid>
        </FormControl>

        <FormControl id="username" aria-label="username-control" isInvalid={fieldValidation && !fieldValidation['username'].valid}>
          <Grid
            templateColumns={{
              base: '1fr',
              md: '1fr 2fr',
            }}
            gap={4}
            alignItems="center"
            width={{
              base: '100%',
              md: '70%',
            }}
          >
            <FormLabel
              htmlFor="username"
              mb="0"
              textAlign={{
                base: 'start',
                md: 'end',
              }}
              color={labelColor}
            >
              Username
            </FormLabel>
            <Input id="username" name="username" type="text" value={username} onChange={handleUsername} isDisabled={!isNew} />
            <FormErrorMessage gridColumn="2" mt="-10px">
              {fieldValidation && fieldValidation['username'].error}
            </FormErrorMessage>
          </Grid>
        </FormControl>
        {isNew && (
          <FormControl
            id="password"
            aria-label="password-control"
            isInvalid={typingPassword && !passwordValidation && fieldValidation && !fieldValidation['password'].valid}
          >
            <Grid
              templateColumns={{
                base: '1fr',
                md: '1fr 2fr',
              }}
              gap={4}
              alignItems="center"
              width={{
                base: '100%',
                md: '70%',
              }}
            >
              <FormLabel
                htmlFor="password"
                mb="0"
                textAlign={{
                  base: 'start',
                  md: 'end',
                }}
                color={labelColor}
              >
                Password
              </FormLabel>

              <PasswordInput label="password" value={password} handleChange={handlePassword} autoComplete="new-password" />
              <FormErrorMessage gridColumn="2" mt="-10px">
                {fieldValidation && fieldValidation['password'].error}
              </FormErrorMessage>
              {showComplexityRules && (
                <ComplexityRules rulesData={rulesData} icons={icons} styleProps={{ marginTop: '-8px', marginLeft: '8px', gridColumn: '2' }} />
              )}
            </Grid>
          </FormControl>
        )}

        {!isNew && (
          <Grid
            templateColumns={{
              base: '1fr',
              md: '1fr 2fr',
            }}
            gap={4}
            alignItems="center"
            width={{
              base: '100%',
              md: '70%',
            }}
          >
            <FormLabel mb="0"></FormLabel>
            <Button variant="cyan" size="sm" onClick={changePasswordModal.onOpen}>
              Change Password
            </Button>
          </Grid>
        )}

        <HStack gap={4} mt={4}>
          <Button mt={4} variant="purple" isDisabled={submitButtonDisabled} type="submit">
            {isNew ? 'Submit' : 'Save'}
          </Button>
        </HStack>
      </VStack>
      {!isNew && (
        <ChangePasswordModal
          onClose={changePasswordModal.onClose}
          isOpen={changePasswordModal.isOpen}
          onOpen={changePasswordModal.onOpen}
          onSubmit={handleChangePassword}
          request={{
            isLoading: updateAuthUserPasswordLoading,
            isError: updateAuthUserPasswordError,
            error: updateAuthUserPasswordErrorMsg,
            isSuccess: updateAuthUserPasswordSuccess,
            reset: updateAuthUserPasswordReset,
          }}
        />
      )}
    </form>
  );
};

NewUserForm.propTypes = {
  user: PropTypes.object,
  isNew: PropTypes.bool,
  onSave: PropTypes.func,
};

export default NewUserForm;
