import React, { useMemo, useState } from 'react';
import { Box, Flex, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react';
import Mappings from '@/features/mapping/Mappings';
import {
  useAddSpecialtyMappingMutation,
  useGetSpecialtyMappingsQuery,
  useModalitiesQuery,
  useRemoveSpecialtyMappingMutation,
  useUpdateSpecialtyMappingMutation,
} from '@/app/services/nucleus';
import { useNotification } from '@/hooks/useNotification';
import { usePagination } from '@/hooks/usePagination';
import { createColumnHelper } from '@tanstack/react-table';
import ModalComponent from '@/features/common/ModalComponent';
import { FaUserEdit } from 'react-icons/fa';
import AlertComponent from '@/features/common/AlertComponent';
import { createMappingColumns } from '@/features/mapping/mappingHelper';
import { useRole } from '@/hooks/useRole';

const SpecialtyMapping = () => {
  useRole('jobs');
  const removeMappingModal = useDisclosure();
  const columnHelper = createColumnHelper();
  const [searchText, setSearchText] = useState('');
  const [source, setSource] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentMapping, setCurrentMapping] = useState(null);
  const { limit, onPaginationChange, pagination: paginationState, offset } = usePagination();

  const { isLoading: isModalitiesLoading, isError: isModalitiesError, error: modalitiesError } = useModalitiesQuery();

  const {
    data: specialtyMappings,
    isLoading: isMappingsLoading,
    isError: isMappingsError,
    error: mappingsErrorMsg,
  } = useGetSpecialtyMappingsQuery({ offset, limit, search: searchText, source: source?.label });

  const [
    removeSpecialtyMapping,
    {
      isLoading: removeMappingLoading,
      isSuccess: removeMappingSuccess,
      isError: removeMappingError,
      error: removeMappingErrorMsg,
      reset: removeMappingReset,
    },
  ] = useRemoveSpecialtyMappingMutation();

  const [
    addSpecialtyMapping,
    { isLoading: addMappingLoading, isSuccess: addMappingSuccess, isError: addMappingError, error: addMappingErrorMsg, reset: addMappingReset },
  ] = useAddSpecialtyMappingMutation();

  const [
    updateSpecialtyMapping,
    {
      isLoading: updateFacilityMappingLoading,
      isSuccess: updateFacilityMappingSuccess,
      isError: updateFacilityMappingError,
      error: updateFacilityMappingErrorMsg,
      reset: updateFacilityMappingReset,
    },
  ] = useUpdateSpecialtyMappingMutation();

  useNotification(updateFacilityMappingSuccess, updateFacilityMappingReset, updateFacilityMappingSuccess, 'success', 'Specialty Mapping Updated');
  useNotification(removeMappingSuccess, removeMappingReset, removeMappingSuccess, 'success', 'Specialty Mapping Removed');
  useNotification(addMappingSuccess, addMappingReset, addMappingSuccess, 'success', 'Specialty Mapping Added');

  const removeMapping = () => {
    removeSpecialtyMapping({ id: currentMapping?.id })
      .unwrap()
      .then((payload) => {
        if (payload) {
          removeMappingModal.onClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFilterChange = (selectOptions) => {
    setSource(selectOptions || []);
  };

  const handleCurrentMapping = (mapping) => {
    setCurrentMapping(mapping);
  };

  const handleEditSpecialtyMapping = (e, row, column, table) => {
    setCurrentMapping(row?.original);
    setIsEditing(true);
    table.options.meta?.addRowColor(row.index, 'yellow.50');
  };

  const handleRemoveSpecialtyMapping = (e, row) => {
    setCurrentMapping(row?.original);
    setIsEditing(false);
    removeMappingModal.onOpen();
  };

  const handleConfirmEdit = (row, column, table) => {
    updateSpecialtyMapping({ id: currentMapping?.id, mapFrom: row?.original?.map_from })
      .unwrap()
      .then((payload) => {
        if (payload) {
          resetEditMode(row, column, table);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetEditMode = (row, column, table) => {
    setCurrentMapping(null);
    setIsEditing(false);
    table.options.meta?.addRowColor(row.index, null);
  };

  const handleCancelEdit = (row, column, table) => {
    resetEditMode(row, column, table);
    table.options.meta?.resetRowData(row.index);
  };

  const columns = useMemo(() => {
    return createMappingColumns({
      helper: columnHelper,
      currentMapping,
      handleActionsCell: (row) => currentMapping?.id === row?.original?.id && isEditing,
      handleMapToCell: (info) => info.getValue()?.short_name,
      isEditing,
      onSave: handleConfirmEdit,
      onCancel: handleCancelEdit,
      onEdit: handleEditSpecialtyMapping,
      onRemove: handleRemoveSpecialtyMapping,
    });
  }, [currentMapping, specialtyMappings?.items]);

  return isModalitiesLoading ? (
    <Flex alignItems="center" justifyContent="center" py={200}>
      <Spinner color="brand.900" size="lg" />
    </Flex>
  ) : isModalitiesError ? (
    <AlertComponent status="warning" title="Error fetching modalities" description={modalitiesError?.data?.detail} />
  ) : (
    <Box>
      <Mappings
        handleCurrentMapping={handleCurrentMapping}
        type="Specialty"
        searchText={searchText}
        handleSearchTextChange={setSearchText}
        columns={columns}
        mappings={specialtyMappings}
        mappingsState={{
          isLoading: isMappingsLoading,
          isError: isMappingsError,
          error: mappingsErrorMsg,
        }}
        add={addSpecialtyMapping}
        addState={{ isLoading: addMappingLoading, isError: addMappingError, error: addMappingErrorMsg }}
        update={updateSpecialtyMapping}
        updateState={{ isLoading: updateFacilityMappingLoading, isError: updateFacilityMappingError, error: updateFacilityMappingErrorMsg }}
        pagination={paginationState}
        limit={limit}
        onPaginationChange={onPaginationChange}
        source={source}
        onFilterChange={handleFilterChange}
      />
      <ModalComponent
        icon={<FaUserEdit />}
        size="sm"
        title="Remove Specialty Mapping"
        primaryText="Yes"
        secondaryText="No"
        handleConfirm={removeMapping}
        onOpen={removeMappingModal.onOpen}
        isOpen={removeMappingModal.isOpen}
        onClose={removeMappingModal.onClose}
        isError={false}
      >
        <Stack spacing={3}>
          {removeMappingLoading ? (
            <Flex alignItems="center" justifyContent="center">
              <Spinner color="brand.900" size="lg" />
            </Flex>
          ) : (
            <Box>
              <Text>Are you sure you want to remove this specialty mapping?</Text>
              {removeMappingError && (
                <AlertComponent status="error" title="Error removing facility mapping" description={removeMappingErrorMsg?.data?.detail} />
              )}
            </Box>
          )}
        </Stack>
      </ModalComponent>
    </Box>
  );
};
export default SpecialtyMapping;
