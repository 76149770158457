import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createColumnHelper } from '@tanstack/react-table';
import { selectLoginStatus } from '../login/loginSlice';
import { Button, Heading, Badge, Tooltip, Box, useDisclosure, Spinner } from '@chakra-ui/react';
import { LOGGED_IN, TOKEN_RECEIVED, LOADING, ERROR, ARCHIVED, DETAILS } from '@/utils/constants';
import {
  selectContractId,
  selectContractSearchStatus,
  selectContractSortBy,
  selectContractSortDirection,
  setContractId,
  setContractSearchStatus,
  setContractSortBy,
  setContractSortDirection,
} from './contractSlice';
import { BsPlus } from 'react-icons/bs';
import { selectFacilityStatus } from '../facility/facilitySlice';
import { selectUsersStatus } from '../user/userSlice';
import { useCloneContractMutation, useContractsQuery, useLazyFilterContractsQuery } from '@/app/services/nucleus';
import { useEditContracts } from '@/hooks/useEditContracts';
import { clearContractData } from './contractActions';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import { isArrayEmpty, isArrayValue, isRealValue } from '@/utils/helpers';
import { handleStatusColor, searchColumns, statusOptions } from '@/features/contract/contractHelper';
import DataTableSearchAndFiltering from '../common/table/DataTableSearchAndFiltering';
import TableButton from '@/features/common/table/TableButton';
import { FaRegCopy } from 'react-icons/fa';
import { setNotificationLogMessage } from '@/features/notification/notificationSlice';
import { useNotification } from '@/hooks/useNotification';
import CopyContractModal from '@/features/contract/CopyContractModal';
import LoadingWrapper from '@/features/common/motion/LoadingWrapper.jsx';

const ContractsPage = () => {
  const skipContracts = useRef(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlChange = useLocation();
  const editable = useEditContracts();
  const cloneContractModal = useDisclosure();
  const loginStatus = useSelector(selectLoginStatus);
  const searchStatus = useSelector(selectContractSearchStatus);
  const facilityStatus = useSelector(selectFacilityStatus);
  const usersStatus = useSelector(selectUsersStatus);
  const sortBy = useSelector(selectContractSortBy);
  const sortDirection = useSelector(selectContractSortDirection);
  const copyContractId = useSelector(selectContractId);

  const [sourceContract, setSourceContract] = useState(null);
  const [contractData, setContractData] = useState(null);
  const [statusValue, setStatusValue] = useSessionStorage('contract_status', null);

  const [advFilterCache, setAdvFilterCache] = useSessionStorage('adv_contract_filters', null);

  const [clone, { isLoading: cloneLoading, isSuccess: cloneSuccess, isError: cloneError, error: cloneErrorMsg }] = useCloneContractMutation();

  useEffect(() => {
    // when the page loads, skip loading contracts until we can determine if filters are stored in session storage
    // if there are no filters, we set our skip to false so that contracts will load
    if (skipContracts.current && !isRealValue(advFilterCache)) {
      skipContracts.current = false;
    } else if (skipContracts.current && Object.values(advFilterCache).every((v) => v.value === null)) {
      skipContracts.current = false;
    }
  }, [advFilterCache]);

  useEffect(() => {
    if (isRealValue(statusValue)) {
      setStatusValue(statusValue);
    } else {
      setStatusValue(null);
    }
  }, []);

  // TODO: normalize contracts in redux extraReducer for use in copy contracts
  // https://redux.js.org/tutorials/essentials/part-6-performance-normalization#normalizing-data
  const {
    data: contracts,
    isFetching: isContractsFetching,
    isSuccess: isContractsSuccess,
    isError: isContractsError,
    error: contractsError,
  } = useContractsQuery({ skip: skipContracts.current });

  const [filterContracts, { isFetching: isFilterFetching, isError: isFilterError, error: filterError }] = useLazyFilterContractsQuery();

  useNotification(cloneSuccess, cloneContractModal.onClose, cloneSuccess, 'success', 'Contract copied');

  const handleContractClick = (e, info) => {
    const id = info.row.original.id;
    dispatch(setContractId(id));
    navigate(`/contracts/${id}/${DETAILS}`, { state: { from: urlChange } });
  };

  const handleButtonDisabled = () => {
    return searchStatus === ERROR || facilityStatus === ERROR || usersStatus === ERROR;
  };

  const getContractStatus = (row, status) => {
    return row?.is_deleted ? ARCHIVED : status;
  };
  const getContractStatusBadge = (row, status) => {
    const contractStatus = getContractStatus(row, status);
    return (
      <Badge borderRadius={4} colorScheme={handleStatusColor(contractStatus)}>
        {contractStatus}
      </Badge>
    );
  };

  const filterContractsByStatus = (status, contracts, data) => {
    if ((status && status.length === 0) || !isRealValue(contracts) || contracts?.length === 0) {
      return contracts || [];
    }
    const filteredFinalResults = isArrayValue(status)
      ? data.filter((result) => {
          let task = false;
          const statuses = status.reduce((prev, cur) => [...prev, cur.value], []);
          // NOTE: 'archived' is not a real status so we check is_deleted
          for (const status of statuses) {
            if (task === true) break;
            if (status === 'archived') {
              task = result.is_deleted;
            } else {
              task = status === result.status.toLowerCase() && !result.is_deleted;
            }
          }
          return task;
        })
      : [];
    // if there are no results and no status, return all contracts
    if (filteredFinalResults.length === 0 && isArrayEmpty(status)) {
      return contracts;
    } else {
      return filteredFinalResults;
    }
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <Button isDisabled={handleButtonDisabled()} variant="link" onClick={(e) => handleContractClick(e, info)}>
          {info.getValue()}
        </Button>
      ),
      header: 'Contract Name',
    }),
    columnHelper.accessor('status', {
      cell: (info) => getContractStatusBadge(info.row.original, info.getValue()),
      header: 'Status',
      sortingFn: (a, b, columnId) => {
        const statusA = getContractStatus(a?.original, a.getValue(columnId));
        const statusB = getContractStatus(b?.original, b.getValue(columnId));
        return String(statusA).localeCompare(String(statusB));
      },
    }),
    columnHelper.accessor('published_status', {
      cell: (info) => !info.row.original.is_deleted && info.getValue(),
      header: 'Published Status',
    }),
    columnHelper.accessor('source', {
      cell: (info) => info.getValue(),
      header: 'Source',
    }),
    columnHelper.accessor('starts_at', {
      cell: (info) => info.getValue(),
      header: 'Effective Date',
    }),
    columnHelper.accessor('', {
      cell: (info) => {
        return (
          <Tooltip hasArrow placement="top" label="Copy">
            <Box p="0" as="span">
              <TableButton isDisabled={!editable} label={'copy'} aria-label="copy" icon={<FaRegCopy />} onClick={(e) => stageCopyContract(e, info)} />
            </Box>
          </Tooltip>
        );
      },
      header: 'Action',
    }),
  ];

  const stageCopyContract = (e, info) => {
    setSourceContract(info?.row?.original);
    dispatch(setContractId(info?.row?.original?.id));
    cloneContractModal.onOpen();
  };

  const handleCopyContract = (e) => {
    if (isRealValue(copyContractId)) {
      clone({ contractId: copyContractId })
        .unwrap()
        .then((payload) => {
          dispatch(setContractId(payload.id));
          navigate(`/contracts/${payload.id}/${DETAILS}`, { state: { from: urlChange } });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch(setNotificationLogMessage({ status: 'error', msg: 'No contract selected' }));
    }
  };

  const handleContractsSortBy = (column) => {
    dispatch(setContractSortBy(column));
  };

  const handleContractsSortDirection = (direction) => {
    dispatch(setContractSortDirection(direction));
  };

  const handleContractsSearchStatus = (status) => {
    dispatch(setContractSearchStatus(status));
  };

  const handleAdvancedFilterSaved = async (filters) => {
    // make api call here - copy stuff over
    try {
      let result = [];
      if (filters === null) {
        result = contracts;
      } else {
        const filtered = await filterContracts(filters).unwrap();
        if (isArrayValue(filtered?.items)) {
          result = filtered.items;
        }
      }
      setContractData(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isRealValue(contracts) && skipContracts.current === false) {
      setContractData(contracts);
    }
  }, [contracts]);

  const handleNewContract = (event) => {
    event.preventDefault();
    dispatch(clearContractData());
    navigate('/contracts/new', { state: { from: urlChange } });
  };

  return (
    (loginStatus === LOGGED_IN || loginStatus === TOKEN_RECEIVED) && (
      <Box position="relative">
        <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
          Contracts
        </Heading>
        {(isContractsFetching || isFilterFetching) && (
          <LoadingWrapper height={'100vh'} alignItems="center" position="absolute" bg="rgba(255,255,255,0.5)" mt={20}>
            <Spinner />
          </LoadingWrapper>
        )}
        <DataTableSearchAndFiltering
          title="Contracts"
          editable={editable}
          includeAdvancedContractFilters={true}
          originalData={contractData}
          filterValue={statusValue}
          filterLabelStyle={{
            fontWeight: 'bold',
            fontSize: '0.75rem',
            textTransform: 'uppercase',
          }}
          handleFilterValue={setStatusValue}
          filterFunction={filterContractsByStatus}
          filterOptions={statusOptions}
          filterPlaceholder={'Filter by Status'}
          searchStatus={searchStatus}
          isSearchLoading={(searchStatus === LOADING || facilityStatus === LOADING || usersStatus === LOADING) && isContractsSuccess}
          isSearchError={searchStatus === ERROR}
          isDataLoading={isContractsFetching || isFilterFetching}
          isDataError={isContractsError || isFilterError}
          dataErrorMsg={contractsError?.data?.detail || filterError?.data?.detail}
          dataErrorTitle={'Error fetching contracts'}
          dataColumns={columns}
          searchColumns={searchColumns}
          sortBy={sortBy}
          sortDirection={sortDirection}
          handleSortBy={handleContractsSortBy}
          handleSortDirection={handleContractsSortDirection}
          handleSearchStatus={handleContractsSearchStatus}
          onAdvFilterSaved={handleAdvancedFilterSaved}
        >
          <Button leftIcon={<BsPlus />} onClick={handleNewContract} isDisabled={!editable} variant="purple">
            New
          </Button>
        </DataTableSearchAndFiltering>
        <CopyContractModal
          source={sourceContract}
          onConfirm={handleCopyContract}
          modal={cloneContractModal}
          isContractsLoading={isContractsFetching}
          isCloneLoading={cloneLoading}
          cloneError={cloneError}
          contractError={contractsError}
          cloneErrorMsg={cloneErrorMsg}
          contractErrorMsg={contractsError}
        >
          {getContractStatusBadge(sourceContract, sourceContract?.status)}
        </CopyContractModal>
      </Box>
    )
  );
};

export default ContractsPage;
