import { appsignal } from '@/appsignal';

export const truncateText = (text, len) => {
  return text.length > len ? `${text.substring(0, len)}...` : text;
};

export const getIndexById = (array, value) => array.findIndex((element) => element?.id === value?.id);

// INFO: items in A but not in B
export const not = (a, b, compare) => {
  return a.filter((value) => compare(b, value) === -1);
};

// INFO: items in A and in B
export const intersection = (a, b, compare) => {
  return a.filter((value) => compare(b, value) !== -1);
};

// INFO: items in A or B but not in both
export const union = (a, b, compare) => {
  return [...a, ...not(b, a, compare)];
};
export const logError = (error) => {
  const isTest = navigator.userAgent.includes('jsdom');
  if (!isTest) {
    appsignal
      .wrap(() => {
        throw new Error(error);
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
    console.error(error);
  }
};

export const parseErrorForLogging = (errors) => {
  let result = '';
  if (errors[0]?.msg && errors[0]?.type) {
    let loc = '';
    let msg = '';
    for (const err of errors) {
      if (isArrayValue(err.loc)) {
        loc = err.loc.join(' > ');
      }
      msg = `msg: ${err?.msg}; type: ${err?.type}`;
      result += `field: ${loc}; ${msg}\n`;
    }
  } else {
    for (const err of errors) {
      if (err?.msg) {
        result += `${err.msg}\n`;
      } else {
        result += `${err}\n`;
      }
    }
  }
  return result;
};

export const parseErrorForToast = (result) => {
  let state = {};
  const msg = result?.error?.message;
  const detail = result?.payload?.data?.detail;
  const error = result?.payload?.error;
  if (msg && detail) {
    if (Array.isArray(detail)) {
      const loc = detail[0]?.loc;
      let query = Array.isArray(loc) ? loc.join(', ') : '';
      state.logMessage = query + ' ' + detail[0]?.msg;
    } else {
      state.logMessage = detail;
    }
    state.logTitle = msg;
    state.toastStatus = 'error';
  } else if (msg && error) {
    state.logTitle = msg;
    state.logMessage = error;
    state.toastStatus = 'error';
  } else {
    state.logTitle = 'Error';
    state.logMessage = 'Pact service is not available.';
    state.toastStatus = 'error';
  }
  return state;
};

export const parseNucleusError = (payload, type) => {
  let msg = '';
  if (payload?.status === 404 || payload?.status === 400) {
    const detail = payload?.data?.detail;
    msg = detail || `${type} not found.`;
  } else if (payload?.status === 422) {
    const detail = payload?.data?.detail;
    if (Array.isArray(detail)) {
      msg = detail[0]?.type + detail[0]?.msg;
    } else {
      msg = detail?.type + detail?.msg;
    }
  } else if (payload?.error !== '' || payload?.data?.error !== '') {
    const err = payload?.error ? payload.error : payload?.data?.error;
    msg = err;
  }
  return msg;
};

export const isRealValue = (obj) => {
  return obj !== null && typeof obj !== 'undefined';
};

export const isArrayValue = (arr) => {
  return Array.isArray(arr) && arr.length > 0;
};

export const isArrayEmpty = (arr) => {
  return Array.isArray(arr) && arr.length === 0;
};

export const isIterable = (obj) => {
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
};

export const dedupeObjects = (data) => {
  return data.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      data.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
};

export const compareValues = (key, dir) => {
  const sortOrder = dir === 'asc' ? 1 : -1;
  return function innerSort(a, b) {
    const aVal = typeof a[key] === 'string' ? a[key].toLowerCase() : '';
    const bVal = typeof b[key] === 'string' ? b[key].toLowerCase() : '';
    const negate = aVal > bVal ? 1 : 0;
    const result = aVal < bVal ? -1 : negate;
    return result * sortOrder;
  };
};

export const toPactPercent = (num) => {
  return parseFloat(num * 100).toFixed(4);
};

export const toNucleusPercent = (num) => {
  return parseFloat(num) / 100;
};

const getJobType = (obj) => {
  let type = obj?.type;
  if (isArrayValue(obj?.allOf) || obj?.enum) {
    type = 'enum';
  }
  return type;
};

export const parseJobProperties = (job) => {
  const props = job?.properties;
  if (props) {
    const jobMap = Object.keys(props).reduce((result, key) => {
      let hasChild = false;
      let parent = null;
      if ('properties' in props[key] || ('items' in props[key] && props[key]?.items?.properties)) {
        hasChild = true;
        parent = job?.title !== 'JobFromDB' ? job?.title : null;
        const obj = 'items' in props[key] ? props[key]?.items : props[key];
        const childJobType = getJobType(obj);
        let childProps = {
          value: key,
          type: props[key]?.type,
          items: parseJobProperties(obj),
        };
        if (childJobType === 'enum') {
          // NOTE: allOf is always an array of 1 item so far
          // i'm not sure what we want to do if it were multiple items
          childProps = {
            ...childProps,
            enum: obj.enum || obj?.allOf[0]?.enum,
          };
        }
        if (childJobType !== 'array' && props[key]?.type !== 'array') {
          result.push(childProps);
        }
      }
      const topLevelJobType = getJobType(props[key]);
      let jobProps = {
        value: key,
        type: topLevelJobType,
      };
      if (topLevelJobType === 'enum') {
        jobProps = {
          ...jobProps,
          enum: props[key].enum || props[key]?.allOf[0]?.enum,
        };
      }
      if (topLevelJobType !== 'array' && !hasChild) {
        result.push(jobProps);
      }
      return result;
    }, []);
    return jobMap;
  }
};
