import { useGetCertificationsQuery } from '@/app/services/nucleus';
import { useRole } from '@/hooks/useRole';
import { Button, Heading } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import DataTableSearchAndFiltering from '../common/table/DataTableSearchAndFiltering';
import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCertificationSearchStatus,
  selectCertificationSortBy,
  selectCertificationSortDirection,
  setSearchStatus,
  setSortBy,
  setSortDirection,
} from './certificationsSlice';
import { ERROR, LOADING } from '@/utils/constants';
import { BsPlus } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const CertificationsPage = () => {
  useRole('support');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchStatus = useSelector(selectCertificationSearchStatus);
  const sortBy = useSelector(selectCertificationSortBy);
  const sortDirection = useSelector(selectCertificationSortDirection);

  const columnHelper = createColumnHelper();

  const { data: certifications, isLoading, isError, error: certificationsError } = useGetCertificationsQuery();

  const certificationsColumns = [
    columnHelper.accessor('name', {
      header: 'Name',
    }),
    columnHelper.accessor('description', {
      header: 'Description',
    }),
  ];

  const handleCertificationsSortBy = (column) => {
    dispatch(setSortBy(column));
  };

  const handleCertificationsSortDirection = (direction) => {
    dispatch(setSortDirection(direction));
  };

  const handleCertificationsSearchStatus = (status) => {
    dispatch(setSearchStatus(status));
  };

  const handleNewCertification = (e) => {
    e.preventDefault();
    navigate('/certifications/new');
  };

  return (
    <React.Fragment>
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        Certifications
      </Heading>
      <DataTableSearchAndFiltering
        title="Certifications"
        originalData={certifications}
        searchStatus={searchStatus}
        isSearchLoading={searchStatus === LOADING}
        isSearchError={searchStatus === ERROR}
        isDataLoading={isLoading}
        isDataError={isError || certificationsError}
        dataErrorMsg={certificationsError?.data?.detail}
        dataErrorTitle="Error fetching certifications"
        dataColumns={certificationsColumns}
        searchColumns={['name']}
        sortBy={sortBy}
        sortDirection={sortDirection}
        handleSortBy={handleCertificationsSortBy}
        handleSortDirection={handleCertificationsSortDirection}
        handleSearchStatus={handleCertificationsSearchStatus}
      >
        <Button leftIcon={<BsPlus />} onClick={handleNewCertification} variant="purple" type="submit">
          New
        </Button>
      </DataTableSearchAndFiltering>
    </React.Fragment>
  );
};

export default CertificationsPage;
