import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import { Box, Button, FormControl, InputGroup, InputLeftElement, Input, InputRightAddon } from '@chakra-ui/react';
import { FiFile } from 'react-icons/fi';
import { createColumnHelper } from '@tanstack/react-table';
import TruncatedTextComponent from '@/features/common/TruncatedTextComponent';
import { useNotification } from '@/hooks/useNotification';

const FileUpload = ({ fileLoadedEvent, handleUpload, uploadSuccess, uploadError, errorMessage, isReset, isDisabled, handleClear }) => {
  const inputRef = useRef();
  const columnHelper = createColumnHelper();
  const [file, setFile] = useState(null);
  const errorMsgRef = useRef(errorMessage?.data?.detail);

  // NOTE: use ref to allow access to upload error messages
  useEffect(() => {
    errorMsgRef.current = errorMessage?.data?.detail;
  }, [errorMessage]);

  const handleTableData = (key, value, info) => {
    const thisRow = Array.isArray(errorMsgRef.current) ? errorMsgRef.current.filter((err) => err.loc.includes(info.row.index)) : null;
    const thisVal = Array.isArray(thisRow) ? thisRow.filter((val) => val.loc.includes(key)) : [];
    if (thisVal.length > 0) {
      return (
        <Box bgColor="#FFF4F4" height="100%" width="100%" display="flex" alignItems="center">
          <Box verticalAlign="middle">{value || thisVal[0].msg}</Box>
        </Box>
      );
    } else if (value && value.length > 30) {
      return <TruncatedTextComponent length={30} title={key} text={value} />;
    }
    return value;
  };

  useEffect(() => {
    if (isDisabled && file !== null) {
      handleClearCSV({ target: { files: null } });
    }
  }, [isDisabled]);

  useNotification(uploadSuccess, null, uploadSuccess, 'success', 'Jobs uploaded successfully!');

  useEffect(() => {
    if (uploadSuccess) {
      handleClearCSV({ target: { files: null } });
    }
  }, [uploadSuccess, uploadError]);

  useEffect(() => {
    if (isReset) {
      handleClearCSV({ target: { files: null } });
    }
  }, [isReset]);

  const handleClearCSV = (e) => {
    inputRef.current.value = null;
    handleFileChange(e);
    handleClear();
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      let contents = '';
      let jsonData = {};
      let cols = [];
      // WARN: event handler callbacks run outside of React's scope
      // the method 'handleTableData' must use a ref when accessing anything from state
      reader.onload = (event) => {
        contents = event.target.result.trim();
        jsonData = Papa.parse(contents, { header: true });
        const fieldVals = jsonData?.meta?.fields || [];
        const srcNoteIndex = fieldVals.indexOf('source_notes');
        let fields = [];
        if (srcNoteIndex !== -1) {
          fields = [...fieldVals.slice(0, srcNoteIndex), 'source', ...fieldVals.slice(srcNoteIndex)];
        } else {
          fields = [...fieldVals, 'source'];
        }
        if (fields && fields.length > 0) {
          cols = fields.map((key) => {
            return columnHelper.accessor(key, {
              cell: (info) => handleTableData(key, info.getValue(), info),
              header: key,
              maxSize: 150,
            });
          });
        }
        typeof fileLoadedEvent === 'function' && fileLoadedEvent(cols, jsonData);
      };
      reader.readAsText(file);
      setFile(file);
    } else {
      setFile(null);
    }
  };

  return (
    <FormControl>
      <InputGroup maxW={'50%'}>
        <InputLeftElement pointerEvents="none">
          <FiFile />
        </InputLeftElement>
        <input id="file" aria-label="choose-file" type="file" accept=".csv" onChange={handleFileChange} ref={inputRef} style={{ display: 'none' }} />
        <Input
          aria-label="facade-input"
          placeholder={file?.name ? file.name : 'Choose file...'}
          onClick={() => inputRef.current.click()}
          isDisabled={isDisabled}
        />
        {file && !uploadError && (
          <InputRightAddon>
            <Button
              variant="ghost"
              onClick={handleUpload}
              size={'sm'}
              _hover={{ color: 'gray.500' }}
              isDisabled={isDisabled}
              _active={{ backgroundColor: 'transparent' }}
            >
              Upload
            </Button>
          </InputRightAddon>
        )}
      </InputGroup>
    </FormControl>
  );
};

FileUpload.propTypes = {
  fileLoadedEvent: PropTypes.func,
  handleUpload: PropTypes.func,
  uploadSuccess: PropTypes.bool,
  uploadError: PropTypes.bool,
  errorMessage: PropTypes.object,
  isReset: PropTypes.bool,
  isDisabled: PropTypes.bool,
  handleClear: PropTypes.func,
};

export default FileUpload;
