import { Box, Flex, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Layout from '../common/Layout';
import AlertComponent from '../common/AlertComponent';
import { Outlet } from 'react-router-dom';
import { useModalitiesQuery } from '@/app/services/nucleus';

const Modalities = () => {
  const [errorTitle, setErrorTitle] = useState('');
  const [errorDesc, setErrorDesc] = useState('');
  const { isError, error: modalitiesError, isLoading } = useModalitiesQuery();

  useEffect(() => {
    if (isError) {
      setErrorTitle('Modalities Error');
      setErrorDesc(modalitiesError?.data?.detail);
    }
  }, [isError]);

  return (
    <Box data-test-id="pact-users">
      <Layout pageTitle={'Pact'}>
        {isLoading ? (
          <Flex alignItems="center" justifyContent="center" py={200}>
            <Spinner color="brand.900" size="lg" />
          </Flex>
        ) : isError ? (
          <AlertComponent status="warning" title={errorTitle} description={errorDesc} />
        ) : (
          <Outlet />
        )}
      </Layout>
    </Box>
  );
};

export default Modalities;
