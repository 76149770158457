import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AsyncSelect, Select, createFilter, components } from 'chakra-react-select';
import { HStack, FormErrorMessage, FormControl } from '@chakra-ui/react';
import { chakraStyles } from '../common/select/styles';
import { ClearIndicator } from '../common/select/ClearIndicator';
import { OptionNameCityAddress } from '../common/select/Option';
import { SingleValueLabel } from '../common/select/SingleValue';
import { useLazySearchCompaniesQuery } from '@/app/services/nucleus';
import { selectStateList } from '../enum/enumSlice';

const FacilitySearchInput = (props) => {
  const { isHidden, ...rest } = props;
  return <components.Input {...rest} isHidden={false} />;
};

const FacilitySearch = ({
  id,
  ariaLabel,
  initialRef,
  facilityDisabled,
  facilityPlaceholder,
  facilityMaxWidth,
  onFacilityChange,
  onFacilityInputChange,
  onStateChange,
  facility,
  input,
  state,
  isReset,
  width,
  isInvalid,
  facilitySearchError,
  facilityRequiredError,
}) => {
  const [facilityInput, setFacilityInput] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [localFacility, setLocalFacility] = useState(null);
  const states = useSelector(selectStateList);

  useEffect(() => {
    if (isReset) {
      setSelectedState(null);
      setFacilityInput('');
      setSearchResults(null);
    }
  }, [isReset]);

  useEffect(() => {
    setLocalFacility(facility);
  }, [facility]);

  useEffect(() => {
    setFacilityInput(input);
  }, [input]);

  useEffect(() => {
    setSelectedState(state);
  }, [state]);

  const [companies, { isError: isSearchCompaniesError, error: searchCompaniesError }] = useLazySearchCompaniesQuery();

  const handleStageFacility = (value, { action }) => {
    setLocalFacility(value);
    setFacilityInput(value?.label ?? '');
    typeof onFacilityChange === 'function' && onFacilityChange(value, action);
    if (action === 'clear') {
      setLocalFacility(null);
    }
  };

  const handleInputChange = (inputValue, { action }) => {
    typeof onFacilityInputChange === 'function' && onFacilityInputChange(inputValue, action);
    if (action === 'input-change') {
      setFacilityInput(inputValue);
    }
  };

  const loadOptions = (inputValue, callback, stateValue) => {
    let payload = { search: inputValue };
    if (stateValue?.label) {
      payload = { search: inputValue, state: stateValue.label };
    }
    companies(payload)
      .unwrap()
      .then((payload) => {
        setSearchResults(payload);
        return callback(payload);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStateChange = (value, { action }) => {
    if (action === 'select-option') {
      setSelectedState(value);
      if (facilityInput) {
        loadOptions(facilityInput, () => null, value);
      }
      // clear the facility alert box
      if (localFacility?.label) {
        setFacilityInput('');
        setLocalFacility(null);
      }
    } else if (action === 'clear') {
      setSelectedState(null);
      setFacilityInput('');
      setLocalFacility(null);
    }
    typeof onStateChange === 'function' && onStateChange(value, action, localFacility);
  };

  return (
    <HStack spacing={4} alignItems="start" width={width ? width : 'auto'}>
      <FormControl
        maxW={facilityMaxWidth ? facilityMaxWidth : '500px'}
        isInvalid={isSearchCompaniesError || isInvalid}
        aria-label="facility_search_control"
      >
        <AsyncSelect
          id={id ? id : 'facility_search'}
          defaultOptions={facilityInput !== '' ? searchResults : null}
          isDisabled={facilityDisabled}
          ref={initialRef}
          aria-label={ariaLabel ? ariaLabel : 'facility_search'}
          chakraStyles={{
            dropdownIndicator: (defaultStyles) => ({
              ...defaultStyles,
              display: 'none',
            }),
          }}
          focusBorderColor="brand.700"
          value={localFacility}
          inputValue={facilityInput}
          onChange={handleStageFacility}
          onInputChange={handleInputChange}
          isClearable
          isSearchable
          placeholder={facilityPlaceholder}
          loadOptions={(value, cb) => loadOptions(value, cb, selectedState)}
          options={searchResults}
          noOptionsMessage={() => null}
          filterOption={createFilter({ ignoreAccents: false })}
          components={{
            Input: FacilitySearchInput,
            SingleValue: SingleValueLabel,
            Option: OptionNameCityAddress,
          }}
          getOptionLabel={(option) => `${option.label} ${option.address} ${option.city} ${option.state}`}
          getOptionValue={(option) => option.value}
        />
        <FormErrorMessage aria-label="facility_search_error">
          {facilityRequiredError ? facilityRequiredError : facilitySearchError ? facilitySearchError : searchCompaniesError?.data?.detail}
        </FormErrorMessage>
      </FormControl>
      <FormControl aria-label="facility_search_state_control" maxW={'160px'}>
        <Select
          aria-label="state"
          chakraStyles={chakraStyles}
          focusBorderColor="brand.700"
          options={states}
          isDisabled={facilityDisabled}
          onChange={handleStateChange}
          value={selectedState}
          isClearable
          isSearchable
          placeholder="State"
          components={{ ClearIndicator }}
        />
      </FormControl>
    </HStack>
  );
};
FacilitySearch.propTypes = {
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  initialRef: PropTypes.object,
  facilityDisabled: PropTypes.bool,
  facilityPlaceholder: PropTypes.string,
  facilityMaxWidth: PropTypes.string,
  onFacilityChange: PropTypes.func,
  onFacilityInputChange: PropTypes.func,
  onStateChange: PropTypes.func,
  facility: PropTypes.object,
  state: PropTypes.object,
  isReset: PropTypes.bool,
  isInvalid: PropTypes.bool,
  facilitySearchError: PropTypes.string,
  facilityRequiredError: PropTypes.string,
};
export default FacilitySearch;
