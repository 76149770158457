import { createSlice } from '@reduxjs/toolkit';
import { nucleusApi } from '@/app/services/nucleus';
import { parseErrorForToast } from '@/utils/helpers';

// NOTE: WIP notification slice that may potentially include
// * default notifications
// * history with configurable length
// * blacklist to maintain which notifications persist
// * most recent notification
// * global toast configuration

const initialState = {
  history: [],
  length: 10,
  logMessage: '',
  errors: [],
  logTitle: '',
  toastDismiss: true,
  toastDuration: 3000,
  toastStatus: 'error',
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotificationHistory: (state, { payload }) => {
      if (state.history) {
        state.history.unshift(payload);
      }
    },
    setNotificationLength: (state, { payload }) => {
      state.length = payload;
    },
    setNotificationErrors: (state, { payload }) => {
      state.errors = payload;
    },
    setNotificationLogMessage: (state, { payload }) => {
      const { msg, status, title, dismiss, duration } = payload;
      state.logMessage = msg;
      if (dismiss !== null && dismiss !== 'undefined') {
        state.toastDismiss = dismiss;
      }
      if (status) {
        state.toastStatus = status;
      }
      if (title) {
        state.logTitle = title;
      } else if (status) {
        // if there's no title provided use the status
        let title = status;
        state.logTitle = title.charAt(0).toUpperCase() + title.slice(1);
      }
      if (duration !== null && duration !== 'undefined') {
        state.toastDuration = duration;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(nucleusApi.endpoints.login.matchRejected, (state, result) => {
      let error = parseErrorForToast(result);
      if (error && Object.keys(error).length > 0) {
        state.logTitle = error?.logTitle;
        state.logMessage = error?.logMessage;
        state.toastStatus = error?.toastStatus;
      }
    });
  },
});

export const { addNotificationHistory, setNotificationErrors, setNotificationLogMessage, setNotificationLength } = notificationSlice.actions;

export const selectNotificationLogMessage = (state) => state.notification.logMessage;
export const selectNotificationLogTitle = (state) => state.notification.logTitle;
export const selectNotificationErrors = (state) => state.notification.errors;
export const selectNotificationToastStatus = (state) => state.notification.toastStatus;
export const selectNotificationToastDismiss = (state) => state.notification.toastDismiss;
export const selectNotificationToastDuration = (state) => state.notification.toastDuration;

export default notificationSlice.reducer;
