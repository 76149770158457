import { useGetAuthUserQuery } from '@/app/services/nucleus';
import { Box, Heading, VStack } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import NewUserForm from './NewUserForm';
import { setNotificationLogMessage } from '../notification/notificationSlice';
import AlertComponent from '../common/AlertComponent';
import { BsPersonFillX } from 'react-icons/bs';

const EditUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data: user, isLoading, isError, error: userError } = useGetAuthUserQuery({ id });

  const onSaveHandler = () => {
    dispatch(setNotificationLogMessage({ msg: 'User updated successfully', status: 'success' }));
  };

  return (
    <VStack spacing={4} alignItems={'left'}>
      <Box as="header">
        <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
          Edit User
        </Heading>
      </Box>
      {isError && (
        <AlertComponent
          icon={<BsPersonFillX style={{ height: '24px', width: '24px' }} />}
          status="error"
          title="Error fetching user"
          description={userError?.status === 422 ? 'Not a valid user' : userError?.data?.detail}
        />
      )}
      {!isLoading && <NewUserForm user={user} onSave={onSaveHandler} />}
    </VStack>
  );
};

export default EditUser;
