export const addColorData = (rowIndex, data, value) => {
  return data.map((row, index) => {
    if (index === rowIndex) {
      return {
        ...row,
        color: value,
      };
    }
    return row;
  });
};

export const updateTableData = (rowIndex, columnId, data, value) => {
  return data.map((row, index) => {
    if (index === rowIndex) {
      return {
        ...row,
        [columnId]: value,
      };
    }
    return row;
  });
};
