import PropTypes from 'prop-types';
import { IconButton, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { BsSearch, BsX } from 'react-icons/bs';

const SearchInput = ({ isDisabled, dataType, searchText, onChange, onClear }) => {
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <BsSearch aria-label={`search ${dataType.toLowerCase()}`} />
      </InputLeftElement>
      <Input
        isDisabled={isDisabled}
        type="text"
        aria-label="search-input"
        placeholder="Search"
        focusBorderColor="brand.700"
        onChange={onChange}
        value={searchText}
      />
      {searchText !== '' && (
        <InputRightElement>
          <IconButton onClick={onClear} icon={<BsX size="25px" />} variant="ghost" size="sm" aria-label="clear" />
        </InputRightElement>
      )}
    </InputGroup>
  );
};
SearchInput.propTypes = {
  isDisabled: PropTypes.bool,
  dataType: PropTypes.string,
  searchText: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};
export default SearchInput;
