import { useEffect } from 'react';
import { Box, Heading, VStack } from '@chakra-ui/react';
import ContractEditForm from './ContractEditForm';

const NewContract = () => {
  return (
    <VStack spacing={4} alignItems={'left'}>
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        New Contract
      </Heading>
      <Box>
        <ContractEditForm isNew />
      </Box>
    </VStack>
  );
};

export default NewContract;
