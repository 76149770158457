import { useEffect, useState } from 'react';
import { useRole } from '@/hooks/useRole';
import {
  useModalitiesQuery,
  useGetUsersQuery,
  useJobSourcesQuery,
  useGetMSPsQuery,
  useGetCompaniesQuery,
  useGetHealthSystemsQuery,
} from '@/app/services/nucleus';
import Layout from '../common/Layout';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import AlertComponent from '../common/AlertComponent';

const Contract = () => {
  useRole('contracts');
  const [errorTitle, setErrorTitle] = useState('');
  const [errorDesc, setErrorDesc] = useState('');

  const { isError: isJobError, error: jobError } = useJobSourcesQuery();
  const { isError: getUsersError, error: usersError } = useGetUsersQuery();
  const { isError: getModalitiesError, error: modalitiesError } = useModalitiesQuery();

  const { isError: isMSPsError, error: mspsError } = useGetMSPsQuery();
  const { isError: isCompaniesError, error: companiesError } = useGetCompaniesQuery();
  const { isError: isHealthSystemsError, error: healthSystemsError } = useGetHealthSystemsQuery();

  useEffect(() => {
    if (getUsersError) {
      setErrorTitle('Users Error');
      setErrorDesc(usersError?.data?.detail);
    } else if (getModalitiesError) {
      setErrorTitle('Modalities Error');
      setErrorDesc(modalitiesError?.data?.detail);
    } else if (isJobError) {
      setErrorTitle('Job Sources Error');
      setErrorDesc(jobError?.data?.detail);
    } else if (isCompaniesError) {
      setErrorTitle('Error Loading Companies');
      setErrorDesc(companiesError?.data?.detail);
    } else if (isMSPsError) {
      setErrorTitle("Error Loading MSP's");
      setErrorDesc(mspsError?.data?.detail);
    } else if (isHealthSystemsError) {
      setErrorTitle('Error Loading Health Systems');
      setErrorDesc(healthSystemsError?.data?.detail);
    }
  }, [getModalitiesError, getUsersError, isJobError, isCompaniesError, isMSPsError, isHealthSystemsError]);

  return (
    <Box data-testid="pact-contracts">
      <Layout pageTitle={'Pact'}>
        {getUsersError || getModalitiesError || isJobError || isCompaniesError || isMSPsError || isHealthSystemsError ? (
          <AlertComponent status="warning" title={errorTitle} description={errorDesc} />
        ) : (
          <Outlet />
        )}
      </Layout>
    </Box>
  );
};

export default Contract;
