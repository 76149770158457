import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, useColorModeValue, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import SidebarContent from './SidebarContent';
import TopNav from './TopNav';
import BreadcrumbComponent from './BreadcrumbComponent';
import { useEffect } from 'react';
import { handleLogoutAction } from '@/features/login/loginActions';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginExpiration } from '@/features/login/loginSlice';

const Layout = ({ pageTitle, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const location = useLocation();
  const expiration = useSelector(selectLoginExpiration);
  const bg = useColorModeValue('white', 'gray.900');

  useEffect(() => {
    if (expiration) {
      if (expiration * 1000 < Date.now()) {
        dispatch(handleLogoutAction());
        navigate('/login');
      }
    }
  }, [expiration, location, dispatch, navigate]);

  return (
    <Box minH="100vh" bg={bg}>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer autoFocus={false} isOpen={isOpen} placement="left" onClose={onClose} returnFocusOnClose={false} onOverlayClick={onClose} size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <TopNav onOpen={onOpen} pageTitle={pageTitle} />
      {((id && id !== '') || location.pathname.includes('new')) && <BreadcrumbComponent id={id} />}
      <Box ml={{ base: 0, md: 64 }} p="6" pos="relative">
        {children}
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.node,
};

export default Layout;
