export const triageAscii = () =>
  console.log(
    `%c
   .::--::.  %c:=++++=:                                                                    
%c  :--------=%c***********=                                                                  
%c.---------=%c####%c*********+.     %c.:--:.     :::::      :.       .-        ::::.      :::::
%c----------*%c####%c**********+     %c  :-       =   =:     -:      .+.-      -:          +.   
%c----------*%c####%c**********+     %c  :-       =::+:      -:      +::=-     =. .:*      +:...
%c----------=%c####%c**********+     %c  .:       -   -.     :.     :    :.     :::::      -::::
%c-----------=%c*#%c***********+                                                                
%c----------:: %c.-%c=++++++++++                                                                
`,
    `font-family: monospace; color: #00a3e0;`,
    `font-family: monospace; color: #514689;`,
    `font-family: monospace; color: #00a3e0;`,
    `font-family: monospace; color: #514689;`,
    `font-family: monospace; color: #00a3e0;`,
    `font-family: monospace; color: #005499;`,
    `font-family: monospace; color: #514689;`,
    `font-family: monospace; color: #fdfdfd;`,
    `font-family: monospace; color: #00a3e0;`,
    `font-family: monospace; color: #005499;`,
    `font-family: monospace; color: #514689;`,
    `font-family: monospace; color: #fdfdfd;`,
    `font-family: monospace; color: #00a3e0;`,
    `font-family: monospace; color: #005499;`,
    `font-family: monospace; color: #514689;`,
    `font-family: monospace; color: #fdfdfd;`,
    `font-family: monospace; color: #00a3e0;`,
    `font-family: monospace; color: #005499;`,
    `font-family: monospace; color: #514689;`,
    `font-family: monospace; color: #fdfdfd;`,
    `font-family: monospace; color: #00a3e0;`,
    `font-family: monospace; color: #005499;`,
    `font-family: monospace; color: #514689;`,
    `font-family: monospace; color: #00a3e0;`,
    `font-family: monospace; color: #005499;`,
    `font-family: monospace; color: #514689;`,
  );
