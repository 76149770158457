import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import DataTable from './DataTable';
import AlertComponent from '@/features/common/AlertComponent';
import SearchComponent from '../SearchComponent';
import SearchContainer from '@/features/common/SearchContainer';
import { NO_RESULTS, LOADED, SEARCHING } from '@/utils/constants';
import { searchFunc, WAIT_INTERVAL } from '@/features/contract/contractHelper';
import { isArrayEmpty, isArrayValue } from '@/utils/helpers';
import ContractFilters from '@/features/contract/ContractFilters';
import { BsPlus } from 'react-icons/bs';

const DataTableSearchAndFiltering = ({
  title,
  editable,
  includeAdvancedContractFilters,
  originalData,
  filterOptions,
  filterValue = null,
  filterFunction = null,
  filterLabelStyle = {},
  handleFilterValue = () => {},
  filterPlaceholder = '',
  searchStatus,
  isSearchLoading,
  isSearchError,
  isDataLoading,
  isDataError,
  dataErrorMsg,
  dataErrorTitle,
  dataColumns,
  searchColumns,
  sortBy,
  sortDirection,
  children,
  handleSortBy = () => {},
  handleSortDirection = () => {},
  handleSearchStatus = () => {},
  handleTableLoaded = () => {},
  onAdvFilterSaved = () => {},
}) => {
  const firstTimeLoaded = useRef(false);
  const timerRef = useRef(null);
  const urlChange = useLocation();
  const addFilterModal = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const [contractSearchText, setContractSearchText] = useState('');
  const [finalResults, setFinalResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [dataBackup, setDataBackup] = useState(null);

  const COLUMNS = searchColumns ?? ['name'];

  const handleFilterComplete = useCallback(
    (value) => {
      handleFilterValue(value);
      handleFiltering(value);
    },
    [finalResults],
  );

  useEffect(() => {
    if (!firstTimeLoaded.current && isArrayValue(originalData)) {
      firstTimeLoaded.current = true;
      setDataBackup(originalData);
    }
    // if the user is searching and the original data is changed outside the component
    // we need to make sure search is applied again
    if (contractSearchText !== '' && isArrayValue(originalData)) {
      handleContractsSearch(contractSearchText, originalData);
    } else if (contractSearchText !== '' && isArrayEmpty(originalData)) {
      handleContractsSearch(contractSearchText, dataBackup);
    }
  }, [originalData]);

  const handleSearch = (text) => {
    handleSearchStatus(SEARCHING);
    timerRef && window.clearTimeout(timerRef);
    setContractSearchText(text);
    timerRef.current = setTimeout(() => {
      searchContracts(text);
    }, WAIT_INTERVAL);
  };

  const searchContracts = (val) => {
    if (val) {
      if (originalData?.length > 0) {
        if (val === '') {
          return;
        }
        handleContractsSearch(val, originalData);
      } else {
        handleSearchStatus('');
      }
    } else {
      handleContractsSearch(null, []);
      handleSearchStatus('');
    }
  };

  const handleFiltering = (value) => {
    let rows = originalData;
    // if there is a search text and a value, use the filtered data
    if (contractSearchText !== '' && isArrayValue(value)) {
      rows = searchFunc(contractSearchText, originalData, COLUMNS);
    }
    // if there is search text and no value, use the search data
    if (contractSearchText !== '' && isArrayEmpty(value)) {
      rows = searchFunc(contractSearchText, originalData, COLUMNS);
      if (
        rows.length !== 0 &&
        rows.length !== originalData.length &&
        (searchResults.length !== 0 || (finalResults.length !== 0 && finalResults.length < originalData.length))
      ) {
        // NOTE: calling setSearchResults will trigger a useEffect which could possibly call this function again in an infinite loop
        setSearchResults(rows);
      }
    } else {
      if (typeof filterFunction === 'function') {
        rows = filterFunction(value, originalData, rows);
      }
      setFinalResults(rows);
    }
  };

  useEffect(() => {
    if ((!filterValue || filterValue.length === 0) && searchResults && searchResults.length !== 0 && searchResults.length < originalData.length) {
      setFinalResults(searchResults);
    } else {
      handleFiltering(filterValue || []);
    }
  }, [filterValue, originalData, searchResults]);

  const handleClearSearch = () => {
    setContractSearchText('');
    handleContractsSearch(null, []);
    handleSearchStatus('');
  };

  const handleContractsSearch = (val, data) => {
    let result = data;
    if (isArrayValue(data)) {
      result = searchFunc(val, data, COLUMNS);
      if (result.length === 0) {
        handleSearchStatus(NO_RESULTS);
      } else {
        handleSearchStatus(LOADED);
      }
    }
    if (isArrayValue(filterValue) && typeof filterFunction === 'function') {
      result = filterFunction(filterValue, originalData, result);
    }
    setSearchResults(result);
  };

  useEffect(() => {
    return () => window.clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    if (urlChange.pathname) {
      if (sortBy && sortDirection && searchParams) {
        for (let param of searchParams) {
          if (Array.isArray(param) && param.at(0) === 'sortBy' && param.at(1) !== sortBy) {
            handleSortBy(param.at(1));
          }
          if (Array.isArray(param) && param.at(0) === 'sortDirection' && param.at(1) !== sortDirection) {
            handleSortDirection(param.at(1));
          }
        }
      }
    }
  }, [urlChange.pathname]);

  const handleTableSort = (val) => {
    let sortCol = 'none';
    let sortDir = 'none';
    if (val && val.length > 0 && val[0]?.id !== 'none') {
      sortCol = val[0]?.id;
      sortDir = val[0]?.desc ? 'desc' : 'asc';
    }
    handleSortBy(sortCol);
    handleSortDirection(sortDir);
    setSearchParams({ sortBy: sortCol, sortDirection: sortDir });
  };

  return (
    <Box mt="6" p={0}>
      <SearchComponent
        isDisabled={!originalData || originalData?.length === 0}
        filterValue={filterValue}
        filterOptions={filterOptions}
        filterPlaceholder={filterPlaceholder}
        filterLabelStyle={filterLabelStyle}
        dataType={title}
        searchText={contractSearchText}
        onSearchComplete={handleSearch}
        onFilterComplete={handleFilterComplete}
        handleClearSearch={handleClearSearch}
      >
        {children}
        {includeAdvancedContractFilters && (
          <Button leftIcon={<BsPlus />} variant="cyan" onClick={addFilterModal.onOpen} isDisabled={!editable}>
            Add Filters
          </Button>
        )}
      </SearchComponent>
      {includeAdvancedContractFilters && <ContractFilters modal={addFilterModal} contracts={dataBackup} onSaveFilter={onAdvFilterSaved} />}
      <SearchContainer feature={title.toUpperCase()} status={searchStatus} loading={isSearchLoading} isError={isSearchError}>
        {isDataError ? (
          <AlertComponent status="error" title={dataErrorTitle} description={dataErrorMsg} />
        ) : (
          <DataTable
            loading={isDataLoading}
            columns={dataColumns}
            data={searchStatus === NO_RESULTS ? [] : finalResults}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onSort={handleTableSort}
            onLoaded={handleTableLoaded}
          />
        )}
      </SearchContainer>
    </Box>
  );
};

DataTableSearchAndFiltering.propTypes = {
  title: PropTypes.string,
  editable: PropTypes.bool,
  includeAdvancedContractFilters: PropTypes.bool,
  originalData: PropTypes.array,
  filterOptions: PropTypes.array,
  filterValue: PropTypes.array,
  filterFunction: PropTypes.func,
  filterLabelStyle: PropTypes.object,
  handleFilterValue: PropTypes.func,
  filterPlaceholder: PropTypes.string,
  searchStatus: PropTypes.string,
  isSearchLoading: PropTypes.bool,
  isSearchError: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  isDataError: PropTypes.bool,
  dataErrorMsg: PropTypes.string,
  dataErrorTitle: PropTypes.string,
  dataColumns: PropTypes.array,
  searchColumns: PropTypes.array,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  children: PropTypes.node,
  handleSortBy: PropTypes.func,
  handleSortDirection: PropTypes.func,
  handleSearchStatus: PropTypes.func,
  onAdvFilterSaved: PropTypes.func,
};

export default DataTableSearchAndFiltering;
