import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Stack, useDisclosure, Button, Spacer } from '@chakra-ui/react';
import Layout from '@/features/common/Layout';
import AlertComponent from '@/features/common/AlertComponent';
import SSDataTable from '@/features/common/table/SSDataTable';
import SearchInput from '@/features/common/SearchInput';
import { BsPlus } from 'react-icons/bs';
import AddMappingModal from '@/features/mapping/AddMappingModal';
import { Select } from 'chakra-react-select';
import { useSelector } from 'react-redux';
import { selectJobSourcesList } from '@/features/enum/enumSlice';

const Mappings = ({
  type,
  searchText,
  handleSearchTextChange,
  columns,
  mappings,
  mappingsState,
  add,
  addState,
  updateState,
  pagination,
  limit,
  onPaginationChange,
  source,
  onFilterChange,
}) => {
  const addMappingModal = useDisclosure();
  const [pageCount, setPageCount] = useState(null);
  const jobSources = useSelector(selectJobSourcesList);

  useEffect(() => {
    if (mappings) {
      setPageCount(Math.ceil(mappings?.total / limit));
    }
  }, [mappings]);

  const handleSearch = (event) => {
    const value = event.target.value;
    handleSearchTextChange(value);
  };

  const handleClear = () => {
    handleSearchTextChange('');
  };

  const handleNewMapping = () => {
    addMappingModal.onOpen();
  };
  const handleAddMapping = (payload) => {
    const { map_from, map_to_id, source_system } = payload;
    const mapping = {
      map_from,
      map_to_id,
      source_system,
    };
    add({ mapping })
      .unwrap()
      .then(() => {
        addMappingModal.onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box data-testid={`${type.toLowerCase()}-mapping`}>
      <Layout pageTitle={'Pact'}>
        <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
          {type + ' Mapping'}
        </Heading>
        <Box mt="6" p={0}>
          {mappingsState.isError ? (
            <AlertComponent status="error" title={`${type} Mapping Error`} description={mappingsState.error?.data?.detail} />
          ) : (
            <React.Fragment>
              <Stack direction={['column', 'row']} minWidth="max-content" alignItems="center" gap="0">
                <Box p={5} width={{ base: '100%', md: '50%' }}>
                  <SearchInput
                    isDisabled={false}
                    searchText={searchText}
                    dataType={`${type.toLowerCase()}-mapping`}
                    onChange={handleSearch}
                    onClear={handleClear}
                  />
                </Box>
                <Flex gap={5} justifyContent="space-between" width="100%">
                  <Select
                    focusBorderColor="brand.700"
                    aria-label="table-filter"
                    options={jobSources}
                    isSearchable
                    value={source}
                    isClearable={true}
                    onChange={onFilterChange}
                    placeholder="Filter by source"
                  />
                  <Spacer />
                  <Button leftIcon={<BsPlus />} onClick={handleNewMapping} variant="purple">
                    New
                  </Button>
                </Flex>
              </Stack>
              <Flex gap={5} justifyContent="space-between" width="100%">
                {updateState.isError ? (
                  <AlertComponent status="error" title={`${type} Mapping Error`} description={updateState.error?.data?.detail} />
                ) : (
                  <SSDataTable
                    loading={mappingsState.isLoading}
                    data={mappings?.items}
                    columns={columns}
                    editRowLoading={updateState.isLoading}
                    pagination={pagination}
                    pageCount={pageCount}
                    onPaginationChange={onPaginationChange}
                  />
                )}
                <AddMappingModal
                  type={type}
                  onOpen={addMappingModal.onOpen}
                  onClose={addMappingModal.onClose}
                  isOpen={addMappingModal.isOpen}
                  isLoading={addState.isLoading}
                  isError={addState.isError}
                  errorMsg={addState.error}
                  handleSubmit={handleAddMapping}
                />
              </Flex>
            </React.Fragment>
          )}
        </Box>
      </Layout>
    </Box>
  );
};

export default Mappings;
