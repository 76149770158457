import { createSlice } from '@reduxjs/toolkit';
import { nucleusApi } from '@/app/services/nucleus';

const initialState = {
  modalities: [],
  searchStatus: '',
  sortBy: 'division',
  sortDirection: 'asc',
};

export const modalitySlice = createSlice({
  name: 'modality',
  initialState,
  reducers: {
    setModalities: (state, { payload }) => {
      state.modalities = payload;
    },
    setSearchStatus: (state, { payload }) => {
      state.searchStatus = payload;
    },
    setSortBy: (state, { payload }) => {
      state.sortBy = payload;
    },
    setSortDirection: (state, { payload }) => {
      state.sortDirection = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(nucleusApi.endpoints.modalities.matchFulfilled, (state, { payload }) => {
      if (payload && payload?.items) {
        state.modalities = payload.items;
      }
    });
  },
});

export const { setModalities, setSearchStatus, setSortBy, setSortDirection } = modalitySlice.actions;

export const selectModalities = (state) => state.modality.modalities;
export const selectModalitySearchStatus = (state) => state.modality.searchStatus;
export const selectModalitySortBy = (state) => state.modality.sortBy;
export const selectModalitySortDirection = (state) => state.modality.sortDirection;

export default modalitySlice.reducer;
