import { chakraComponents } from 'chakra-react-select';
import PropTypes from 'prop-types';
import { chakra } from '@chakra-ui/react';

export const SingleValueShortName = (props) => {
  const { short_name } = props.getValue()[0];
  return (
    <chakraComponents.SingleValue {...props}>
      <chakra.div>{short_name}</chakra.div>
    </chakraComponents.SingleValue>
  );
};
SingleValueShortName.propTypes = {
  short_name: PropTypes.string,
};

export const SingleValueName = (props) => {
  const { name } = props.getValue()[0];
  return (
    <chakraComponents.SingleValue {...props}>
      <chakra.div>{name}</chakra.div>
    </chakraComponents.SingleValue>
  );
};
SingleValueName.propTypes = {
  name: PropTypes.string,
};

export const SingleValueFirstLastName = (props) => {
  const { first_name, last_name } = props.getValue()[0];
  const first = first_name ?? '';
  const last = last_name ?? '';
  return (
    <chakraComponents.SingleValue {...props}>
      <chakra.div>{first + ' ' + last}</chakra.div>
    </chakraComponents.SingleValue>
  );
};
SingleValueFirstLastName.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
};

export const SingleValueLabel = (props) => {
  const { label } = props.getValue()[0];
  return (
    <chakraComponents.SingleValue {...props}>
      <chakra.div>{label}</chakra.div>
    </chakraComponents.SingleValue>
  );
};
SingleValueLabel.propTypes = {
  label: PropTypes.string,
};
