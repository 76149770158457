import { createSlice, createSelector } from '@reduxjs/toolkit';
import { nucleusApi } from '@/app/services/nucleus';

const initialState = {
  roles: [],
  companyType: {},
  divisions: {},
  invoiceDeliveryMethods: {},
  operators: {},
  continentalStates: [],
  jobSources: [],
  operatorMapping: {
    eq: '=',
    gt: '>',
    gte: '>=',
    lt: '<',
    lte: '<=',
    ne: '!=',
    ilcontains: 'has',
    ircontains: 'in',
  },
  eventSource: {},
  entityType: {},
};

export const enumSlice = createSlice({
  name: 'enum',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(nucleusApi.endpoints.getEnums.matchFulfilled, (state, { payload }) => {
      if (payload?.divisions) {
        state.divisions = payload?.divisions;
      }
      if (payload?.auth_user_role) {
        state.roles = payload?.auth_user_role;
      }
      if (payload?.job_sources) {
        state.jobSources = payload?.job_sources;
      }
      if (payload?.company_type) {
        state.companyType = payload?.company_type;
      }
      if (payload?.comparison_operator) {
        state.operators = payload?.comparison_operator;
      }
      if (payload?.continental_state) {
        state.continentalStates = Object.values(payload?.continental_state);
      }
      if (payload?.invoice_delivery_methods) {
        state.invoiceDeliveryMethods = payload?.invoice_delivery_methods;
      }
      if (payload?.entity_type) {
        state.entityType = payload?.entity_type;
      }
      if (payload?.event_source) {
        state.eventSource = payload?.event_source;
      }
    });
  },
});

export const { setActivityType } = enumSlice.actions;

export const selectAllEnum = (state) => state.enum;
export const selectCompanyType = (state) => state.enum.companyType;
export const selectCompanyTypeList = createSelector(selectCompanyType, (companyType) => {
  return Object.values(companyType).map((ct) => {
    let display = ct;
    if (display.includes('_')) display = display.replace('_', ' ');
    const words = display.split(' ');

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    const label = words.join(' ');
    return {
      value: ct,
      label: label.toLowerCase() === 'msp' ? label.toUpperCase() : label,
    };
  });
});
export const selectJobSources = (state) => state.enum.jobSources;
export const selectJobSourcesList = createSelector(selectJobSources, (source) => {
  return Object.values(source).map((div) => ({ value: div, label: div }));
});
export const selectEntityType = (state) => state.enum.entityType;
export const selectEntityTypeList = createSelector(selectEntityType, (source) => {
  return Object.values(source).map((div) => ({ value: div, label: div }));
});
export const selectContinentalStates = (state) => state.enum.continentalStates;
export const selectStateList = createSelector(selectContinentalStates, (states) => {
  return states.map((state, i) => ({ value: i, label: state }));
});

export const selectRoles = (state) => state.enum.roles;
export const selectRolesList = createSelector(selectRoles, (roles) => {
  if (typeof roles !== 'object' || roles === null) {
    return [];
  }
  return Object.values(roles).map((role) => ({ value: role, label: role }));
});
export const selectOperators = (state) => state.enum.operators;
export const selectOperatorMapping = (state) => state.enum.operatorMapping;
export const selectOperatorList = createSelector(selectOperators, selectOperatorMapping, (op, mapping) => {
  return Object.keys(op).reduce((result, key) => {
    if (mapping && Object.keys(mapping).includes(key)) {
      result.push({ label: mapping[key], value: op[key] });
    }
    return result;
  }, []);
});

export const selectEventSource = (state) => state.enum.eventSource;
export const selectEventSourceList = createSelector(selectEventSource, (source) => {
  let values = Object.values(source).map((div) => ({ value: div, label: div }));
  values.push({ value: 'bullhorn_placement', label: 'bullhorn_placement' });
  values.push({ value: 'bullhorn_job_submission', label: 'bullhorn_job_submission' });
  return values;
});
export const selectDivisions = (state) => state.enum.divisions;
export const selectDivisionsList = createSelector(selectDivisions, (divisions) => {
  return Object.values(divisions).map((div) => ({ value: div, label: div }));
});
export const selectInvoiceDeliveryMethods = (state) => state.enum.invoiceDeliveryMethods;

export default enumSlice.reducer;
