import { useEffect } from 'react';
import { setNotificationLogMessage } from '@/features/notification/notificationSlice';
import { useDispatch } from 'react-redux';

export const useNotification = (dep, cb, condition, status, msg) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (condition) {
      typeof cb === 'function' && cb();
      dispatch(setNotificationLogMessage({ status, msg }));
    }
  }, [dep]);

  return null;
};
