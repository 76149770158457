import React, { useMemo, useState } from 'react';
import { Box, Flex, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react';
import {
  useAddFacilityMappingMutation,
  useGetFacilityMappingsQuery,
  useRemoveFacilityMappingMutation,
  useUpdateFacilityMappingMutation,
} from '@/app/services/nucleus';
import { createColumnHelper } from '@tanstack/react-table';
import AlertComponent from '@/features/common/AlertComponent';
import { usePagination } from '@/hooks/usePagination';
import ModalComponent from '@/features/common/ModalComponent';
import { FaUserEdit } from 'react-icons/fa';
import { useNotification } from '@/hooks/useNotification';
import { useRole } from '@/hooks/useRole';
import { createMappingColumns } from '@/features/mapping/mappingHelper';
import Mappings from '@/features/mapping/Mappings';

const FacilityMapping = () => {
  useRole('jobs');
  const removeMappingModal = useDisclosure();
  const columnHelper = createColumnHelper();
  const [searchText, setSearchText] = useState('');
  const [currentMapping, setCurrentMapping] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [source, setSource] = useState(null);
  const { limit, onPaginationChange, pagination: paginationState, offset } = usePagination();

  const {
    data: facilityMappings,
    isLoading: isMappingsLoading,
    isError: isMappingsError,
    error: mappingsErrorMsg,
  } = useGetFacilityMappingsQuery({ offset, limit, search: searchText, source: source?.label });

  const [
    removeFacilityMapping,
    {
      isLoading: removeMappingLoading,
      isSuccess: removeMappingSuccess,
      isError: removeMappingError,
      error: removeMappingErrorMsg,
      reset: removeMappingReset,
    },
  ] = useRemoveFacilityMappingMutation();

  const [
    addFacilityMapping,
    { isLoading: addMappingLoading, isSuccess: addMappingSuccess, isError: addMappingError, error: addMappingErrorMsg, reset: addMappingReset },
  ] = useAddFacilityMappingMutation();

  const [
    updateFacilityMapping,
    {
      isLoading: updateFacilityMappingLoading,
      isSuccess: updateFacilityMappingSuccess,
      isError: updateFacilityMappingError,
      error: updateFacilityMappingErrorMsg,
      reset: updateFacilityMappingReset,
    },
  ] = useUpdateFacilityMappingMutation();

  useNotification(updateFacilityMappingSuccess, updateFacilityMappingReset, updateFacilityMappingSuccess, 'success', 'Facility Mapping Updated');
  useNotification(removeMappingSuccess, removeMappingReset, removeMappingSuccess, 'success', 'Facility Mapping Removed');
  useNotification(addMappingSuccess, addMappingReset, addMappingSuccess, 'success', 'Facility Mapping Added');

  const outputMapToString = (info) => {
    const mapTo = info.getValue();
    return `${mapTo?.name} - ${mapTo?.city}, ${mapTo?.state}`;
  };

  const handleCancelEdit = (row, column, table) => {
    resetEditMode(row, column, table);
    table.options.meta?.resetRowData(row.index);
  };

  const handleFilterChange = (selectOptions) => {
    setSource(selectOptions || []);
  };

  const handleConfirmEdit = (row, column, table) => {
    updateFacilityMapping({ id: currentMapping?.id, mapFrom: row?.original?.map_from })
      .unwrap()
      .then((payload) => {
        if (payload) {
          resetEditMode(row, column, table);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleRemoveFacilityMapping = (e, row) => {
    setCurrentMapping(row?.original);
    setIsEditing(false);
    removeMappingModal.onOpen();
  };

  const handleEditFacilityMapping = (e, row, column, table) => {
    setCurrentMapping(row?.original);
    setIsEditing(true);
    table.options.meta?.addRowColor(row.index, 'yellow.50');
  };

  const columns = useMemo(() => {
    return createMappingColumns({
      helper: columnHelper,
      currentMapping,
      handleActionsCell: (row) => currentMapping?.id === row?.original?.id && isEditing,
      handleMapToCell: (info) => outputMapToString(info),
      isEditing,
      onSave: handleConfirmEdit,
      onCancel: handleCancelEdit,
      onEdit: handleEditFacilityMapping,
      onRemove: handleRemoveFacilityMapping,
    });
  }, [currentMapping, facilityMappings?.items]);

  const handleCurrentMapping = (mapping) => {
    setCurrentMapping(mapping);
  };

  const removeMapping = () => {
    removeFacilityMapping({ id: currentMapping?.id })
      .unwrap()
      .then((payload) => {
        if (payload) {
          removeMappingModal.onClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetEditMode = (row, column, table) => {
    setCurrentMapping(null);
    setIsEditing(false);
    table.options.meta?.addRowColor(row.index, null);
  };

  return (
    <Box data-testid="facility-mapping">
      <Mappings
        handleCurrentMapping={handleCurrentMapping}
        type="Facility"
        searchText={searchText}
        handleSearchTextChange={setSearchText}
        columns={columns}
        mappings={facilityMappings}
        mappingsState={{
          isLoading: isMappingsLoading,
          isError: isMappingsError,
          error: mappingsErrorMsg,
        }}
        add={addFacilityMapping}
        addState={{ isLoading: addMappingLoading, isError: addMappingError, error: addMappingErrorMsg }}
        update={updateFacilityMapping}
        updateState={{ isLoading: updateFacilityMappingLoading, isError: updateFacilityMappingError, error: updateFacilityMappingErrorMsg }}
        pagination={paginationState}
        limit={limit}
        onPaginationChange={onPaginationChange}
        source={source}
        onFilterChange={handleFilterChange}
      />
      <ModalComponent
        icon={<FaUserEdit />}
        size="sm"
        title="Remove Facility Mapping"
        primaryText="Yes"
        secondaryText="No"
        handleConfirm={removeMapping}
        onOpen={removeMappingModal.onOpen}
        isOpen={removeMappingModal.isOpen}
        onClose={removeMappingModal.onClose}
        isError={false}
      >
        <Stack spacing={3}>
          {removeMappingLoading ? (
            <Flex alignItems="center" justifyContent="center">
              <Spinner color="brand.900" size="lg" />
            </Flex>
          ) : (
            <Box>
              <Text>Are you sure you want to remove this facility mapping?</Text>
              {removeMappingError && (
                <AlertComponent status="error" title="Error removing facility mapping" description={removeMappingErrorMsg?.data?.detail} />
              )}
            </Box>
          )}
        </Stack>
      </ModalComponent>
    </Box>
  );
};

export default FacilityMapping;
