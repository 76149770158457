import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Heading } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import Layout from '@/features/common/Layout';

const WelcomeError = ({ title, desc }) => {
  return (
    <Box data-testid="pact-contracts">
      <Layout pageTitle={'Pact'}>
        <Box mt="6" p={0}>
          <Heading as={'h4'} size={'md'} fontWeight={'bold'} color="brand.900" pb={4}>
            {title}
          </Heading>
          <Alert status={'error'} borderRadius={6}>
            <AlertIcon />
            <Box pl={4}>
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{desc ? desc : 'Oops, there was an error!'}</AlertDescription>
            </Box>
          </Alert>
        </Box>
      </Layout>
    </Box>
  );
};

WelcomeError.propTypes = {
  error: PropTypes.object,
};

export default WelcomeError;
