import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import { FiArrowRight } from 'react-icons/fi';
import { chakraComponents } from 'chakra-react-select';

const MultiValueRemove = (props) => {
  const { data } = props;
  if (data?.items) {
    return <Box>{data?.items && <FiArrowRight />}</Box>;
  } else {
    return <chakraComponents.MultiValueRemove {...props} />;
  }
};
MultiValueRemove.propTypes = {
  data: PropTypes.object,
};
export default MultiValueRemove;
