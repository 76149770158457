import PropTypes from 'prop-types';
import { Box, HStack, Icon, Text } from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';
import TruncatedTextComponent from '../common/TruncatedTextComponent';

const RulesErrorListComponent = ({ errors, ...props }) => {
  if (Array.isArray(errors)) {
    return errors.map((err, i) => {
      let ruleIndex = null;
      let conditionIndex = null;
      let field = null;
      if (err.loc && err.loc.length > 0) {
        ruleIndex = err.loc[err.loc.indexOf('rules') + 1] + 1;
        conditionIndex = err.loc[err.loc.indexOf('conditions') + 1] + 1;
        field = err.loc[err.loc.length - 1];
      }
      return (
        <Box aria-label="error-list" key={err.type + i} status="error" {...props} bgColor="#FFF4F4" p={4}>
          <Box>
            <HStack>
              <Icon boxSize={6} as={FiAlertCircle} color="red" />
              <Box maxWidth="sm">
                <Text as="b"></Text>
                <Box>
                  <b>Rule: </b>
                  {ruleIndex}
                </Box>
                <Box>
                  <b>Condition: </b>
                  {conditionIndex}
                </Box>
                <Box>
                  <b>Field: </b>
                  {field}
                </Box>
                <Box>
                  <b>Error: </b>
                  <TruncatedTextComponent length={150} title="Error Title" text={err.msg} />
                </Box>
              </Box>
            </HStack>
          </Box>
        </Box>
      );
    });
  }
};
RulesErrorListComponent.propTypes = {
  errors: PropTypes.array,
};

export default RulesErrorListComponent;
