import { MAX_LIST_LENGTH } from '@/utils/constants.js';

const cleanString = (string) => {
  const noSpecials = string.replace(/[^a-zA-Z ]/g, '');
  return noSpecials.toLowerCase().replace(/ /g, '_');
};

export const addTruncatedProperties = (fieldName, response, length) => {
  return response.map((item) => {
    const list = item[fieldName];
    let divisionsObjList = [];
    if (list && list.length > 0 && typeof list !== 'string') {
      // create obj with id for React key
      divisionsObjList = list.map((item) => {
        const id = cleanString(item).slice(0, item.length / 2);
        return {
          id,
          value: item,
        };
      });
    }

    let tmpString = '';
    let marker = 0;
    if (list && list.length > 0 && typeof list !== 'string') {
      const maxLen = length || MAX_LIST_LENGTH;
      list.forEach((fac, i) => {
        if (marker === 0) {
          tmpString = tmpString === '' ? fac : tmpString + `, ${fac}`;
        }
        if (tmpString.length >= maxLen && marker === 0) {
          const re = new RegExp(`.{${maxLen}}\\w*|.*`, 'g');
          const match = re.exec(tmpString);
          tmpString = match[0];
          marker = i;
        }
      });
      if (tmpString[tmpString.length - 1] === ',') {
        tmpString = tmpString.slice(0, -1);
      }
    }
    const truncatedDivs = tmpString;
    return {
      ...item,
      [`${fieldName}Truncated`]: truncatedDivs,
      [`${fieldName}Popover`]: divisionsObjList,
    };
  });
};
