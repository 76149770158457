import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@chakra-ui/react';

const TableCell = ({ getValue, row, column, table }) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Input
      aria-label="edit input"
      autoFocus={true}
      bgColor={'white'}
      _placeholder={{ color: 'gray.400' }}
      type="text"
      placeholder=""
      value={value}
      onBlur={() => table.options.meta?.updateData(row.index, column.id, value)}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

TableCell.propTypes = {
  getValue: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  table: PropTypes.object.isRequired,
};
export default TableCell;
