import { createSlice } from '@reduxjs/toolkit';
import { nucleusApi } from '@/app/services/nucleus';
import { LOGGED_OUT, LOGGED_IN, LOGIN_ERROR, ERROR, LOADING, LOADED, TOKEN_RECEIVED } from '@/utils/constants';

const initialState = {
  token: '',
  status: '',
  expiration: 0,
  forceLogout: false,
  failedRequests: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLogoutStatus: (state) => {
      state.status = LOGGED_OUT;
      state.token = '';
    },
    setLoginStatus: (state, { payload }) => {
      const status = [LOGGED_OUT, LOGGED_IN, LOGIN_ERROR, ERROR, LOADING, LOADED, TOKEN_RECEIVED];
      if (status.includes(payload)) {
        state.status = payload;
      }
    },
    setLoginToken: (state, { payload }) => {
      state.token = payload;
    },
    setFailedRequests: (state, { payload }) => {
      state.failedRequests = payload;
    },
    setForceLogout: (state, { payload }) => {
      state.forceLogout = payload;
    },
    setLoginExpiration: (state, { payload }) => {
      state.expiration = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(nucleusApi.endpoints.login.matchPending, (state) => {
        state.status = LOADING;
      })
      .addMatcher(nucleusApi.endpoints.login.matchFulfilled, (state, { payload }) => {
        if (payload) {
          state.token = payload?.access_token;
          state.expiration = payload?.expires;
          state.status = TOKEN_RECEIVED;
        }
      })
      .addMatcher(nucleusApi.endpoints.login.matchRejected, (state, result) => {
        state.status = LOGIN_ERROR;
      });
    builder.addMatcher(nucleusApi.endpoints.contracts.matchFulfilled, (state, { payload }) => {
      state.status = LOGGED_IN;
    });
  },
});

export const { setFailedRequests, setLoginToken, setFeatures, setForceLogout, setLoginStatus, setLoginExpiration, setLogoutStatus } =
  loginSlice.actions;

export const selectLoginExpiration = (state) => state.login.expiration;
export const selectLoginToken = (state) => state.login.token;
export const selectLoginStatus = (state) => state.login.status;
export const selectForceLogout = (state) => state.login.forceLogout;

export default loginSlice.reducer;
