import { unwrapResult } from '@reduxjs/toolkit';

export const removeFacilityAction = (removeFacility, contractId, facilityId) => {
  let asyncRemoveFacility = async () => {
    try {
      const result = await removeFacility({ contractId: contractId, facilityId: facilityId });
      unwrapResult(result);
    } catch (error) {
      console.log(error);
    }
  };
  asyncRemoveFacility().catch((error) => {
    console.log(error);
  });
};
export const facilityFilterOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Do Not Sell', value: 'is_do_not_sell' },
];
export const searchColumns = ['name', 'city', 'state', 'is_do_not_sell'];
