import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Box, Spinner, Heading, Button, FormControl, useDisclosure } from '@chakra-ui/react';
import Layout from '@/features/common/Layout';
import DataTable from '@/features/common/table/DataTable';
import ModalComponent from '@/features/common/ModalComponent';
import AlertComponent from '@/features/common/AlertComponent';
import { Select } from 'chakra-react-select';
import { chakraStyles } from '@/features/common/select/styles';
import { useJobSourcesQuery, useUploadJobsMutation } from '@/app/services/nucleus';
import { selectJobSources } from './jobSlice';
import { AnimatePresence } from 'framer-motion';
import FileUpload from '../common/FileUpload';
import JobErrorListComponent from './JobErrorListComponent';
import { useRole } from '@/hooks/useRole';
import LoaderOverlay from '@/features/common/LoaderOverlay';

const JobLoader = () => {
  useRole('jobs');
  const previewDataModal = useDisclosure();
  const sources = useSelector(selectJobSources);
  const [jobSource, setJobSource] = useState(null);
  const [payload, setPayload] = useState([]);
  const [reset, setReset] = useState(false);
  const [data, setData] = useState(null);
  const [columns, setColumns] = useState(null);

  const { isLoading: isJobLoading, isError: isJobError, error: jobError } = useJobSourcesQuery();
  const [upload, { isLoading: uploadLoading, isSuccess: uploadSuccess, isError: uploadError, error: uploadErrorMsg, reset: resetUpload }] =
    useUploadJobsMutation();

  const handlePreview = () => {
    previewDataModal.onOpen();
  };

  const handleUpload = () => {
    if (payload && payload.length > 0) {
      upload({ jobs: payload })
        .unwrap()
        .catch((error) => console.log('job upload failed', error));
    }
  };

  const handleJobSourceChange = (value) => {
    setJobSource(value);
  };

  const handleCancel = () => {
    setReset(true);
    setJobSource(null);
    resetUpload();
  };

  const handleFileUploadClear = () => {
    setData(null);
    setJobSource(null);
  };

  const handleFileLoaded = (cols, file) => {
    resetUpload();
    let payload = [];
    if (jobSource?.value) {
      const fileData = file?.data;
      if (Array.isArray(fileData)) {
        payload = fileData.reduce((result, data) => {
          if (data?.status) {
            const status = data.status.toLowerCase();
            data.status = status === 'on hold' ? 'on_hold' : status;
          }
          if (data.origin_id !== '') {
            result.push({
              ...data,
              source: jobSource.label,
            });
          }
          return result;
        }, []);
      }
    }
    setData(file?.data);
    setColumns(cols);
    setPayload(payload);
  };

  return (
    <Box data-testid="pact-contracts">
      <Layout pageTitle={'Pact'}>
        {isJobLoading ? (
          <Flex alignItems="center" justifyContent="center" py={200}>
            <Spinner color="brand.900" size="lg" />
          </Flex>
        ) : (
          <React.Fragment>
            <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
              Job Loader
            </Heading>
            <Box mt="6" p={0}>
              {isJobError ? (
                <AlertComponent mb={6} status="error" title="Error Fetching Job Sources" description={jobError?.data?.detail} />
              ) : (
                <React.Fragment>
                  <LoaderOverlay loading={uploadLoading} />
                  <FormControl id="jobSources" maxW={'50%'} py={6}>
                    <Select
                      aria-label="jobSources"
                      chakraStyles={chakraStyles}
                      focusBorderColor="brand.700"
                      options={sources.map((s) => ({ value: s.toLowerCase(), label: s }))}
                      onChange={handleJobSourceChange}
                      isClearable
                      isSearchable
                      value={jobSource}
                      placeholder="Select job source"
                    />
                  </FormControl>
                  <FileUpload
                    handleUpload={handleUpload}
                    uploadSuccess={uploadSuccess}
                    uploadError={uploadError}
                    errorMessage={uploadErrorMsg}
                    isReset={reset}
                    fileLoadedEvent={(cols, data) => handleFileLoaded(cols, data)}
                    isDisabled={jobSource === null}
                    handleClear={() => handleFileUploadClear()}
                  />
                  {(data !== null || uploadErrorMsg) && (
                    <Button onClick={handlePreview} mt={6} mr={4} variant={uploadErrorMsg ? 'red' : 'cyan'}>
                      {uploadErrorMsg ? 'Review' : 'Preview'}
                    </Button>
                  )}
                  {data !== null && (
                    <Button onClick={handleCancel} mt={6} mr={4}>
                      Cancel
                    </Button>
                  )}
                  <AnimatePresence>
                    {uploadError && (
                      <Box
                        mt={6}
                        overflowY="scroll"
                        maxH={800}
                        css={{
                          '&::-webkit-scrollbar': {
                            width: '4px',
                          },
                          '&::-webkit-scrollbar-track': {
                            width: '6px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: '#cccccc',
                            borderRadius: '24px',
                          },
                        }}
                      >
                        <Heading as="h5" size="md" fontWeight="bold" color="brand.900">
                          Errors
                        </Heading>
                        <JobErrorListComponent errors={uploadErrorMsg?.data?.detail} />
                      </Box>
                    )}
                  </AnimatePresence>
                  <ModalComponent
                    size="xl"
                    title="CSV Preview"
                    secondaryText="Close"
                    onOpen={previewDataModal.onOpen}
                    isOpen={previewDataModal.isOpen}
                    onClose={previewDataModal.onClose}
                    isError={false}
                  >
                    <Box>
                      <DataTable showScrollbar={true} columns={columns} data={payload} />
                    </Box>
                  </ModalComponent>
                </React.Fragment>
              )}
            </Box>
          </React.Fragment>
        )}
      </Layout>
    </Box>
  );
};

export default JobLoader;
