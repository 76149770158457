import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import LoadingWrapper from '@/features/common/motion/LoadingWrapper';

const LoaderOverlay = ({ loading }) => {
  return (
    <AnimatePresence>
      {loading && (
        <LoadingWrapper
          alignItems="center"
          aria-label="loading"
          pos={'absolute'}
          bg={'rgba(255,255,255,0.6)'}
          height={'100%'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Spinner color="brand.900" size="lg" />
        </LoadingWrapper>
      )}
    </AnimatePresence>
  );
};

LoaderOverlay.propTypes = {
  loading: PropTypes.bool,
};

export default LoaderOverlay;
