import { chakra, shouldForwardProp } from '@chakra-ui/react';
import { motion, isValidMotionProp } from 'framer-motion';

const LoadingWrapper = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
  baseStyle: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    zIndex: 103,
  },
});
export default LoadingWrapper;
