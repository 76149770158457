import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, FormLabel, Grid, IconButton, Input, InputGroup, InputRightElement, VStack, useColorModeValue } from '@chakra-ui/react';
import { useAddCertificationsMutation } from '@/app/services/nucleus';
import LoaderOverlay from '../common/LoaderOverlay';
import AlertComponent from '../common/AlertComponent';
import { TbCertificateOff } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { Select } from 'chakra-react-select';
import { BsTrash } from 'react-icons/bs';
import { selectEventSourceList } from '../enum/enumSlice';
import { useSelector } from 'react-redux';
import { isRealValue } from '@/utils/helpers';

const NewCertificationsForm = ({ onSave }) => {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const labelColor = useColorModeValue('gray.500', 'gray.400');
  const eventSourceOptions = useSelector(selectEventSourceList);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    systemIDs: {},
    eventSource: [],
  });

  const [
    addCertifications,
    { isLoading: addCertificationsLoading, isError: addCertificationsError, error: addCertificationsErrorMsg, reset: addCertificationsReset },
  ] = useAddCertificationsMutation();

  const validateForm = () => {
    return (
      formData.name.trim() !== '' &&
      formData.description.trim() !== '' &&
      formData.eventSource.length > 0 &&
      !formData.eventSource.some((option) => !isRealValue(option?.value) || formData.systemIDs[option.value].trim() === '')
    );
  };

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [formData]);

  const handleInputChange = useCallback((key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  });

  const handleEventSource = (options) => {
    setFormData((prev) => {
      const updatedEventSource = options;
      const newSystemIDs = {};
      options.forEach((option) => {
        const key = option.value;
        if (prev.systemIDs[key] !== undefined) {
          newSystemIDs[key] = prev.systemIDs[key];
        } else {
          newSystemIDs[key] = '';
        }
      });

      return {
        ...prev,
        eventSource: updatedEventSource,
        systemIDs: newSystemIDs,
      };
    });
  };

  const handleSystemIDInput = (e, systemId) => {
    const newValue = e.target.value;

    setFormData((prev) => {
      const newSystemIDs = {
        ...prev.systemIDs,
        [systemId]: newValue,
      };

      return {
        ...prev,
        systemIDs: newSystemIDs,
      };
    });
  };

  const handleRemoveInputField = (index) => {
    setFormData((prev) => {
      const updatedEventSource = prev.eventSource.filter((option) => option.value !== index);
      const newSystemIDs = { ...prev.systemIDs };
      delete newSystemIDs[index];

      return {
        ...prev,
        eventSource: updatedEventSource,
        systemIDs: newSystemIDs,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addCertificationsReset();

    const system_ids = formData.eventSource.map((option) => ({
      name: option.value,
      system_id: formData.systemIDs[option.value],
    }));

    const certPayload = {
      name: formData.name,
      description: formData.description,
      system_ids,
    };

    addCertifications(certPayload)
      .unwrap()
      .then((payload) => {
        onSave(payload);
        navigate('/certifications');
      })
      .catch((error) => {
        console.log('Error adding certification', error);
      });
  };

  return (
    <form aria-label="new-certifications-form" name="new-certifications-form" onSubmit={handleSubmit}>
      <LoaderOverlay isLoading={addCertificationsLoading} />

      {addCertificationsError && (
        <AlertComponent
          icon={<TbCertificateOff style={{ height: '24px', width: '24px' }} />}
          status="error"
          title="Error Adding Certification"
          description={addCertificationsErrorMsg?.status === 422 ? 'Please add a valid certification' : addCertificationsErrorMsg?.data?.detail}
        />
      )}
      <VStack spacing={4} alignItems="flex-start" width="full" mt={6}>
        <FormControl id="name" aria-label="name-form-control">
          <Grid
            templateColumns={{
              base: '1fr',
              md: '1fr 2fr',
            }}
            gap={4}
            alignItems="center"
            width={{
              base: '100%',
              md: '70%',
            }}
          >
            <FormLabel
              htmlFor="name"
              mb="0"
              textAlign={{
                base: 'start',
                md: 'end',
              }}
              color={labelColor}
              whiteSpace="nowrap"
            >
              Name
            </FormLabel>
            <Input id="name" name="name" type="text" value={formData.name} onChange={(e) => handleInputChange('name', e.target.value)} />
          </Grid>
        </FormControl>

        <FormControl id="description" aria-label="description-form-control">
          <Grid
            templateColumns={{
              base: '1fr',
              md: '1fr 2fr',
            }}
            gap={4}
            alignItems="center"
            width={{
              base: '100%',
              md: '70%',
            }}
          >
            <FormLabel
              htmlFor="description"
              mb="0"
              textAlign={{
                base: 'start',
                md: 'end',
              }}
              color={labelColor}
              whiteSpace="nowrap"
            >
              Description
            </FormLabel>
            <Input
              id="description"
              name="description"
              type="text"
              value={formData.description}
              onChange={(e) => handleInputChange('description', e.target.value)}
            />
          </Grid>
        </FormControl>

        <FormControl id="event_source" aria-label="event-source-form-control">
          <Grid
            templateColumns={{
              base: '1fr',
              md: '1fr 2fr',
            }}
            gap={4}
            alignItems="center"
            width={{
              base: '100%',
              md: '70%',
            }}
          >
            <FormLabel
              htmlFor="event_source"
              mb="0"
              textAlign={{
                base: 'start',
                md: 'end',
              }}
              color={labelColor}
              whiteSpace="nowrap"
            >
              Event Source
            </FormLabel>
            <Select
              id="event_source"
              aria-label="Event Source"
              placeholder="Select Event Source"
              focusBorderColor="brand.700"
              isMulti={true}
              options={eventSourceOptions}
              isSearchable
              value={formData.eventSource}
              isClearable={true}
              onChange={handleEventSource}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </Grid>
        </FormControl>

        {formData.eventSource.map((option) => (
          <FormControl id={`${option.label}-system-id`} key={option.label} aria-label={`${option.label}-system-id-form-control`}>
            <Grid
              templateColumns={{
                base: '1fr',
                md: '1fr 2fr',
              }}
              gap={4}
              alignItems="center"
              width={{
                base: '130%',
                md: '95%',
              }}
            >
              <FormLabel
                htmlFor={`${option.label}-system-id`}
                mb="0"
                textAlign={{
                  base: 'start',
                  md: 'end',
                }}
                color={labelColor}
                whiteSpace="nowrap"
              >
                {option.label}
              </FormLabel>
              <InputGroup width={'60%'}>
                <Input
                  id={`${option.label}-system-id`}
                  name={`${option.label}-system-id`}
                  type="text"
                  value={formData.systemIDs[option.value]}
                  onChange={(e) => handleSystemIDInput(e, option.value)}
                  placeholder={`enter ${option.label} system id`}
                  sx={{ '::placeholder': { color: 'gray.400' } }}
                />
                <InputRightElement>
                  <IconButton
                    aria-label={`Remove ${option.label}`}
                    icon={<BsTrash />}
                    onClick={() => handleRemoveInputField(option.value)}
                    size="sm"
                    variant="ghost"
                  />
                </InputRightElement>
              </InputGroup>
            </Grid>
          </FormControl>
        ))}
      </VStack>
      <Button mt={4} variant="purple" type="submit" isDisabled={!isFormValid}>
        Submit
      </Button>
    </form>
  );
};

NewCertificationsForm.propTypes = {
  onSave: PropTypes.func,
};

export default NewCertificationsForm;
