import { createSlice } from '@reduxjs/toolkit';
import { nucleusApi } from '@/app/services/nucleus';
import { LOADING, LOADED, ERROR, NO_RESULTS } from '@/utils/constants';
import { parseNucleusError } from '@/utils/helpers';
// TODO: clean out companies data since it's not stored here
const initialState = {
  status: '',
  facilities: [],
  msps: [],
  companies: [],
  healthSystems: [],
  total: 0,
  sortBy: 'name',
  sortDirection: 'asc',
};

export const facilitySlice = createSlice({
  name: 'facility',
  initialState,
  reducers: {
    setFacilities: (state, { payload }) => {
      state.facilities = payload;
    },
    setMSPs: (state, { payload }) => {
      state.msps = payload;
    },
    setCompanies: (state, { payload }) => {
      state.companies = payload;
    },
    setHealthSystems: (state, { payload }) => {
      state.healthSystems = payload;
    },
    setFacilityStatus: (state, { payload }) => {
      state.status = payload;
    },
    setFacilitySortBy: (state, { payload }) => {
      state.sortBy = payload;
    },
    setFacilitySortDirection: (state, { payload }) => {
      state.sortDirection = payload;
    },
  },
  // TODO: update to use companies endpoint
  extraReducers: (builder) => {
    builder
      .addMatcher(nucleusApi.endpoints.facilities.matchPending, (state) => {
        state.status = LOADING;
      })
      .addMatcher(nucleusApi.endpoints.facilities.matchFulfilled, (state, { payload }) => {
        if (payload && payload?.items) {
          state.facilities = payload.items;
          state.total = payload?.total || 0;
          if (payload.items.length === 0) {
            state.status = NO_RESULTS;
          } else {
            state.status = LOADED;
          }
        } else {
          state.status = NO_RESULTS;
        }
      })
      .addMatcher(nucleusApi.endpoints.facilities.matchRejected, (state, result) => {
        state.status = ERROR;
        const { payload } = result;
        state.errorMessage = parseNucleusError(payload);
      });
  },
});

export const { setFacilities, setMSPs, setCompanies, setHealthSystems, setFacilityStatus, setFacilitySortBy, setFacilitySortDirection } =
  facilitySlice.actions;

export const selectFacilities = (state) => state.facility.facilities;
export const selectMSPs = (state) => state.facility.msps;
export const selectCompanies = (state) => state.facility.companies;
export const selectHealthSystems = (state) => state.facility.healthSystems;
export const selectFacilitySortBy = (state) => state.facility.sortBy;
export const selectFacilitySortDirection = (state) => state.facility.sortDirection;
export const selectFacilityStatus = (state) => state.facility.status;
export const selectFacilityTotal = (state) => state.facility.total;

export default facilitySlice.reducer;
