import React from 'react';
import PropTypes from 'prop-types';
import { Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import TableButton from '../common/table/TableButton';
import { BsBuildingExclamation, BsTrash } from 'react-icons/bs';
import ModalOverlayComponent from '../common/ModalOverlayComponent';
import LoadingWrapper from '../common/motion/LoadingWrapper';
import AlertComponent from '../common/AlertComponent';
import AnimatedRow from '../common/motion/AnimatedRow';
import { AnimatePresence } from 'framer-motion';
import TruncatedTextComponent from '@/features/common/TruncatedTextComponent.jsx';

const FacilityResultsTable = ({
  data,
  loading,
  facilityName,
  handleDelete,
  handleCancel,
  isDisabled,
  handleRemove,
  showEmpty,
  showAddError,
  showRemoveError,
  addErr,
  removeErr,
}) => {
  return (
    <TableContainer pt={6} width={'100%'} style={{ position: 'relative' }} minH={360}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th width={387}>Name</Th>
            <Th width={165}>City</Th>
            <Th>State</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          <AnimatePresence>
            {data.map((val, i) => (
              <AnimatedRow key={i} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                {val?.overlay ? (
                  <ModalOverlayComponent
                    loading={loading}
                    confirmText="Are you sure you want to remove this facility?"
                    handleDelete={(e) => handleDelete(e, val?.id)}
                    handleCancel={(e) => handleCancel(e, val?.id)}
                  />
                ) : (
                  <React.Fragment>
                    <Td>
                      <TruncatedTextComponent length={30} title="Facility Name" text={val?.name ?? val?.label} />
                    </Td>
                    <Td>{val?.city}</Td>
                    <Td>{val?.state}</Td>
                    <Td>
                      <Tooltip hasArrow placement="top" label="Remove">
                        <Box p="0" as="span">
                          <TableButton isDisabled={isDisabled} aria-label="remove" icon={<BsTrash />} onClick={(e) => handleRemove(e, val?.id)} />
                        </Box>
                      </Tooltip>
                    </Td>
                  </React.Fragment>
                )}
              </AnimatedRow>
            ))}
          </AnimatePresence>
        </Tbody>
      </Table>
      {showEmpty && (
        <LoadingWrapper py={16} height="40px" alignItems="center">
          <Text>No facilities assigned</Text>
        </LoadingWrapper>
      )}
      {showAddError && (
        <AlertComponent
          icon={<BsBuildingExclamation style={{ height: '24px', width: '24px' }} />}
          status="error"
          title={facilityName}
          description={addErr}
        />
      )}
      {showRemoveError && (
        <AlertComponent
          icon={<BsBuildingExclamation style={{ height: '24px', width: '24px' }} />}
          status="error"
          title={facilityName}
          description={removeErr}
        />
      )}
    </TableContainer>
  );
};
FacilityResultsTable.propTypes = {
  data: PropTypes.array,
  isOverlay: PropTypes.bool,
  loading: PropTypes.bool,
  facilityName: PropTypes.string,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  name: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleRemove: PropTypes.func,
  showEmpty: PropTypes.bool,
  showAddError: PropTypes.bool,
  showRemoveError: PropTypes.bool,
  addErr: PropTypes.string,
  removeErr: PropTypes.string,
};
export default FacilityResultsTable;
