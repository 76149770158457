import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  Radio,
  RadioGroup,
  Spinner,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import { days, DETAILS } from '@/utils/constants';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { isArrayEmpty, isArrayValue, isRealValue, toPactPercent } from '@/utils/helpers';
import { Select } from 'chakra-react-select';
import { selectJobSources } from '../job/jobSlice';
import { chakraStyles } from '@/features/common/select/styles.js';
import { BsBuildingExclamation, BsFileEarmarkX, BsX } from 'react-icons/bs';
import {
  useAddContractMutation,
  useGetCompaniesQuery,
  useGetContractQuery,
  useGetHealthSystemsQuery,
  useGetMSPsQuery,
  useLazySearchCompaniesQuery,
  useUpdateContractMutation,
} from '@/app/services/nucleus';
import { datePickerConfig } from '../common/datePickerConfig';
import { useEditContracts } from '@/hooks/useEditContracts';
import AlertComponent from '../common/AlertComponent';
import { selectContractClient, selectContractDivisionsList, selectContractId, setContractClient, setContractId } from './contractSlice';
import {
  calculateContractPayload,
  calculateIsFormInvalid,
  fieldValidationStatus,
  getClientNamePlaceholder,
  parseErrorLocation,
  setFieldValidation,
} from './contractHelper.js';
import { OptionNameCityAddress } from '../common/select/Option';
import { SingleValueLabel } from '../common/select/SingleValue';
import { selectCompanyTypeList, selectDivisionsList, selectEventSource, selectInvoiceDeliveryMethods } from '../enum/enumSlice';
import FacilitySearch from '../facility/FacilitySearch';
import { useNotification } from '@/hooks/useNotification';

const ContractEditForm = ({ isNew }) => {
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const firstTimeLoaded = useRef(false);

  const editable = useEditContracts();
  const labelColor = useColorModeValue('gray.500', 'gray.400');
  const jobSources = useSelector(selectJobSources);
  const client = useSelector(selectContractClient);
  const deliveryMethods = useSelector(selectInvoiceDeliveryMethods);
  const companyType = useSelector(selectCompanyTypeList);
  const divisions = useSelector(selectDivisionsList);
  const eventSource = useSelector(selectEventSource);
  const divisionsDefault = useSelector(selectContractDivisionsList);

  const [fields, setFields] = useState([]);
  const [clientType, setClientType] = useState(null);
  const [contract, setContract] = useState(null);

  const { data: clientMSPs } = useGetMSPsQuery();
  const { data: clientCompanies } = useGetCompaniesQuery();
  const { data: clientHealthSystems } = useGetHealthSystemsQuery();

  const id = useSelector(selectContractId);
  const [localId, setLocalId] = useState(null);

  const [activeDatePicker, setActiveDatePicker] = useState('');

  const [{ isError: isSearchCompaniesError, error: searchCompaniesError }] = useLazySearchCompaniesQuery();

  const { data: existingContract } = useGetContractQuery({ id }, { skip: isNew || !id });

  const template = {
    id: null,
    name: '',
    divisions: [],
    source: null,
    status: 'New',
    starts_at: null,
    ends_at: null,
    client_cancellation_policy: '',
    call_off_policy: '',
    holiday_policy: '',
    notes: '',
    non_bill_orientation: 0,
    bill_orientation: 0,
    bill_orientation_percentage: 0,
    bill_client_fee: 0,
    work_week: 'sunday',
    invoice_delivery_method: '',
    display_facility_name: 0,
    client: {},
    client_id: null,
  };

  useEffect(() => {
    if (isNew) {
      setContract(template);
    } else {
      setContract(existingContract);
    }
  }, [existingContract]);

  const [
    addContract,
    { isLoading: addContractLoading, isSuccess: addContractSuccess, isError: addContractError, error: addContractErrorMsg, reset: addContractReset },
  ] = useAddContractMutation();

  const [
    updateContract,
    {
      isLoading: updateContractLoading,
      isSuccess: updateContractSuccess,
      isError: updateContractError,
      error: updateContractErrorMsg,
      reset: updateContractReset,
    },
  ] = useUpdateContractMutation();

  const allDays = useMemo(() => {
    if (days) {
      return Object.values(days).map((div, i) => ({ value: div, label: div[0].toUpperCase() + div.slice(1) }));
    }
    return [];
  }, [days]);

  const allDeliveryMethods = useMemo(() => {
    if (deliveryMethods) {
      return Object.values(deliveryMethods).map((div, i) => ({ value: div, label: div[0].toUpperCase() + div.slice(1) }));
    }
    return [];
  }, [deliveryMethods]);

  useEffect(() => {
    if (!firstTimeLoaded.current) {
      firstTimeLoaded.current = true;
    }
  }, []);

  useEffect(() => {
    if (addContractError && addContractErrorMsg?.status === 422) {
      const errorFields = parseErrorLocation(addContractErrorMsg, fields);
      if (isArrayValue(errorFields)) {
        setFields(errorFields);
      }
    }
  }, [addContractError, addContractErrorMsg]);

  useEffect(() => {
    if (updateContractError && updateContractErrorMsg?.status === 422) {
      const errorFields = parseErrorLocation(updateContractErrorMsg, fields);
      if (isArrayValue(errorFields)) {
        setFields(errorFields);
      }
    }
  }, [updateContractError, updateContractErrorMsg]);

  useEffect(() => {
    let type = 'msp';
    if (client?.type) {
      type = client?.type;
    }
    setClientType(type);
  }, [client]);

  useEffect(() => {
    if (id === localId) {
      navigate(`/contracts/${id}/${DETAILS}`, { state: { from: location } });
    }
  }, [id, localId]);

  useNotification(addContractSuccess, updateContractReset, addContractSuccess, 'success', 'Contract added successfully');
  useNotification(updateContractSuccess, updateContractReset, updateContractSuccess, 'success', 'Contract updated successfully');
  const updateContractErrorCallback = () => {
    updateContractReset();
    window.scrollTo(0, 0);
  };
  useNotification(
    updateContractError,
    updateContractErrorCallback,
    updateContractError,
    'error',
    'Contract update failed. Please resolve issues marked below',
  );
  useNotification(addContractError, updateContractErrorCallback, addContractError, 'error', 'Contract add failed');

  const isWhole = (n) => n === '' || /^\d+$/.test(n);

  const setFieldValue = (label, val, formFields) => {
    return formFields.map((ff) => {
      let valid = ff.required ? isRealValue(val) && val !== '' && !isArrayEmpty(val) : true;
      if (ff?.max && val > ff?.max) {
        val = ff.max;
      }
      if (ff?.min && val < ff?.min) {
        val = ff.min;
      }
      if (ff?.type === 'hours' && !isWhole(val) && ff.name === label) {
        return {
          ...ff,
          value: val,
          valid: false,
          error: 'Input must be a whole number [0-100]',
        };
      }
      if (ff?.type === 'percentage' && !isWhole(val) && ff.name === label && val < 1) {
        return {
          ...ff,
          value: val,
          valid: true,
          warn: true,
          error: 'Warning: input value is less than 1 percent',
        };
      }
      if (ff.key === 'client_row' && label === 'client_name') {
        ff.fields = ff.fields.map((f) => {
          if (f.name === 'client_name') {
            f.value = val;
            f.valid = isRealValue(val);
            return f;
          } else {
            return f;
          }
        });
        return ff;
      }
      if (ff.name === label) {
        return {
          ...ff,
          value: val,
          warn: false,
          valid,
        };
      }
      return ff;
    });
  };

  const handleInputChange = (event, label, formFields) => {
    const value = isRealValue(event?.target?.value) ? event.target.value : event;
    const modFields = setFieldValue(label, value, formFields);
    if (isArrayValue(modFields)) {
      setFields(modFields);
    }
  };

  const handleDateChange = (event, label, formFields) => {
    const value = isRealValue(event?.target?.value) ? event.target.value : event;
    const updatedFields = formFields.map((field) => {
      if (field.name === 'starts_at') {
        field.valid = true;
        return { ...field, value };
      }
      return field;
    });
    setFields(updatedFields);
  };

  const contractDate = (field) => {
    return contract?.[field] ? new Date(contract?.[field].replace(/-/g, '/')) : undefined;
  };

  const getContractDateField = (field) => {
    const dateField = fields.find((f) => f.name === field);
    return dateField?.value;
  };

  const getClientOption = (type) => {
    return companyType.find((f) => f.value === type);
  };

  const getElementByClientType = (type) => {
    if (type === 'facility') {
      return 'asyncselect';
    } else {
      return 'clientselect';
    }
  };

  const getKamanaContractIdValue = (ids) => {
    let result = '';
    if (ids && isArrayValue(ids)) {
      result = ids.find((id) => id?.name.toLowerCase() === eventSource.kamana?.toLowerCase());
      if (result?.system_id) {
        return result.system_id;
      }
    }
    return result;
  };

  // WARN: JS has known issues with YYYY-MM-DD. A hack/workaround is to replace dashes with slashes
  // https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
  // This useEffect processes form fields after each change is made to a field
  // we do not want validation to trigger for empty fields on initial page load. only on focusOut or submit
  useEffect(() => {
    if (isRealValue(contract?.name) && isArrayEmpty(fields) && clientType) {
      let vms = toPactPercent(contract?.bill_client_fee);
      let billOrientation = toPactPercent(contract?.bill_orientation_percentage);
      const isEndDateVald = new Date(contract?.ends_at) >= new Date(contract?.starts_at) || !isRealValue(contract?.ends_at);
      const fields = [
        {
          label: 'Name',
          name: 'name',
          type: 'text',
          value: contract?.name,
          required: true,
          onchange: handleInputChange,
          error: 'Name is required.',
          valid: firstTimeLoaded.current ? true : isRealValue(contract?.name),
        },
        {
          label: 'Effective Start Date',
          name: 'starts_at',
          type: 'starts_at',
          value: contractDate('starts_at'),
          onchange: handleDateChange,
          required: true,
          valid: firstTimeLoaded.current ? true : isRealValue(contract?.starts_at),
          error: 'Effective Start Date is required.',
        },
        {
          label: 'Effective End Date',
          name: 'ends_at',
          type: 'ends_at',
          value: contractDate('ends_at'),
          onchange: handleInputChange,
          required: false,
          valid: isEndDateVald,
          error: isEndDateVald ? '' : 'End date must be after start date',
        },
        {
          label: 'Divisions',
          name: 'divisions',
          type: 'divisions',
          options: divisions,
          onchange: handleSelectChange,
          value: isArrayEmpty(divisionsDefault) ? null : divisionsDefault,
          required: true,
          placeholder: 'Select divisions',
          error: 'Divisions are required',
          valid: firstTimeLoaded.current ? true : isRealValue(contract?.divisions),
        },
        {
          label: 'Source',
          name: 'source',
          type: 'select',
          options: jobSources.map((s) => ({ value: s.toLowerCase(), label: s })),
          onchange: handleSelectChange,
          value: isRealValue(contract?.source) ? { label: contract?.source, value: contract?.source.toLowerCase() } : null,
          required: true,
          placeholder: 'Select job source',
          error: 'Source is required.',
          valid: firstTimeLoaded.current ? true : isRealValue(contract?.source),
        },
        {
          label: 'Kamana Contract ID',
          name: 'system_ids',
          type: 'text',
          value: getKamanaContractIdValue(contract?.system_ids),
          required: true,
          error: 'Kamana Contract ID is required.',
          onchange: handleInputChange,
          valid: firstTimeLoaded.current ? true : !!getKamanaContractIdValue(contract?.system_ids),
        },
        {
          type: 'row',
          key: 'client_row',
          fields: [
            {
              label: 'Client',
              name: 'client_type',
              type: 'client_type',
              options: companyType,
              value: isRealValue(client) ? getClientOption(clientType) : { label: 'MSP', value: 'msp', payload: {} },
              required: true,
              error: 'Client type is required.',
              placeholder: 'Select a client type',
              valid: firstTimeLoaded.current ? true : isRealValue(client),
            },
            {
              label: '',
              name: 'client_name',
              disabled: false,
              type: isRealValue(client) ? getElementByClientType(clientType) : 'clientselect',
              onchange: handleClientChange,
              options: clientMSPs,
              placeholder: getClientNamePlaceholder(clientType),
              value: isRealValue(client)
                ? { label: client?.name, value: client?.id, city: client?.city, address: client?.address, payload: client }
                : null,
              state: client?.state,
              required: true,
              valid: firstTimeLoaded.current ? true : !!client?.name,
              error: 'Client name is required',
            },
          ],
        },
        {
          label: 'Non Billable Orientation',
          name: 'non_bill_orientation',
          type: 'hours',
          min: 0,
          max: 100,
          onchange: handleInputChange,
          value: contract?.non_bill_orientation,
          required: true,
          valid: firstTimeLoaded.current ? true : isRealValue(contract?.non_bill_orientation),
          error: 'Non billable orientation is required',
        },
        {
          label: 'Billable Orientation',
          name: 'bill_orientation',
          type: 'hours',
          min: 0,
          max: 100,
          onchange: handleInputChange,
          value: contract?.bill_orientation,
          required: true,
          valid: firstTimeLoaded.current ? true : isRealValue(contract?.bill_orientation),
          error: 'Billable orientation is required',
        },
        {
          label: 'Billable Orientation (%)',
          name: 'bill_orientation_percentage',
          type: 'percentage',
          min: 0,
          max: 100,
          warn: false,
          onchange: handleInputChange,
          value: billOrientation,
          required: true,
          valid: firstTimeLoaded.current ? true : isRealValue(billOrientation),
          error: 'Billable orientation percentage is required',
        },
        {
          label: 'VMS Fee',
          name: 'bill_client_fee',
          type: 'percentage',
          min: 0,
          max: 100,
          warn: false,
          onchange: handleInputChange,
          value: vms,
          required: true,
          valid: firstTimeLoaded.current ? true : isRealValue(vms),
          error: 'VMS fee is required',
        },
        {
          label: 'Invoice Delivery',
          name: 'invoice_delivery_method',
          type: 'select',
          options: allDeliveryMethods,
          placeholder: 'Select a delivery method',
          onchange: handleSelectChange,
          value: contract?.invoice_delivery_method
            ? {
                label: contract?.invoice_delivery_method[0].toUpperCase() + contract?.invoice_delivery_method.slice(1),
                value: contract?.invoice_delivery_method,
              }
            : null,
          required: true,
          valid: firstTimeLoaded.current ? true : !!contract?.invoice_delivery_method,
          error: 'Invoice delivery is required',
        },
        {
          label: 'Work Week',
          name: 'work_week',
          type: 'select',
          options: allDays,
          placeholder: 'Select a work day',
          onchange: handleSelectChange,
          value: { label: contract?.work_week[0].toUpperCase() + contract?.work_week.slice(1), value: contract?.work_week },
          required: true,
          valid: firstTimeLoaded.current ? true : !!contract?.work_week,
          error: 'Work week is required',
        },
        {
          label: 'Display Facility',
          name: 'display_facility_name',
          type: 'boolean',
          onchange: handleInputChange,
          value: contract?.display_facility_name,
          required: true,
          valid: firstTimeLoaded.current ? true : contract?.display_facility_name !== null || typeof contract?.display_facility_name !== 'undefined',
          error: 'Display facility is required',
        },
        {
          label: 'Client Cancellation',
          name: 'client_cancellation_policy',
          onchange: handleInputChange,
          type: 'textarea',
          value: contract?.client_cancellation_policy,
          required: false,
          valid: true,
          error: '',
        },
        {
          label: 'Call Off',
          name: 'call_off_policy',
          type: 'textarea',
          onchange: handleInputChange,
          value: contract?.call_off_policy,
          required: false,
          valid: true,
          error: '',
        },
        {
          label: 'Holiday',
          name: 'holiday_policy',
          type: 'textarea',
          onchange: handleInputChange,
          value: contract?.holiday_policy,
          required: false,
          valid: true,
          error: '',
        },
        {
          label: 'Notes',
          name: 'notes',
          type: 'textarea',
          onchange: handleInputChange,
          value: contract?.notes,
          required: false,
          valid: true,
          error: '',
        },
      ];
      setFields(fields);
    }
  }, [contract, client, clientType, updateContractSuccess, divisions]);

  const isFormInvalid = useMemo(() => {
    if (fields) {
      return calculateIsFormInvalid(fields);
    }
  }, [fields]);

  const handleSubmit = (event) => {
    addContractReset();
    updateContractReset();
    event.preventDefault();

    const fieldMap = setFieldValidation(fields);
    let valid = fieldValidationStatus(fieldMap);
    setFields(fieldMap);
    if (valid) {
      let contractPayload = calculateContractPayload(fields);
      // NOTE: system_ids may contain other ids that we need to keep
      // this form can only add/update nested system_ids with name === eventSource.kamana
      let systemIds = [];
      if (contractPayload?.system_ids) {
        const existingIds = contract?.system_ids ?? [];
        const otherIds = existingIds.filter((e) => e?.name.toLowerCase() !== eventSource.kamana.toLowerCase());
        const kamanaObj = {
          name: eventSource.kamana,
          system_id: contractPayload?.system_ids,
        };
        systemIds = [...otherIds, kamanaObj];
      }
      contractPayload.system_ids = systemIds;
      if (contractPayload && isNew) {
        addContract({ contract: contractPayload })
          .unwrap()
          .then((payload) => {
            if (payload) {
              dispatch(setContractId(payload?.id));
              setLocalId(payload?.id);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (contractPayload) {
        updateContract({ contractId: contract?.id, contract: contractPayload })
          .unwrap()
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const handleSelectChange = (val, field, formFields) => {
    const name = field.name;
    const modFields = setFieldValue(name, val, formFields);
    if (isArrayValue(modFields)) {
      setFields(modFields);
    }
  };

  const handleClientChange = (val, action, field, formFields) => {
    handleSelectChange(val, field, formFields);
    dispatch(setContractClient(val?.payload));
  };

  const modifyFieldsByCompanyType = (disabled, recs, value) => {
    let modFields = recs;
    // NOTE: value can be null
    switch (value) {
      case 'company':
        modFields = recs.map((f) => {
          if (f.name === 'client_name') {
            return {
              ...f,
              type: 'clientselect',
              disabled,
              options: clientCompanies,
              onchange: handleClientChange,
              value: null,
              placeholder: 'Select a Company',
            };
          } else {
            return f;
          }
        });
        break;
      case 'facility':
        modFields = recs.map((f) => {
          if (f.name === 'client_name') {
            return {
              ...f,
              type: 'asyncselect',
              disabled,
              onchange: handleClientChange,
              options: null,
              value: null,
              placeholder: 'Search for a Facility',
            };
          } else {
            return f;
          }
        });
        break;
      case 'health_system':
        modFields = recs.map((f) => {
          if (f.name === 'client_name') {
            return {
              ...f,
              type: 'clientselect',
              disabled,
              options: clientHealthSystems,
              onchange: handleClientChange,
              value: null,
              placeholder: 'Select a Health System',
            };
          } else {
            return f;
          }
        });
        break;
      case 'msp':
        modFields = recs.map((f) => {
          if (f.name === 'client_name') {
            return {
              ...f,
              type: 'clientselect',
              disabled,
              options: clientMSPs,
              onchange: handleClientChange,
              value: null,
              placeholder: 'Select an MSP',
            };
          } else {
            return f;
          }
        });
        break;
      default:
        break;
    }
    return modFields;
  };

  const handleClientTypeChange = (val, { action }, field, formFields) => {
    let disabled = action === 'clear';
    const name = field.name;
    // swap out the client_name field element type, clear it's value, etc..
    const clientRow = formFields.find((mod) => mod.key === 'client_row');
    // set the client_type value
    let modFields = setFieldValue(name, val, clientRow.fields);
    const clientNameField = modFields.find((f) => f.name === 'client_name');
    // if the client type field is cleared, disable the client field
    if ('disabled' in clientNameField) {
      clientNameField.disabled = disabled;
    }
    // if client field contains a different client type clear it's value
    if (val?.value !== clientNameField?.value?.payload?.type) {
      modFields = setFieldValue(clientNameField?.name, null, modFields);
    }
    modFields = modifyFieldsByCompanyType(disabled, modFields, val?.value);

    if (isArrayValue(modFields)) {
      const newFields = fields.map((ff) => {
        if (ff.key === 'client_row') {
          return {
            ...ff,
            fields: modFields,
          };
        } else {
          return ff;
        }
      });
      setFields(newFields);
      setClientType(val?.value);
    }
  };

  const handleClearField = (event, field) => {
    event.preventDefault();
    handleInputChange(undefined, field, fields);
  };

  const outputDatePicker = (field, name, handler, minDate) => (
    <React.Fragment>
      <SingleDatepicker
        aria-label={field.name}
        disabled={!editable}
        name={field.name}
        date={field.value}
        onDateChange={(val) => field.onchange(val, name, fields)}
        minDate={minDate}
        propsConfigs={datePickerConfig({ name: field.name })}
      />
      {isRealValue(field.value) && field.value !== '' && (
        <InputRightElement>
          <IconButton onClick={(e) => handler(e, name)} icon={<BsX />} variant="ghost" size="md" aria-label={`clear-${field.name}`} />
        </InputRightElement>
      )}
      <FormErrorMessage mt={4} sx={{ flexBasis: '100%', height: 0 }}>
        {field.error}
      </FormErrorMessage>
    </React.Fragment>
  );

  const getDatePickerZIndex = (picker) => {
    return activeDatePicker === picker ? 101 : 100;
  };

  const fieldTypeSwitch = (field) => {
    const val = isRealValue(field?.value) ? field.value : '';
    switch (field.type) {
      case 'text': {
        return (
          <React.Fragment>
            <Input
              id={field.name}
              focusBorderColor={'brand.700'}
              type="text"
              isDisabled={!editable}
              value={field.value}
              onChange={(e) => field.onchange(e, field?.name, fields)}
            />
            <FormErrorMessage aria-label={`${field.name}-error`}>{field.error}</FormErrorMessage>
          </React.Fragment>
        );
      }
      case 'hours': {
        return (
          <Box>
            <InputGroup>
              <Input
                aria-label={field.name}
                id={field.name}
                focusBorderColor={'brand.700'}
                type="number"
                step={1}
                min={field?.min || 0}
                max={field?.max || 100}
                isDisabled={!editable}
                value={field.value}
                onChange={(e) => field.onchange(e, field?.name, fields)}
              />
              <InputRightAddon>hours</InputRightAddon>
            </InputGroup>
            <FormErrorMessage aria-label={`${field.name}-error`}>{field.error}</FormErrorMessage>
          </Box>
        );
      }
      case 'percentage': {
        return (
          <Box>
            <InputGroup>
              <Input
                aria-label={field.name}
                id={field.name}
                focusBorderColor={field?.warn ? 'orange.400' : 'brand.700'}
                type="number"
                step="any"
                min={field?.min || 0}
                max={field?.max || 100}
                isDisabled={!editable}
                value={field.value}
                onChange={(e) => field.onchange(e, field?.name, fields)}
              />
              <InputRightAddon>%</InputRightAddon>
            </InputGroup>
            {field?.warn && (
              <Flex aria-label={`${field.name}-warning`} fontSize="sm" color="orange.400">
                {field.error}
              </Flex>
            )}
            <FormErrorMessage aria-label={`${field.name}-error`}>{field.error}</FormErrorMessage>
          </Box>
        );
      }
      case 'starts_at': {
        return (
          <InputGroup sx={{ flexWrap: 'wrap', zIndex: getDatePickerZIndex('starts_at') }} onFocus={() => setActiveDatePicker('starts_at')}>
            {outputDatePicker(field, 'starts_at', handleClearField)}
          </InputGroup>
        );
      }
      case 'ends_at': {
        const startDate = getContractDateField('starts_at');
        return (
          <InputGroup sx={{ flexWrap: 'wrap', zIndex: getDatePickerZIndex('ends_at') }} onFocus={() => setActiveDatePicker('ends_at')}>
            {outputDatePicker(field, 'ends_at', handleClearField, startDate)}
          </InputGroup>
        );
      }
      case 'textarea': {
        return (
          <React.Fragment>
            <Textarea
              id={field.name}
              resize="none"
              focusBorderColor={'brand.700'}
              isDisabled={!editable}
              value={field.value || ''}
              onChange={(e) => field.onchange(e, field?.name, fields)}
            />
            <FormErrorMessage aria-label={`${field.name}-error`} mt={4} sx={{ flexBasis: '100%', height: 0 }}>
              {field.error}
            </FormErrorMessage>
          </React.Fragment>
        );
      }
      case 'boolean': {
        let strVal = Number(val);
        return (
          <RadioGroup aria-label={field.name} onChange={(val) => field.onchange(val, field?.name, fields)} value={strVal} isDisabled={!editable}>
            <HStack spacing="24px">
              <Radio value={0} aria-label={field.name + '_false'}>
                False
              </Radio>
              <Radio value={1} aria-label={field.name + '_true'}>
                True
              </Radio>
            </HStack>
            <FormErrorMessage aria-label={`${field.name}-error`} mt={4} sx={{ flexBasis: '100%', height: 0 }}>
              {field.error}
            </FormErrorMessage>
          </RadioGroup>
        );
      }
      case 'asyncselect': {
        return (
          <FacilitySearch
            id={field.name}
            ariaLabel={field.name}
            isInvalid={!field.valid}
            facilityDisabled={field?.disabled || !editable}
            facility={field.value}
            state={field.state ? { label: field.state, value: field.state } : null}
            facilityMaxWidth="300px"
            onFacilityChange={(val, action) => field.onchange(val, action, field, fields)}
            facilityPlaceholder={field?.placeholder}
            facilitySearchError={field?.error}
          />
        );
      }
      case 'clientselect': {
        return (
          <Box>
            <Select
              isDisabled={field?.disabled || !editable}
              id={field.name}
              aria-label={field.name}
              chakraStyles={chakraStyles}
              focusBorderColor="brand.700"
              options={field?.options}
              onChange={(val, action) => field.onchange(val, action, field, fields)}
              isClearable
              isSearchable
              value={field.value}
              placeholder={field?.placeholder}
              components={{
                SingleValue: SingleValueLabel,
                Option: OptionNameCityAddress,
              }}
              getOptionLabel={(option) => `${option.label} ${option.address} ${option.city} ${option.state}`}
              getOptionValue={(option) => option.value}
            />
            <FormErrorMessage aria-label={`${field.name}-error`}>{field.error}</FormErrorMessage>
          </Box>
        );
      }
      case 'client_type': {
        return (
          <Box>
            <Select
              isDisabled={!editable}
              id={field.name}
              aria-label={field.name}
              chakraStyles={chakraStyles}
              focusBorderColor="brand.700"
              options={field?.options}
              onChange={(val, action) => handleClientTypeChange(val, action, field, fields)}
              isClearable
              isSearchable
              value={field.value}
              placeholder={field?.placeholder}
            />
            <FormErrorMessage aria-label={`${field.name}-error`}>{field.error}</FormErrorMessage>
          </Box>
        );
      }
      case 'select': {
        return (
          <Box>
            <Select
              isDisabled={field?.disabled || !editable}
              id={field.name}
              aria-label={field.name}
              chakraStyles={chakraStyles}
              focusBorderColor="brand.700"
              options={field?.options}
              onChange={(val) => field.onchange(val, field, fields)}
              isClearable
              isSearchable
              value={field.value}
              placeholder={field?.placeholder}
            />
            <FormErrorMessage aria-label={`${field.name}-error`}>{field.error}</FormErrorMessage>
          </Box>
        );
      }
      case 'divisions': {
        return (
          <Box>
            <Select
              aria-label={field.name}
              isDisabled={!editable}
              isMulti
              classNamePrefix="addl-class"
              options={field?.options}
              onChange={(val) => field.onchange(val, field, fields)}
              value={field.value}
            />
            <FormErrorMessage aria-label={`${field.name}-error`}>{field.error}</FormErrorMessage>
          </Box>
        );
      }
    }
  };

  return (
    <form aria-label={isNew ? 'New Contract' : 'Edit Contract'} name="contract-form" onSubmit={handleSubmit}>
      {(updateContractLoading || addContractLoading) && (
        <Flex alignItems="center" justifyContent="center" pos={'absolute'} bg={'rgba(255,255,255,0.6)'} height={'100%'} zIndex={1200}>
          <Spinner color="brand.900" size="lg" />
        </Flex>
      )}

      {isSearchCompaniesError && (
        <AlertComponent
          icon={<BsBuildingExclamation style={{ height: '24px', width: '24px' }} />}
          status="error"
          title="Error Loading Clients"
          description={searchCompaniesError?.data?.detail}
        />
      )}

      {addContractError && (
        <AlertComponent
          icon={<BsFileEarmarkX style={{ height: '24px', width: '24px' }} />}
          status="error"
          title="Error Adding Contract"
          description={addContractErrorMsg?.status === 422 ? 'Please correct the errors marked below.' : addContractErrorMsg?.data?.detail}
        />
      )}
      <Box minHeight="1300px">
        {fields.map((field, index) => {
          if (field.type === 'row' && isArrayValue(field?.fields)) {
            const firstField = field.fields[0];
            return (
              <Grid key={field.name + index} templateColumns="180px auto" py={3}>
                <GridItem pt={3}>
                  <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor={field.name} mb={0} textAlign={'end'}>
                    {firstField.label}
                  </FormLabel>
                </GridItem>
                <GridItem>
                  <HStack spacing={4} key={field.key} alignItems="start">
                    {field.fields.map((ff, i) => {
                      return (
                        <FormControl
                          key={ff.name + index}
                          isInvalid={!ff.valid}
                          aria-label={field.name + '-control'}
                          width={i === 0 ? 'calc(100% - 300px)' : '100%'}
                        >
                          <Grid key={ff?.name} templateColumns={'auto'}>
                            <GridItem pt={'5px'}>
                              {i !== 0 && (
                                <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor={ff.name} mb={0} textAlign={'end'}>
                                  {ff.label}
                                </FormLabel>
                              )}
                            </GridItem>
                            <GridItem flex={1}>{fieldTypeSwitch(ff)}</GridItem>
                          </Grid>
                        </FormControl>
                      );
                    })}
                  </HStack>
                </GridItem>
              </Grid>
            );
          }
          return (
            <FormControl key={field.name + index} isInvalid={!field.valid} aria-label={field.name + '-control'}>
              <Grid templateColumns="180px auto" py={3}>
                <GridItem pt={'5px'}>
                  <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor={field.name} mb={0} textAlign={'end'}>
                    {field.label}
                  </FormLabel>
                </GridItem>
                <GridItem flex={1}>{fieldTypeSwitch(field)}</GridItem>
              </Grid>
            </FormControl>
          );
        })}
      </Box>
      {editable && (
        <Button
          type="submit"
          _disabled={{
            opacity: '0.4 !important',
            cursor: 'not-allowed',
            _hover: {
              bg: 'purple.600',
            },
          }}
          variant="purple"
          isDisabled={isFormInvalid}
          mt={4}
        >
          {isNew ? 'Submit' : 'Save'}
        </Button>
      )}
    </form>
  );
};

ContractEditForm.propTypes = {
  contract: PropTypes.object,
  isNew: PropTypes.bool,
};

export default ContractEditForm;
