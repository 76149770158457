import { Box, Heading, Text } from '@chakra-ui/react';
import Layout from '@/features/common/Layout';

const Welcome = () => {
  return (
    <Box data-testid="pact-contracts">
      <Layout pageTitle={'Pact'}>
        <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
          Welcome
        </Heading>
        <Box mt="6" p={0}>
          <Text as="p">Hello, welcome to Pact.</Text>
        </Box>
      </Layout>
    </Box>
  );
};

export default Welcome;
