import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TbLayoutCards } from 'react-icons/tb';
import LoaderOverlay from '../common/LoaderOverlay';
import { Select } from 'chakra-react-select';
import { useSelector } from 'react-redux';
import { selectDivisionsList } from '../enum/enumSlice';
import { useAddModalitiesMutation, useProfessionsQuery, useSpecialtiesQuery, useUpdateModalityMutation } from '@/app/services/nucleus';
import AlertComponent from '../common/AlertComponent';
import { useRole } from '@/hooks/useRole';

const ModalityModal = ({ isOpen, onClose, onSave, initialValues, mode }) => {
  useRole('admin');
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const labelColor = useColorModeValue('gray.500', 'gray.400');

  const [division, setDivision] = useState(null);
  const [shortName, setShortName] = useState('');
  const [profession, setProfession] = useState(null);
  const [specialty, setSpecialty] = useState(null);
  const [isDefault, setIsDefault] = useState(true);
  const divisionList = useSelector(selectDivisionsList);

  const [
    addModality,
    { isLoading: addModalityLoading, isSuccess: addModalitySuccess, isError: addModalityError, error: addModalityErrorMsg, reset: addModalityReset },
  ] = useAddModalitiesMutation();

  const [
    updateModality,
    {
      isLoading: updateModalityLoading,
      isSuccess: updateModalitySuccess,
      isError: updateModalityError,
      error: updateModalityErrorMsg,
      reset: updateModalityReset,
    },
  ] = useUpdateModalityMutation();

  const { data: specialties, isLoading: specialtiesLoading, isError: specialtiesError, error: specialtiesErrorMsg } = useSpecialtiesQuery();

  const { data: professions, isLoading: professionsLoading, isError: professionsError, error: professionsErrorMsg } = useProfessionsQuery();

  useEffect(() => {
    if (!isOpen) {
      setDivision(null);
      setShortName('');
      setProfession(null);
      setSpecialty(null);
      setIsDefault(true);
      addModalityReset();
      updateModalityReset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (initialValues) {
      const {
        division: divisionValue,
        short_name: shortNameValue,
        profession: professionValue,
        specialty: specialtyValue,
        is_default: isDefaultValue,
      } = initialValues;

      if (divisionValue && divisionList.length) {
        const divisionItem = divisionList.find((item) => item.value === divisionValue);
        if (divisionItem) {
          setDivision(divisionItem);
        }
      }
      setShortName(shortNameValue || '');
      setProfession(professionValue || null);
      setSpecialty(specialtyValue || null);
      setIsDefault(isDefaultValue ?? true);
    }
  }, [divisionList, initialValues]);

  const handleClose = () => {
    onClose();
  };

  const handleDivisionChange = (value) => {
    setDivision(value);
  };

  const handleSpecialtyChange = (value) => {
    setSpecialty(value);
  };

  const handleProfessionsChange = (value) => {
    setProfession(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const modalityPayload = {
      division: division?.value,
      short_name: shortName,
      profession_id: profession?.id,
      specialty_id: specialty?.id,
      is_default: isDefault,
    };

    if (mode === 'edit' && initialValues?.id) {
      updateModality({ modality: modalityPayload, id: initialValues.id })
        .unwrap()
        .then((payload) => {
          console.log('Modality updated', payload);
          onSave();
          onClose();
        })
        .catch((error) => {
          console.log('Error updating modality', error);
        });
    } else {
      addModality(modalityPayload)
        .unwrap()
        .then((payload) => {
          console.log('Modality added', payload);
          onSave();
          onClose();
        })
        .catch((error) => {
          console.log('Error adding modality', error);
        });
    }
  };

  return (
    <Modal
      title={mode === 'edit' ? 'Edit Modality' : 'Add Modality'}
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size="3xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <form id={`${mode}_modality`} onSubmit={handleSubmit}>
          <ModalHeader>
            <HStack>
              <TbLayoutCards />
              <Text>{mode === 'edit' ? 'Edit Modality' : 'Add Modality'}</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4} position={'relative'}>
              <LoaderOverlay loading={addModalityLoading || updateModalityLoading || professionsLoading || specialtiesLoading} />

              {(addModalityError || updateModalityError) && (
                <AlertComponent
                  status="error"
                  title={mode === 'edit' ? 'Error updating modality' : 'Error adding modality'}
                  description={
                    addModalityErrorMsg?.status === 409 || updateModalityErrorMsg?.status === 409
                      ? 'Modality already exists'
                      : addModalityErrorMsg?.status === 422 || updateModalityErrorMsg?.status === 422
                      ? 'Invalid data. Please check the fields and try again.'
                      : addModalityErrorMsg?.data?.detail || updateModalityErrorMsg?.data?.detail
                  }
                />
              )}

              {(specialtiesError || professionsError) && (
                <AlertComponent
                  status="error"
                  title="Error fetching specialties or professions"
                  description={specialtiesErrorMsg?.data?.detail || professionsErrorMsg?.data?.detail}
                />
              )}

              <FormControl isInvalid={false} aria-label={'division-control'}>
                <Grid templateColumns="120px auto">
                  <GridItem pt={2}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="division" mb={0} textAlign="end">
                      Division
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Select
                      id="division"
                      aria-label="division"
                      placeholder="Select a Division"
                      focusBorderColor="brand.700"
                      options={divisionList}
                      value={division}
                      onChange={handleDivisionChange}
                      isClearable={true}
                      isSearchable
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                    />
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl isInvalid={false} aria-label={'short-name-control'}>
                <Grid templateColumns="120px auto">
                  <GridItem pt={2}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="short_name" mb={0} textAlign="end">
                      Short Name
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Input
                      id="short_name"
                      focusBorderColor={'brand.700'}
                      type="text"
                      isRequired={false}
                      isDisabled={false}
                      value={shortName}
                      onChange={(e) => setShortName(e.target.value)}
                    />
                  </GridItem>
                </Grid>
              </FormControl>
              <FormControl isInvalid={false} aria-label={'is-default-control'}>
                <Grid templateColumns="120px auto">
                  <GridItem>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="is_default" mb={0} textAlign="end">
                      Default
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Switch id="is_default" aria-label="is_default" isChecked={isDefault} onChange={(e) => setIsDefault(e.target.checked)} mb={0.5} />
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl isInvalid={false} aria-label={'specialty-control'}>
                <Grid templateColumns="120px auto">
                  <GridItem pt={2}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="specialty" mb={0} textAlign="end">
                      Specialty
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Select
                      id="specialties"
                      aria-label="specialties"
                      placeholder="Select a Specialty"
                      focusBorderColor="brand.700"
                      options={specialties?.items}
                      value={specialty}
                      onChange={handleSpecialtyChange}
                      isClearable={true}
                      isSearchable
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                    />
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl isInvalid={false} aria-label={'profession-control'}>
                <Grid templateColumns="120px auto">
                  <GridItem pt={2}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="profession" mb={0} textAlign="end">
                      Profession
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Select
                      id="profession"
                      aria-label="profession"
                      placeholder="Select a Profession"
                      focusBorderColor="brand.700"
                      options={professions?.items}
                      value={profession}
                      onChange={handleProfessionsChange}
                      isClearable={true}
                      isSearchable
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                    />
                  </GridItem>
                </Grid>
              </FormControl>

              <HStack width="100%" py={3} justifyContent={'end'}>
                <Button variant="purple" type="submit">
                  {mode === 'edit' ? 'Save' : 'Submit'}
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
};

ModalityModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  initialValues: PropTypes.object,
  mode: PropTypes.string,
};

export default ModalityModal;
