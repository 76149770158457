import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  FormControl,
  VStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Stack,
  Input,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useSelector } from 'react-redux';
import AlertComponent from '../common/AlertComponent';
import { BsTrash, BsPlus } from 'react-icons/bs';
import { isRealValue } from '@/utils/helpers';
import TableButton from '../common/table/TableButton';
import { selectModalities } from '../modality/modalitySlice';
import TableFooter from '../common/table/TableFooter';
import useTable from '@/hooks/useTable';
import { SingleValueShortName } from '../common/select/SingleValue';
import { OptionShortName } from '../common/select/Option';
import { chakraStyles } from '../common/select/styles';
import LoaderOverlay from '@/features/common/LoaderOverlay';

const operators = [{ label: '=' }, { label: '*' }, { label: '+' }];
const rules = [
  ['Regular', '=', 'bill_base_rate'],
  ['>40', '*', 'bill_40'],
  ['>48', '*', 'bill_48'],
  ['>8', '*', 'bill_8'],
  ['>12', '*', 'bill_12'],
  ['Holiday', '*', 'bill_holiday'],
  ['Call Back', '*', 'bill_callback'],
  ['Charge', '+', 'bill_charge'],
  ['On Call', '=', 'bill_call'],
];

const RateCardModal = ({
  specialties,
  isOpen,
  onClose,
  isLoading,
  isEdit,
  isError,
  errorMessage,
  saveRateCardHandler,
  reset,
  disabledSpecialties,
  rateCard,
}) => {
  const mappedRules = rules.map((el) => ({
    name: el[0],
    operator: el[1],
    value: 0,
    field: el[2],
  }));
  const validationRules = rules.map((el) => ({
    [el[2]]: false,
  }));
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const portalRef = useRef(null);
  const modalities = useSelector(selectModalities);
  const [showError, setShowError] = useState(false);
  const [summary, setSummary] = useState('');
  const [specialty, setSpecialty] = useState([]);
  const [earnCodeFields, setEarnCodeFields] = useState(mappedRules);
  const [page, setPage] = useState(1);
  const [validation, setValidation] = useState({ ...validationRules, summary: false });
  const [specialitiesCopy, setSpecialitiesCopy] = useState([]);

  const { slice, range } = useTable(specialty, page, 6);

  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [specialty, page, range, slice]);

  useEffect(() => {
    if (isError) {
      setShowError(true);
    }
  }, [isError]);

  // showError must have it's own useEffect separate from rateCard
  // the referential equality check will cause showError to be false
  useEffect(() => {
    if (isOpen) {
      setShowError(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      let editSpecialty = [];
      let summary = '';
      let rulesCopy = [...mappedRules];
      // NOTE: rateCard obj is only valid while editing
      if (rateCard?.modality_ids && rateCard?.modality_ids.length > 0) {
        editSpecialty = modalities.filter((spec) => spec.short_name && rateCard.modality_ids.includes(spec.id));
      }
      if (rateCard && Object.keys(rateCard).length > 0) {
        summary = rateCard.name;
        // populate our rules using rateCard data
        rulesCopy = rulesCopy.map((rule) => ({
          ...rule,
          operator: rateCard[`${rule.field}_operator`],
          value: rateCard[rule.field],
        }));
      }
      setSpecialty(editSpecialty);
      setSummary(summary);
      setEarnCodeFields(rulesCopy);
    }
  }, [isOpen, rateCard]);

  useEffect(() => {
    if (specialties && specialties.length > 0) {
      let copySpecialities = [...specialties];
      copySpecialities.shift();
      const spc = selectAllSpecialties();
      let isDisabled = false;
      if (spc.length === 0) {
        isDisabled = true;
      }
      copySpecialities.unshift({ id: 'select_all', short_name: 'Select All', checked: false, disabled: isDisabled });
      setSpecialitiesCopy(copySpecialities);
    }
  }, [specialties, disabledSpecialties]);

  const handleSave = (e, id) => {
    e.preventDefault();
    resetSelectAll();
    saveRateCardHandler(e, id);
  };

  const handleClose = () => {
    reset();
    resetSelectAll();
    onClose();
  };

  const handleSummaryChange = (e) => {
    if (isRealValue(e.target.value)) {
      setSummary(e.target.value);
    }
    let invalid = e.target.value === '' || !isRealValue(e.target.value);
    setValidation((prev) => ({
      ...prev,
      summary: invalid,
    }));
  };

  const handleRateChange = (e, code) => {
    e.preventDefault();
    const newEarnCodes = earnCodeFields.map((item) => {
      if (item.name === code.name) {
        item.value = e.target.value;
      }
      return item;
    });
    setEarnCodeFields(newEarnCodes);
    let invalid = e.target.value === '' || !isRealValue(e.target.value);
    setValidation((prev) => ({
      ...prev,
      [code.field]: invalid,
    }));
  };

  const handleSpecialty = (item, { action }) => {
    if (action === 'clear') {
      resetSelectAll();
    } else if (action === 'deselect-option') {
      const { data: modifiedOptions } = toggleSelectAll(specialitiesCopy, false);
      setSpecialitiesCopy(modifiedOptions);
      setSpecialty(item);
    } else {
      if (selectId(item, 'select_all')) {
        const spc = selectAllSpecialties();
        if (spc.length === 0) return null;
        const { modifiedOptions, newSpecialties } = selectAllLogic(specialitiesCopy, spc);
        setSpecialty(newSpecialties);
        setSpecialitiesCopy(modifiedOptions);
      } else {
        setSpecialty(item);
        setShowError(false);
        reset();
      }
    }
  };

  const handleRemoveSpecialtyActions = (e, val) => {
    e.preventDefault();
    const reducedSpecialties = specialty.filter((item) => item.id !== val.id);
    resetSelectAll();
    setSpecialty(reducedSpecialties);
    setShowError(false);
  };

  const handleOperatorChange = (val, code) => {
    const newEarnCodes = earnCodeFields.map((item) => {
      if (item.name === code.name) {
        item.operator = val.label;
      }
      return item;
    });
    setEarnCodeFields(newEarnCodes);
  };

  const selectAllSpecialties = () => {
    return specialties.filter((item) => !disabledSpecialties.includes(item.id));
  };

  const selectId = (item, id) => {
    return item && item.length > 0 && item.filter((el) => el.id === id).length > 0;
  };

  const resetSelectAll = () => {
    const modifiedOptions = specialitiesCopy.map((item) => {
      return { ...item, checked: false };
    });
    setSpecialty([]);
    setSpecialitiesCopy(modifiedOptions);
  };

  const toggleSelectAll = (data, toggle) => {
    let checked = false;
    const output = data
      ? data.map((item) => {
          if (item.id === 'select_all') {
            checked = isRealValue(toggle) ? toggle : !item.checked;
            return { ...item, checked };
          }
          return item;
        })
      : [];
    return { data: output, checked };
  };

  const selectAllLogic = (specialitiesCopy, selectAllSpecialties) => {
    const { data: modifiedOptions, checked } = toggleSelectAll(specialitiesCopy);
    const newSpecialties = checked ? selectAllSpecialties : [];
    return { modifiedOptions, newSpecialties };
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size="xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent maxW={'1200px'}>
        <ModalHeader>
          <HStack>
            <BsPlus />
            <Text>{isEdit ? 'Edit' : 'Add'} Rate Card</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <div ref={portalRef}>
            <VStack style={{ position: 'relative' }} gap={6}>
              <Input
                aria-label="summary"
                isInvalid={validation.summary}
                ref={initialRef}
                type="text"
                focusBorderColor="brand.700"
                placeholder="Summary"
                value={summary}
                onChange={handleSummaryChange}
              />
              <FormControl id="earn_codes">
                {isError && showError ? (
                  <Flex alignItems="center" justifyContent="center">
                    <AlertComponent status="error" title="Error" description={errorMessage?.data?.detail} flexDirection="row" />
                  </Flex>
                ) : (
                  <Stack gap={8} alignItems={'start'} direction={['column', 'row']}>
                    <LoaderOverlay loading={isLoading} />
                    <TableContainer pt={6} style={{ overflowY: 'visible' }} flex={1} borderWidth={1} borderRadius={6}>
                      <Table variant="simple" size="sm">
                        <Thead>
                          <Tr>
                            <Th>Earn Code</Th>
                            <Th>Operator</Th>
                            <Th isNumeric>Bill Rate</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {earnCodeFields.map((code) => (
                            <Tr key={code.field}>
                              <Td>{code.name}</Td>
                              <Td>
                                <Select
                                  chakraStyles={chakraStyles}
                                  focusBorderColor="brand.700"
                                  value={{ label: code.operator }}
                                  options={operators}
                                  menuPosition="fixed"
                                  menuPortalTarget={portalRef.current}
                                  getOptionLabel={(option) => option.label}
                                  getOptionValue={(option) => option.label}
                                  onChange={(val) => handleOperatorChange(val, code)}
                                  size="sm"
                                />
                              </Td>
                              <Td isNumeric>
                                <Input
                                  aria-label={code.field}
                                  isInvalid={validation[code.field]}
                                  focusBorderColor="brand.700"
                                  id={code.field}
                                  width={75}
                                  size="sm"
                                  type="number"
                                  value={code.value}
                                  onChange={(e) => handleRateChange(e, code)}
                                />
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                    <VStack flex={1}>
                      <FormControl id="specialty">
                        {!isEdit && (
                          <>
                            <Select
                              chakraStyles={chakraStyles}
                              focusBorderColor="brand.700"
                              selectedOptionStyle="check"
                              isMulti={true}
                              aria-label={'specialty'}
                              options={specialitiesCopy}
                              isSearchable
                              isDisabled={isEdit}
                              isOptionDisabled={(option) => disabledSpecialties.includes(option.id)}
                              value={specialty}
                              isClearable={true}
                              onChange={handleSpecialty}
                              getOptionLabel={(option) => option.short_name}
                              getOptionValue={(option) => option.id}
                              placeholder={specialty.length > 0 ? `Select profession/specialty  +${specialty.length}` : 'Select profession/specialty'}
                              controlShouldRenderValue={false}
                              hideSelectedOptions={false}
                              components={{ SingleValue: SingleValueShortName, Option: OptionShortName }}
                            />
                          </>
                        )}
                        {specialty && specialty.length > 0 ? (
                          <Box borderWidth="1px" borderRadius="lg" overflow="hidden" mt={isEdit ? '0' : '5'}>
                            <TableContainer pt="4">
                              <Table variant="striped" size="sm" aria-label="specialty selection" colorScheme={'lightgray'}>
                                <Thead>
                                  <Tr>
                                    <Th>Profession/Specialty</Th>
                                    <Th>Division</Th>
                                    {isEdit ? null : <Th>Action</Th>}
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {slice.map((val) => (
                                    <Tr key={val.id}>
                                      <Td>{val.short_name}</Td>
                                      <Td>{val.division}</Td>
                                      {isEdit ? null : (
                                        <Td>
                                          <TableButton
                                            isDisabled={isEdit}
                                            color={isEdit ? 'gray.300' : null}
                                            aria-label="remove"
                                            icon={<BsTrash />}
                                            onClick={(e) => handleRemoveSpecialtyActions(e, val)}
                                          />
                                        </Td>
                                      )}
                                    </Tr>
                                  ))}
                                </Tbody>
                              </Table>
                            </TableContainer>
                            {range && range.length > 1 && (
                              <Box width="100%" p={3}>
                                <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
                              </Box>
                            )}
                          </Box>
                        ) : (
                          isEdit && (
                            <Flex alignItems="center" justifyContent="center" py={100} bg={'gray.100'} borderRadius={6}>
                              No profession/specialties assigned
                            </Flex>
                          )
                        )}
                      </FormControl>
                    </VStack>
                  </Stack>
                )}
              </FormControl>
            </VStack>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={specialty.length === 0 || summary === '' || (isError && showError) || Object.values(validation).includes(true)}
            variant="cyan"
            mr={3}
            onClick={(e) => handleSave(e, { name: summary, specialty, code: earnCodeFields })}
          >
            Save
          </Button>
          <Button onClick={() => handleClose()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

RateCardModal.propTypes = {
  specialties: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  isEdit: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.object,
  saveRateCardHandler: PropTypes.func,
  reset: PropTypes.func,
  disabledSpecialties: PropTypes.array,
  rateCard: PropTypes.object,
};

export default RateCardModal;
